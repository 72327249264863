import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

import { CompanyItem } from '@/components/Company';
import { Button } from '@/components/Elements';
import { PeopleItem } from '@/components/People';
import { ProductItem } from '@/components/Product';
import { checkIsAgencies, checkIsPeople, checkIsProducts } from '@/utils';

export const SearchResult = ({ searchValue, searchedValue, selectedCategory, toggleSearch }) => {
  const isPeople = checkIsPeople(selectedCategory);
  const isAgencies = checkIsAgencies(selectedCategory);
  const isProducts = checkIsProducts(selectedCategory);

  const RenderItem = (isPeople && PeopleItem) || (isAgencies && CompanyItem) || (isProducts && ProductItem);

  const linkTo = (isPeople && '/people') || (isAgencies && '/agencies') || (isProducts && '/shop');

  const queryParam = new URLSearchParams({ searchValue }).toString();

  if (!searchValue) return null;

  if (searchedValue.length === 0)
    return (
      <div className="flex h-96 w-full items-center justify-center">
        <p className="md: text-base text-xl">Илэрц олдсонгүй.</p>
      </div>
    );

  return (
    <div className="z-10 mt-4 md:mt-10">
      <p>“{searchValue}” хайлтын үр дүн</p>
      <ul className="mt-2 grid grid-cols-2 gap-2 md:mt-4 md:grid-cols-4 md:gap-4">
        {searchedValue.map((value, i) => (
          <RenderItem key={i} value={value} toggleSearch={toggleSearch} />
        ))}
      </ul>
      <div className="mt-4 flex justify-center md:mt-32">
        <Link state={{ fromSearch: true }} to={`${linkTo}?${queryParam}`}>
          <Button onClick={toggleSearch} variant="filled" size="lg" className="px-8" endIcon={<ArrowNarrowRightIcon className="ml-1.5 h-5 w-5" />}>
            Бүгдийг харах
          </Button>
        </Link>
      </div>
    </div>
  );
};
