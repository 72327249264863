import Axios from 'axios';
import { get, isArray, isString } from 'lodash';
import { toast } from 'react-toastify';

import { API_URL } from '@/config';
import storage from '@/utils/storage';

function authRequestInterceptor(config) {
  const token = storage.getToken();
  if (token) config.headers.authorization = `Bearer ${token}`;
  config.headers.Accept = 'application/json';
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let message = (isString(error?.response?.data?.error) && error?.response?.data?.error) || error?.response?.data?.message || error.message;

    console.log(message);

    const formError = error?.response?.data?.form?.children && Object.values(error?.response?.data?.form?.children);
    if (isArray(formError)) {
      for (const err of formError) {
        if (get(err, 'errors')) {
          message = err.errors[0];
          break;
        }
      }
    }

    toast.error(message);
    return Promise.reject(error);
  }
);

export const decodeUrl = (url) => url.replace(/%5B/g, '[').replace(/%5D/g, ']');
