import clsx from 'clsx';
import { useRef, useState } from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ProductItem } from '@/components/Product/ProductItem';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';
import { classNames } from '@/utils/classNames';

const RenderProductItem = () => {
  return (
    <div className=" animate-pulse rounded ">
      <div className="image-container bg-secondary-disabled" />
      <div className="mt-4">
        <div className="mb-1.5 h-3 w-40 rounded bg-secondary-disabled" />
        <div className="mb-1.5 h-4 w-40 rounded bg-secondary-disabled" />
        <div className="h-3 w-20 rounded bg-secondary-disabled" />
      </div>
    </div>
  );
};
export const ProductSwiper = ({ data, isLoading }) => {
  const swiperRef = useRef(null);
  const { isSm, isMd } = useWindowDimensions();
  const slidePerView = isSm ? 1.5 : isMd ? 3.2 : 4;
  const realData = isLoading ? [1, 2, 3, 4, 5] : data;
  const [p, setP] = useState(0);
  const sliderPerView = 4;
  const showButtons = sliderPerView < data?.length;
  return (
    <div className="relative">
      {showButtons && (
        <div className={clsx('absolute top-[-74px] right-[0] z-10  hidden place-self-center md:flex')}>
          <button
            onClick={() => {
              swiperRef?.current?.swiper?.slidePrev();
            }}
            className={classNames(p !== 0 ? 'bg-orange' : 'bg-light-disabled', 'center mr-4 h-10 w-10 rounded-3xl ')}>
            <ArrowLeft size={16} color={'white'} />
          </button>
          <button
            onClick={() => {
              swiperRef?.current?.swiper?.slideNext();
            }}
            className={classNames(p < realData?.length - 4 ? 'bg-orange' : 'bg-light-disabled', 'center mr-4 h-10 w-10 rounded-3xl ')}>
            <ArrowRight size={16} color={'white'} />
          </button>
        </div>
      )}

      <Swiper
        className=""
        ref={swiperRef}
        slidesPerView={slidePerView}
        spaceBetween={30}
        modules={[Navigation]}
        onSlideChange={(s) => {
          setP(s.realIndex);
        }}>
        {realData?.map((item) => (
          <SwiperSlide key={item?.id || `home-product-skeleton-${item}`}>{isLoading ? <RenderProductItem /> : <ProductItem value={item} />}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
