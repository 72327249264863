import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchValue: '',
  selectedCategory: { label: 'Хүн', value: 'people' },
  selectedGender: 'male'
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload.value;
    },
    setSelectedGender: (state, action) => {
      state.selectedGender = action.payload.value;
    },
    resetSearch: () => initialState
  }
});

export const searchActions = searchSlice.actions;

export const searchSliceReducer = searchSlice.reducer;
