import { useInfiniteQuery } from 'react-query';

import { peopleKeys } from '@/features/people';
import { axios, decodeUrl } from '@/libs/axios';
import { getNextPageParam } from '@/libs/react-query';

import { companyKeys } from '../api/companyKeys';

const PER_PAGE = '6';
const fetchCompanies = (pageParam, searchParams) => {
  searchParams.set('page', pageParam);
  searchParams.set('perPage', PER_PAGE);
  return axios.get(`v1/company?${decodeUrl(searchParams.toString())}`);
};

export const useInfiniteCompanies = (searchParams, opt) => {
  return useInfiniteQuery(peopleKeys.infiniteFilter(searchParams.toString()), ({ pageParam = 1 }) => fetchCompanies(pageParam, searchParams), {
    getNextPageParam: (lastPage, allPages) => getNextPageParam(lastPage, allPages, PER_PAGE),
    ...opt
    // enabled: searchParams.includes('searchValue')
  });
};
