import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { Head } from '@/components/Head';
import { CompanyLabel } from '@/components/Work/CompanyLabel';
import { ExecutorLabel } from '@/components/Work/ExecutorLabel';
import { useWorkDetails } from '@/features/work';
import { slashFormat } from '@/utils/dateFormats';

export const WorkDetail = () => {
  let params = useParams();
  const location = useLocation();

  const { work } = location.state || {};

  const hasStateWork = !!work?.workId;

  const { workId } = params;

  const { data, isLoading } = useWorkDetails(workId, !hasStateWork);

  const workData = hasStateWork ? work : data;

  const { name, description, datePublished, photos, executors, companies } = workData || {};

  if (isLoading) {
    return (
      <>
        <Head title={'Туршлага'} description={'Туршлага'} />
        <div className={'flex h-full w-full items-center justify-center'}>
          <Spinner />
        </div>
      </>
    );
  }

  return (
    <div className="layout layout-space grid grid-cols-12 py-20">
      <Head title={'Туршлага'} description={'Туршлага'} />

      <div className={'center col-span-12 flex-col pb-10 text-center'}>
        <p className={'font-[Marcellus] text-xl text-zinc-900'}>{name}</p>
        <p className={'text-zinc-600'}>{description}</p>
        <p className={'text-sm italic text-zinc-500'}>{slashFormat(datePublished)}</p>
        {photos?.map((pho) => (
          <img src={pho?.originalName} className={'my-10 mb-2 h-auto w-full'} alt={pho?.photoId} key={pho?.photoId} />
        ))}
      </div>
      <div className={'col-span-12'}>
        <div>
          <p className="mb-1 text-lg text-zinc-900">Оролцогчид</p>
          <hr className={'mb-1'} />
          <div className={'flex pb-10'}>
            {executors?.map((ex) => (
              <ExecutorLabel item={ex} key={ex?.executorId} />
            ))}
          </div>
          <p className="mb-1 text-lg text-zinc-900">Оролцогч байгууллагууд</p>
          <hr className={'mb-1'} />
          <div className={'flex'}>
            {companies?.map((co) => (
              <CompanyLabel item={co} key={co?.companyId} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
