export const Categories = ({ categories }) => {
  return (
    <div>
      {categories.map((cat) => (
        <p className="font-body-xs text-primary-medium" key={`categoryId-${cat?.categoryId}`}>
          {cat?.name}
        </p>
      ))}
    </div>
  );
};
