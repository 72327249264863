import { Link } from 'react-router-dom';

import { TugCurrency } from '@/libs/react-number-format';
import { getLinkToDetail } from '@/utils/link';

export const RecommendationProductItem = ({ item, close }) => {
  const name = item.name || `${item.lastname}. ${item.firstname}`;
  const to = getLinkToDetail(item);
  return (
    <Link to={to} onClick={close}>
      <div className="image-recommendation-product-container">
        <img src={item.avatarPath} alt={name} />
      </div>

      <p className="font-label-l mt-6 line-clamp-1">{name}</p>
      <TugCurrency className="font-body-m mt-2 text-secondary-medium" value={item.basePrice} />
    </Link>
  );
};
