import { useMutation } from 'react-query';

import { categoryTypes } from '@/constants/categoryTypes';
import { axios } from '@/libs/axios';

const orderItems = ({ totalPrice, items }) =>
  axios.post('/customer/order', {
    order: {
      type: categoryTypes.commerce,
      amount: totalPrice,
      paid: false,
      status: 'initiated',
      productOptions: items.map((item) => ({ productOption: item.id, quantity: item.qty }))
    }
  });

export const useMutateOrderItems = () => {
  return useMutation(orderItems);
};
