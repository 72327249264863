import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button } from '@/components/Elements';
import { menuActions } from '@/store/menuSlice';

import { useMutateActive } from '../api/useMutateActive';
import AuthContainer from '../components/AuthContainer';

export const Confirmation = () => {
  const dispatch = useDispatch();
  const { mutateAsync, isLoading } = useMutateActive();

  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
  const token = searchParams.get('token');

  const handleGoHome = () => navigate('/');

  const handleCustomerActive = React.useCallback(async () => {
    if (token) {
      await mutateAsync(token);
      toast.success('Та амжилдалтай идэвхжилээ.');
      dispatch(menuActions.toggleLogin());
      navigate('/');
    }
  }, [dispatch, mutateAsync, navigate, token]);

  useEffect(() => {
    if (token) {
      handleCustomerActive();
    }
  }, [handleCustomerActive, token]);

  return (
    <AuthContainer>
      <div className="flex w-full flex-col items-center">
        <div className="bg-primary-high p-6 md:p-20">
          <p className="font-title-l md:font-headline-l">Бүртгэлээ баталгаажуулна уу</p>

          <p className="font-body-l mt-6 md:mt-8">Та өөрийн мэйл хаягтаа ирсэн линкээр орж баталгаажуулна уу.</p>

          <Button isLoading={isLoading} className="mt-6 md:mt-8" variant="filled" onClick={handleGoHome} text="БҮРТГЭЛ ҮҮСГЭХ" />
        </div>
      </div>
    </AuthContainer>
  );
};
