import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ListSort, SearchResultNotFound } from '@/components/Elements';
import { FilterSearch } from '@/components/Elements/FilterSearch/FilterSearch';
import { ListFilter } from '@/components/Elements/ListFilter/ListFilter';
import { RoutePath } from '@/components/Elements/RoutePath';
import { Head } from '@/components/Head';
import { ProductItem } from '@/components/Product';
import { categoryTypes } from '@/constants/categoryTypes';
import { useFilterName } from '@/hooks/useFilterName';
import { useSearchState } from '@/hooks/useSearchState';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';

import { useInfiniteProducts } from '../api/useInfiniteProducts';
import { ProductFilter } from '../components/ProductFilter';

const RenderProductItem = () => {
  return (
    <div className=" animate-pulse rounded">
      <div className="image-container bg-slate-300" />
      <div className="pt-2">
        <div className={`h-${3} mb-2 w-2/5 rounded bg-slate-200`} />
        <div className={`h-${10} w-full rounded bg-slate-200`} />
      </div>
    </div>
  );
};
export const ProductList = () => {
  const { isLg, isSm } = useWindowDimensions();
  const { name, debouncedName, setName } = useFilterName();
  const { searchParams, featuredSearchParams } = useSearchState(debouncedName);
  searchParams.set('searchValue', debouncedName);
  const { data: productData, isLoading: isProductLoading, fetchNextPage, hasNextPage } = useInfiniteProducts(searchParams);
  const standardData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const total = productData?.pages?.[0]?.total;
  return (
    <div>
      <Head title="Дэлгүүр" description="Дэлгүүр бараа хайх" />
      <>
        <div className="w-full bg-primary">
          <div className="layout layout-space py-10">
            <RoutePath type={categoryTypes.commerce} categoryId={searchParams.get('category')} />
          </div>
        </div>
        <div className=" mymd:row layout layout-space h-full pt-10 md:pt-20 mymd:space-x-8 ">
          <ListFilter>
            <ProductFilter filter={searchParams.toString()} />
          </ListFilter>

          <div className="flex flex-1 flex-col items-start">
            <FilterSearch name={name} setName={setName} />
            {isProductLoading && !productData?.pages && (
              <div className="mt-10 w-full md:mt-20">
                {/*<div className=" animate-pulse rounded">*/}
                {/*  <div className="mb-6 h-8 w-24 bg-slate-300" />*/}
                {/*</div>*/}
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
                  {standardData.slice(0, isLg ? 4 : 10).map((i, index) => (
                    <RenderProductItem key={index + 'standardLoading' + i} />
                  ))}
                </div>
              </div>
            )}
            <>
              <ListSort total={total} type={categoryTypes.commerce} />
              {!isProductLoading && total === 0 && <SearchResultNotFound />}
              <InfiniteScroll
                className="space-y-2 md:space-y-5"
                dataLength={productData?.pages?.length || 0} //This is important field to render the next data
                next={fetchNextPage}
                hasMore={hasNextPage}
                loader={
                  <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
                    {standardData.slice(0, isLg ? 4 : 10).map((i, index) => (
                      <RenderProductItem key={index + 'standardLoading' + i} />
                    ))}
                  </div>
                }>
                {productData?.pages?.map((page, i) => (
                  <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3" key={i}>
                    {page?.products?.map((product) => (
                      <ProductItem key={product.id} value={product} />
                    ))}
                  </div>
                ))}
              </InfiniteScroll>
            </>
          </div>
        </div>
      </>
    </div>
  );
};
