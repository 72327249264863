import { useQuery } from 'react-query';

import { axios } from '@/libs/axios';

import { productsKeys } from './productsKeys';

const fetchProductDetail = async (id) => (await axios.get(`/v1/shop/product?productId=${id}`)).products?.[0];

export const useProductDetail = (id, opt) => {
  return useQuery(productsKeys.detail(id), () => fetchProductDetail(id), opt);
};
