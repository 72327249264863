import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { first, isObject } from 'lodash';
import React, { Fragment } from 'react';
import { Star } from 'react-feather';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Rating } from 'react-simple-star-rating';
import { toast } from 'react-toastify';

import { Button } from '@/components/Elements';
import { useMutateRating } from '@/features/rate';
import { menuActions } from '@/store/menuSlice';

export const ReviewModal = () => {
  const { isLoading, mutateAsync } = useMutateRating();
  const dispatch = useDispatch();
  const { reviewItem, isReviewOpen } = useSelector((store) => store.menu);

  const { control, register, watch, handleSubmit, reset } = useForm({ defaultValues: { rating: 0, comment: '' } });

  const { executorId, companyId, name, lastname, firstname, categories, categoryDetail, image, avatarPath, avatarOriginalName, avatarPathSecond, product } = reviewItem || {};

  const { productId, avatarPath: productPath, name: productName } = product || {};

  const itemImage = avatarOriginalName || image || avatarPath || avatarPathSecond || productPath;

  const itemName = name ?? productName ?? `${lastname ?? ''} ${firstname ?? ''}`;

  const itemCategory = first(categories) ?? categoryDetail;

  const toggleReview = () => {
    reset();
    dispatch(menuActions.toggleReview());
  };

  const onSubmit = async (values) => {
    try {
      await mutateAsync({ rateItem: reviewItem, values });
      toast.success('Амжилттай үнэлгээ өглөө.');
      toggleReview();
    } catch (e) {
      toast.error('Алдаа гарлаа дахин оролдн уу.');
    }
  };

  return (
    <Transition
      show={isReviewOpen}
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 -translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-1">
      <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto" onClose={toggleReview}>
        <div className="min-h-screen text-center">
          <Transition.Child as={Fragment}>
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child as={Fragment}>
            <span
              className={clsx(
                'inline-block w-full max-w-lg transform overflow-hidden rounded-sm bg-white p-6 text-left align-middle shadow-xl transition-all',
                isObject(reviewItem) || 'h-96'
              )}>
              {reviewItem && (
                <>
                  <div className="row space-x-4 border-b border-b-border-light pb-6">
                    <div className="w-20">
                      <div className={clsx(companyId ? 'square-image' : 'people-image-container')}>
                        <img src={itemImage} alt={`review ${itemName}`} />
                      </div>
                    </div>
                    <div className="flex flex-col justify-center space-y-1">
                      <p className="font-title-m">{itemName}</p>
                      <p className="font-body-xs text-secondary-medium">
                        {itemCategory?.name ?? (executorId && 'Хувь хүн') ?? (companyId && 'Байгуулга') ?? (productId && 'Бүтээгдэхүүн')}
                      </p>
                    </div>
                  </div>
                  <div className="mt-6 space-y-2 border-b border-b-border-light pb-6 text-center">
                    <p className="font-title-m">Таны өгөх үнэлгээ</p>

                    <Controller
                      control={control}
                      name="rating"
                      render={({ field }) => (
                        <Rating
                          size={24}
                          onClick={field.onChange}
                          ratingValue={field.value}
                          fillColor="#FFC043"
                          emptyColor="#B7B7B7"
                          fullIcon={<Star fill="#FFC043" className="mx-0.5 inline" size={24} />}
                          emptyIcon={<Star fill="#B7B7B7" className="mx-0.5 inline" size={24} />}
                        />
                      )}
                    />
                  </div>

                  <textarea
                    {...register('comment')}
                    placeholder="Та сэтгэгдлээ энд бичнэ үү..."
                    className="font-body-m mt-6 w-full bg-background p-4 text-primary placeholder:text-secondary-medium focus:outline-none"
                    name=""
                    id=""
                    rows="3"
                  />

                  <div className="row mt-4 w-full space-x-4">
                    <Button onClick={handleSubmit(onSubmit)} disabled={isLoading || watch('rate') === 0} className="flex-1" variant="filled">
                      Хадгалах
                    </Button>
                    <Button disabled={isLoading} className="flex-1" variant="outlined" onClick={toggleReview}>
                      Цуцлах
                    </Button>
                  </div>
                </>
              )}
            </span>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
