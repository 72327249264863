import { Transition, Dialog } from '@headlessui/react';
import { SearchIcon, XIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from '@/components/Elements';
import { useCompany } from '@/features/company';
import { usePeople } from '@/features/people';
import { useProducts } from '@/features/shop';
import useKeypress from '@/hooks/useKeypress';
import { searchActions } from '@/store/searchSlice';
import { checkIsAgencies, checkIsPeople, checkIsProducts } from '@/utils';

import { SearchCategories, SearchResult } from './index';

export const SearchModal = ({ isSearchOpen, toggleSearch }) => {
  const dispatch = useDispatch();
  const { searchValue, selectedCategory } = useSelector((state) => state.search);

  const setSearchValue = (value) => dispatch(searchActions.setSearchValue({ value }));
  const setSelectedCategory = (category) => dispatch(searchActions.setSelectedCategory(category));

  const isPeople = checkIsPeople(selectedCategory);
  const isAgencies = checkIsAgencies(selectedCategory);
  const isProducts = checkIsProducts(selectedCategory);

  const searchParam = new URLSearchParams({ searchValue, perPage: '4' });

  const { data: peopleData, isLoading: peopleIsLoading } = usePeople(searchParam, { enabled: isPeople && !!searchValue });

  const { data: agenciesData, isLoading: agenciesIsLoading } = useCompany(searchParam, { enabled: isAgencies && !!searchValue });

  const { data: productsData, isLoading: productsIsLoading } = useProducts(searchParam, { enabled: isProducts && !!searchValue });

  useKeypress('Escape', () => isSearchOpen && toggleSearch());

  const isLoading = peopleIsLoading || agenciesIsLoading || productsIsLoading;

  const searchedValue = (isPeople && peopleData?.executors) || (isAgencies && agenciesData?.companies) || (isProducts && productsData?.products) || [];

  return (
    <Transition
      show={isSearchOpen}
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 -translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-1">
      <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto" onClose={() => null}>
        <div className="fixed inset-0 z-50 h-screen w-screen overflow-hidden overflow-y-scroll bg-white px-12">
          <button className="absolute top-8 right-5" onClick={toggleSearch}>
            <XIcon className="absolute top-3 right-5 h-6 w-6 text-black" />
          </button>

          <div className="mx-auto max-w-screen-mymd">
            <div className="mt-24 flex flex-row items-center border-b border-b-black pb-2.5">
              <div className="flex w-full items-center md:px-16">
                <SearchIcon className="mr-1 h-4 w-4 text-zinc-900 placeholder:text-zinc-400 md:mr-6 md:h-6 md:w-6" />
                <input
                  autoComplete="off"
                  className="w-full text-sm focus:outline-none md:text-2xl"
                  type="text"
                  name="search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Хайж буй зүйлээ энд бичнэ үү."
                />
              </div>
            </div>

            <SearchCategories selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />

            {!searchValue && <p className="my-3 text-xs text-zinc-500 md:ml-10 md:text-sm">Жишээ нь: Хүний нэр, ажлын нэр, брэнд нэр</p>}

            {isLoading && (
              <div className="flex h-96 w-full items-center justify-center">
                <Spinner />
              </div>
            )}
            {!isLoading && <SearchResult toggleSearch={toggleSearch} searchValue={searchValue} searchedValue={searchedValue} selectedCategory={selectedCategory} />}
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
