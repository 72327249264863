import React from 'react';
import { useDispatch } from 'react-redux';

import { cartActions } from '@/store/cartSlice';
import { packActions } from '@/store/packSlice';
import storage from '@/utils/storage';

export const InitReduxData = ({ children }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const items = storage.getCartItems();

    if (items && items.length > 0) {
      dispatch(cartActions.setCartItems({ items }));
    }
    dispatch(packActions.setPackItems());
  }, [dispatch]);

  return children;
};
