import { useQuery } from 'react-query';

import { axios } from '@/libs/axios';

import { homeKeys } from './homeKeys';

export const fetchHeader = async () => {
  const res = await axios.get('v1/category/header');
  return JSON.stringify(res.headerData);
};

export const useHeader = () => {
  return useQuery(homeKeys.header(), fetchHeader);
};
