import React from 'react';
import { useParams } from 'react-router-dom';

import { Head } from '@/components/Head';
import { Tabs } from '@/components/Tabs';
import { TopDetail } from '@/components/TopDetail';
import { categoryTypes } from '@/constants/categoryTypes';

import { useCompanyDetail } from '../api/useCompanyDetail';

// xs 12 sm 14 base 16 xl 20 2xl 24

export const CompanyDetail = () => {
  let params = useParams();
  const { agencyId } = params;
  const { data } = useCompanyDetail(agencyId, { enabled: !!agencyId });

  return (
    <div className="">
      <Head title={data?.name} description={data?.description} image={data?.avatarOriginalName} width={360} height={360} />
      <TopDetail detail={data} type={categoryTypes.company} />
      <Tabs type={categoryTypes.company} detail={data} />
    </div>
  );
};
