import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';
import { Fragment, useState } from 'react';
import { ChevronDown } from 'react-feather';

export const Select = ({ sizes, onPress }) => {
  const [selected, setSelected] = useState(sizes[0]);
  const onChangeSelectedSize = (item) => {
    onPress(item);
    setSelected(item);
  };
  return (
    <div className="mb-10">
      <Listbox value={selected} onChange={onChangeSelectedSize}>
        <div className="relative mt-1">
          <Listbox.Button className="focus-visible:ring-offset-orange-300 relative w-full cursor-default border border-border   py-3 pl-3 pr-10 text-left  focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm">
            <span className="block truncate">{selected}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDown className="h-6 w-6 text-primary" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded bg-white  text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {sizes.map((mySize, sizeIdx) => (
                <Listbox.Option
                  key={`product-detail-${sizeIdx}`}
                  className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-primary text-white' : 'text-primary'}`}
                  value={mySize}>
                  {({ selected }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{mySize}</span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-support">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
