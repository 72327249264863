import { useQuery } from 'react-query';

import { axios, decodeUrl } from '@/libs/axios';

import { peopleKeys } from '../api/peopleKeys';

const fetchPeople = (searchParams) => {
  return axios.get(`v1/people?${decodeUrl(searchParams.toString())}`);
};
export const usePeople = (searchParam, opt) => {
  // const [name] = useDebounce(filter?.name, 300);
  return useQuery(peopleKeys.list(searchParam.toString()), () => fetchPeople(searchParam), opt);
};
