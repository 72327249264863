import clsx from 'clsx';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { TugCurrency } from '@/libs/react-number-format';
export const OrderItem = ({ orderItem }) => {
  const { createdAt, totalAmount, paid, orderId } = orderItem || {};
  const text = paid ? 'Төлбөр төлсөн' : 'Төлбөр төлөөгүй';
  return (
    <Link to={`/orders/${orderId}`}>
      <div className="overflow-hidden rounded border pt-4">
        <div className=" mb-2 flex items-center px-4">
          <div className={clsx(paid ? 'bg-light-high' : 'bg-yellow', 'mr-2 h-2 w-2 rounded ')} />
          <p className="font-body-s text-primary">{text}</p>
        </div>
        <div className="py-2 px-4">
          <div className=" flex justify-between ">
            <p className=" font-body-s md:font-body-m">Захиалгын дугаар</p>
            <p className="font-body-s md:font-body-m text-right">Захиалсан огноо</p>
          </div>
          <div className="flex justify-between">
            <p className="font-body-m md:font-body-l  mr-2 text-primary md:mr-0">{orderId}</p>
            <p className="font-body-m md:font-body-l  text-right text-primary">{format(new Date(createdAt), 'yyyy/MM/dd hh:ss')}</p>
          </div>
        </div>
        <div className={'flex items-center justify-between border-t-[1px] bg-light-disabled px-4 py-4'}>
          <p className="font-body-m text-primary">Захиалгын дүн</p>
          <TugCurrency value={totalAmount} className="font-body-l text-primary" />
        </div>
      </div>
    </Link>
  );
};
