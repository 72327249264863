import { useMemo } from 'react';

export const useProductOptions = (productOptions = []) => {
  const normalizedOptions = useMemo(
    () =>
      productOptions?.reduce((a, c) => {
        const { size } = c;

        const normalizedSize = size?.toUpperCase();

        if (normalizedSize in a) {
          a[normalizedSize] = [...a[normalizedSize], c];
        } else {
          a[normalizedSize] = [c];
        }
        return a;
      }, {}),
    [productOptions]
  );

  const hasOptions = useMemo(() => Object.keys(normalizedOptions)?.length > 0, [normalizedOptions]);

  const productSizes = useMemo(() => Object.keys(normalizedOptions).filter((key) => normalizedOptions[key].length > 0), [normalizedOptions]);

  return { hasOptions, productSizes, normalizedOptions };
};
