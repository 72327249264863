import clsx from 'clsx';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Element } from 'react-scroll';

import { Spinner } from '@/components/Elements';
import { Head } from '@/components/Head';
import { NewsItem } from '@/components/News';
import { useNews, useNewsDetail } from '@/features/news';
import { useFilterName } from '@/hooks/useFilterName';
import { useSearchState } from '@/hooks/useSearchState';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';
import { periodFormat } from '@/utils/dateFormats';

export const NewsDetail = () => {
  const { isMd, isSm } = useWindowDimensions();
  const navigate = useNavigate();
  let params = useParams();
  const { articleId } = params;
  const { data, isLoading } = useNewsDetail(articleId, { enabled: !!articleId });
  const { debouncedName } = useFilterName();
  const { newsSearchParam } = useSearchState(debouncedName);
  const { data: temp, isLoading: newsLoading } = useNews(newsSearchParam);
  const { article } = temp || {};
  const tempArticle = [{ articleId: 'tempArticle1' }, { articleId: 'tempArticle2' }, { articleId: 'tempArticle3' }];
  const { headline, description, avatarOriginalName, content, authorName, authorType, datePublished } = data || {};
  React.useEffect(() => {
    if (!isLoading && !data) navigate('/404');
  }, [data, isLoading, navigate]);
  if (isLoading) {
    return (
      <>
        <Head title={'Мэдээ'} description={'Мэдээ'} />
        <div className={'flex h-full w-full items-center justify-center'}>
          <Spinner />
        </div>
      </>
    );
  }
  return (
    <div>
      <div className="layout center">
        <div className={clsx('justify-self-auto py-10', isMd ? 'w-full px-8' : 'w-3/5')}>
          <Head title={headline} description={'Мэдээ'} />
          <p className="font-headline-l md:font-display-l py-10  text-secondary-high">{headline}</p>
          <div className="flex justify-between pb-5">
            <p className="font-body-l text-secondary-high">{authorType}</p>
            <div className="flex">
              <p className="font-body-l pr-5 text-secondary-high">{authorName}</p>
              <p>{periodFormat(datePublished)}</p>
            </div>
          </div>
          <p className="font-body-l text-secondary-high">{description}</p>
          <img src={avatarOriginalName} alt="news" className="w-full py-20" />
          <div className="col-span-12">
            <Element className="w-full" name="home">
              <div className="" dangerouslySetInnerHTML={{ __html: content }}></div>
            </Element>
          </div>
        </div>
      </div>
      <div className="bg-white py-20">
        <div className="layout px-5 md:px-10 xl:px-0">
          {newsLoading ? (
            <div className={clsx('grid grid-cols-1 gap-x-7 md:grid-cols-2 lg:grid-cols-3')}>
              {tempArticle.map((a) => (
                <NewsItem isLoading={true} key={`detail-article-${a?.articleId}`} item={a} />
              ))}
            </div>
          ) : (
            <div className={clsx('grid grid-cols-1 gap-x-7 md:grid-cols-2 lg:grid-cols-3')}>
              {article
                ?.filter((e) => e?.articleId !== data?.articleId)
                ?.slice(0, isMd ? 2 : 3)
                ?.map((a) => (
                  <NewsItem isLoading={false} key={`detail-article-${a?.articleId}`} item={a} />
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
