import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import React, { Fragment } from 'react';
import { MoreVertical } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

import { menuActions } from '@/store/menuSlice';

export const ListFilter = ({ children }) => {
  const dispatch = useDispatch();
  const isCategoryOpen = useSelector((state) => state.menu.isCategoryOpen);
  const toggleCategory = () => dispatch(menuActions.toggleCategory());

  return (
    <>
      <div className="hidden h-full mymd:block mymd:w-72">{children}</div>

      <button className="mt-10 mb-4 block flex bg-white px-10 py-2 mymd:hidden" onClick={toggleCategory}>
        <MoreVertical className="h-6 w-6 text-zinc-700" />
        <p>Ангилал</p>
      </button>
      <Transition show={isCategoryOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in duration-300"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in duration-300"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full">
          <div aria-hidden="true" className="fixed inset-0 z-30 h-screen w-screen bg-white">
            <div aria-hidden="true" className="relative z-50 h-full w-screen overflow-y-scroll px-8 py-14">
              <button className="absolute top-8 right-5" onClick={toggleCategory}>
                <XIcon className="absolute top-3 right-5 h-6 w-6 text-black" />
              </button>

              {React.cloneElement(children, { toggleCategory })}
            </div>
          </div>
        </Transition.Child>
      </Transition>
    </>
  );
};
