import { orderStatus } from '@/constants/order';

export const checkStatus = (paid, status) => {
  let paidText = paid ? 'Төлбөр төлөгдсөн' : 'Төлбөр төлөгдөөгүй';
  let statusText = '';
  switch (status?.toUpperCase()) {
    case orderStatus.cancelled?.toUpperCase():
      statusText = 'Буцаагдсан';
      break;
    case orderStatus.completed?.toUpperCase():
      statusText = 'Хүргэгдсэн';
      break;
    case orderStatus.initiated?.toUpperCase():
      statusText = 'Бүртгэгдсэн';
      break;
    case orderStatus.ongoing?.toUpperCase():
      statusText = 'Хүлээгдэж байгаа';
      break;
    default:
      statusText = 'Төлөв тодорхойгүй';
  }
  return { paidText, statusText };
};
