import { useQuery } from 'react-query';

import { axios } from '@/libs/axios';

import { peopleKeys } from '../api/peopleKeys';

const fetchPeopleDetail = (id) => axios.get(`/v1/executor/${id}`);

export const usePeopleDetail = (id, opt) => {
  return useQuery(peopleKeys.detail(id), () => fetchPeopleDetail(id), opt);
};
