import { useQuery } from 'react-query';

import { axios, decodeUrl } from '@/libs/axios';

import { newsKeys } from '../api/newsKeys';

const fetchNews = (searchParams) => {
  return axios.get(`v1/article?${decodeUrl(searchParams.toString())}`);
};

export const useNews = (searchParams, opt = {}) => {
  return useQuery(newsKeys.list(searchParams.toString()), () => fetchNews(searchParams), opt);
};
