import clsx from 'clsx';

const colors = {
  primary: 'primary',
  support: 'support'
};

const sizes = {
  sm: 'sm',
  md: 'md',
  lg: 'lg'
};

const getButtonStyle = (color, size, disabled) => {
  let buttonStyle = 'rounded-sm';

  if (disabled) {
    buttonStyle = clsx(buttonStyle, 'cursor-not-allowed');
    switch (color) {
      case colors.primary:
        buttonStyle = clsx(buttonStyle, 'text-primary-disabled');
        break;
      case colors.support:
        buttonStyle = clsx(buttonStyle, 'text-support-disabled');
        break;
    }
  } else {
    buttonStyle = clsx(buttonStyle, 'cursor-pointer');
  }

  switch (color) {
    case colors.primary:
      buttonStyle = clsx(buttonStyle, 'text-primary');
      break;
    case colors.support:
      buttonStyle = clsx(buttonStyle, 'text-support');
      break;
  }

  switch (size) {
    case sizes.sm:
      buttonStyle = clsx(buttonStyle, 'h-5 w-5');
      break;
    case sizes.md:
      buttonStyle = clsx(buttonStyle, 'h-6 w-6');
      break;
    case sizes.lg:
      buttonStyle = clsx(buttonStyle, 'h-10 w-10');
      break;
  }

  return buttonStyle;
};

export const IconButton = ({ children, color = colors.primary, onClick, isLoading, disabled, size = 'lg', className }) => {
  const buttonStyle = getButtonStyle(color, size, disabled);
  return (
    <button onClick={onClick} disabled={isLoading || disabled} className={clsx('center', className, `text-${color} icon-button`, buttonStyle)}>
      {children}
    </button>
  );
};
