import { useQuery } from 'react-query';

import { axios } from '@/libs/axios';

import { orderKeys } from './orderKeys';

const fetchOrder = (orderId) => {
  return axios.get(`/customer/order/${orderId}`);
};

export const useOrderDetail = (orderId, enabled) => {
  return useQuery(orderKeys.detail(orderId), () => fetchOrder(orderId), { enabled });
};
