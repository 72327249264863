import React from 'react';

import { SearchResultNotFound } from '@/components/Elements';
import { NewsItem } from '@/components/News';
import { categoryTypes } from '@/constants/categoryTypes';
import { useNews } from '@/features/news';
import { useFilterName } from '@/hooks/useFilterName';
import { useSearchState } from '@/hooks/useSearchState';

export const NewsContainer = ({ id, type }) => {
  const { debouncedName } = useFilterName();
  const { newsSearchParam } = useSearchState(debouncedName);
  if (type === categoryTypes.person) newsSearchParam.set('executor', id);
  if (type === categoryTypes.person) newsSearchParam.set('perPage', '6');
  if (type === categoryTypes.company) newsSearchParam.set('company', id);
  const { data: temp, isLoading: newsLoading } = useNews(newsSearchParam);
  const { article } = temp || {};
  const tempArticle = [{ articleId: 'tempArticle1' }, { articleId: 'tempArticle2' }, { articleId: 'tempArticle3' }];
  if (newsLoading) {
    return (
      <div className=" grid gap-4 md:grid-cols-3">
        {tempArticle.map((n, i) => (
          <NewsItem isLoading={newsLoading} item={n} key={'temp' + n.articleId} />
        ))}
      </div>
    );
  }
  if (!newsLoading && article?.length === 0) {
    return <SearchResultNotFound />;
  }
  return (
    <div className=" grid gap-4 md:grid-cols-3">
      {article?.map((n, i) => (
        <NewsItem isLoading={newsLoading} item={n} key={n?.articleId + 'i'} />
      ))}
    </div>
  );
};
