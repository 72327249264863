import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@/components/Elements';
import { SuperSwiper } from '@/components/SuperSwiper';
import { getLinkToList } from '@/utils/link';

export const HomeItem = ({ category }) => {
  if (category?.data?.length === 0) return null;
  return (
    <div key={`home-swiper-${category.categoryId}`} className="super-swiper overflow-hidden py-16 odd:bg-white even:bg-light-layout ">
      <div className="layout layout-space">
        <div className=" mb-8 flex justify-between ">
          <div>
            <p className="font-headline-s mb-2 font-medium text-primary">{category.featuredTitle}</p>
            <p className="font-body-lg text-secondary-medium"> {category.featuredDescription}</p>
          </div>
        </div>

        <SuperSwiper category={category} />
        {category.categoryId && (
          <div className="center mt-12">
            <Link to={getLinkToList(category)}>
              <Button variant="outlined">Бүгдийг харах</Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
