import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { XCircle } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, IconButton } from '@/components/Elements';
import { PackModalList, NEW_PACK } from '@/features/pack';
import { useCheckUser } from '@/hooks/useCheckUser';
import useKeypress from '@/hooks/useKeypress';
import { packActions, packSelector } from '@/store/packSlice';

export const PackModal = ({ isPackOpen, togglePack }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasPackItem = useSelector(packSelector.hasPackItem);
  const { handleOnClickSubscriptionRequired } = useCheckUser();
  useKeypress('Escape', () => isPackOpen && togglePack());

  const handlePackOrder = () => {
    navigate(`/pack/${NEW_PACK}`);
  };

  const handleResetPack = () => {
    togglePack();
    dispatch(packActions.resetPack());
  };

  return (
    <Transition
      show={isPackOpen}
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 -translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-1">
      <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto" onClose={togglePack}>
        <Transition.Child as={Fragment}>
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        </Transition.Child>

        <Transition.Child as={Fragment}>
          <div className="absolute right-0 z-40 w-full max-w-lg	space-y-10 overflow-hidden bg-white">
            <div className="p-4">
              <div className="flex items-center justify-between pb-8">
                <p className="font-body-l text-primary">{hasPackItem ? 'Багц' : 'Таны багц хоосон байна.'}</p>

                <IconButton onClick={togglePack} size="md" color="primary">
                  <XCircle />
                </IconButton>
              </div>

              {hasPackItem || (
                <div className="flex w-full justify-center">
                  <img className="h-2/3 w-2/3" src={require('@/assets/emptyCart.png')} alt="Багц хоосон байна." />
                </div>
              )}
              {hasPackItem && <PackModalList hasEdit togglePack={togglePack} />}
            </div>
            {hasPackItem && (
              <div className="bg-primary py-6 px-4">
                <div className="row space-x-4">
                  <Button onClick={handleOnClickSubscriptionRequired(handlePackOrder, togglePack)} className="flex-1 bg-white" variant="outlined" color="white">
                    Баталгаажуулах
                  </Button>
                  <Button onClick={handleResetPack} className="flex-1 border-white text-white" variant="outlined">
                    Багц хоослох
                  </Button>
                </div>
                <span className="font-label-m m-5 text-center text-primary-medium">
                  <p>Хэрвээ та багц хоосолсон тохиолдолд таны багц доторх хувь хүн, байгуулна алга болно.</p>
                </span>
              </div>
            )}
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};
