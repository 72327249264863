import React, { useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ListFilter, SearchResultNotFound } from '@/components/Elements';
import { FilterSearch } from '@/components/Elements/FilterSearch/FilterSearch';
import { RoutePath } from '@/components/Elements/RoutePath';
import { Head } from '@/components/Head';
import { PeopleItem } from '@/components/People';
import { categoryTypes } from '@/constants/categoryTypes';
import { useInfinitePeople, usePeople } from '@/features/people';
import { useFilterName } from '@/hooks/useFilterName';
import { useSearchState } from '@/hooks/useSearchState';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';

import { PeopleFilter } from '../components/PeopleFilter';

const RenderPeopleItem = () => {
  return (
    <div className=" animate-pulse rounded">
      <div className="people-image-container bg-slate-300" />
      <div className="mt-4">
        <div className={`h-${5} mb-2 w-2/3 rounded bg-slate-200`} />
        <div className={`h-${3} w-1/2 rounded bg-slate-200`} />
      </div>
    </div>
  );
};
export const People = () => {
  const { isLg, isSm } = useWindowDimensions();
  const { name, debouncedName, setName } = useFilterName();
  const { searchParams, featuredSearchParams } = useSearchState(debouncedName);
  searchParams.set('searchValue', debouncedName);
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfinitePeople(searchParams);
  const { data: featured, isLoading: isFeaturedLoading } = usePeople(featuredSearchParams);
  const featuredData = [1, 2, 3, 4];
  const standardData = [1, 2, 3, 4, 5, 6];
  const swiperRef = useRef(null);
  return (
    <div>
      <Head title="Хувь хүн" description="Хувь хүн хайх" />
      <>
        <div className="w-full bg-primary">
          <div className="layout layout-space py-10">
            <RoutePath type={categoryTypes.person} categoryId={searchParams.get('category')} />
          </div>
        </div>
        <div className="mymd:row layout layout-space h-full pt-10 md:pt-20 mymd:space-x-8">
          <ListFilter>
            <PeopleFilter filter={searchParams.toString()} />
          </ListFilter>

          <div className="flex flex-1 flex-col items-start">
            <FilterSearch name={name} setName={setName} />
            {isFeaturedLoading ? (
              <div className="mt-10 w-full md:mt-20">
                <div className=" animate-pulse rounded">
                  <div className="mb-6 h-8 w-24 bg-slate-300" />
                </div>
                <div className="grid grid-cols-2 gap-8 xl:grid-cols-3">
                  {featuredData?.slice(0, isLg ? 4 : 3)?.map((id) => (
                    <RenderPeopleItem key={`featured-skeleton-${id}`} />
                  ))}
                </div>
              </div>
            ) : (
              <>
                {featured?.executors.length > 0 && (
                  <div className="mt-10 w-full md:mt-20">
                    <p className="font-title-l mb-6">Онцгой</p>
                    {isSm ? (
                      <div className="relative">
                        <Swiper ref={swiperRef} slidesPerView={1.25} spaceBetween={30} loop={true} modules={[Navigation]}>
                          {featured?.executors?.map((person) => (
                            <SwiperSlide key={`featured-${person.executorId}`}>{isLoading ? <RenderPeopleItem /> : <PeopleItem value={person} />}</SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    ) : (
                      <div className="grid grid-cols-2 gap-8 xl:grid-cols-3">
                        {featured?.executors?.slice(0, isLg ? 4 : 3)?.map((person) => (
                          <PeopleItem key={`featured-${person.executorId}`} value={person} />
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
            {isLoading && !data?.pages && (
              <div className="mt-10 w-full md:mt-20">
                <div className=" animate-pulse rounded">
                  <div className="mb-6 h-8 w-24 bg-slate-300" />
                </div>
                <div className="grid grid-cols-2 gap-8 xl:grid-cols-3">
                  {standardData.slice(0, isLg ? 4 : 6).map((i, index) => (
                    <RenderPeopleItem key={index + 'standardLoading' + i} />
                  ))}
                </div>
              </div>
            )}
            <div className="mt-10 w-full md:mt-20">
              <p className="font-title-l mb-6 text-zinc-700">Стандарт</p>
              <InfiniteScroll
                className="space-y-2 md:space-y-5"
                dataLength={data?.pages?.length || 0} //This is important field to render the next data
                next={fetchNextPage}
                hasMore={hasNextPage}
                loader={
                  <div className="grid grid-cols-2 gap-8 xl:grid-cols-3">
                    {standardData.slice(0, isLg ? 4 : 6).map((i, index) => (
                      <RenderPeopleItem key={index + 'standardLoading' + i} />
                    ))}
                  </div>
                }>
                {data?.pages?.map((page, i) => (
                  <div className="grid grid-cols-2 gap-8 xl:grid-cols-3" key={i}>
                    {page?.executors?.map((person) => (
                      <PeopleItem key={person.executorId} value={person} />
                    ))}
                  </div>
                ))}
              </InfiniteScroll>
            </div>
            {!isLoading && data?.pages[0]?.executors?.length === 0 && <SearchResultNotFound />}
          </div>
        </div>
      </>
    </div>
  );
};
