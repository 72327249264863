import { useQuery } from 'react-query';

import { axios } from '@/libs/axios';

import { homeKeys } from './homeKeys';

const fetchBanners = () => axios.get('/v1/banner');

export const useBanners = () => {
  return useQuery(homeKeys.banners(), fetchBanners);
};
