import clsx from 'clsx';
import React from 'react';
import { Facebook, Instagram, Youtube } from 'react-feather';
import { Link } from 'react-router-dom';

import modelsLogo from '@/assets/models_logo-white.png';
// const linkClasses = 'py-2 px-2 md:px-3 hover:text-gray-300 cursor-pointer text-ellipsis text-primary-medium font-label-large';
const smallLinkClasses = 'px-1 md:px-2 hover:text-gray-300 cursor-pointer text-ellipsis overflow-hidden text-primary-medium font-label-large';
const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="bg-primary pt-8 pb-10 text-white md:pt-16 md:pb-20">
      <div className="layout px-4 md:px-12 2xl:px-0">
        <div className="grid grid-cols-1 border-b border-white pt-8 pb-8 md:grid-cols-2">
          <Link to="/" className="justify-self-center md:justify-self-start">
            <img className="mx-auto mb-6 w-24 cursor-pointer md:mx-0" src={modelsLogo} alt="Models.mn Logo" />
            <p className={clsx('font-body-m text-center text-primary-medium')}>© {year} MODELS.mn, ХХК Бүх эрх хуулиар хамгаалагдсан</p>
          </Link>
          <div className="mt-3 flex items-center justify-center md:justify-end">
            <a href={'https://www.instagram.com/modelsmanagementmn/'} target="_blank" rel="noreferrer" className="mr-6">
              <Instagram size={24} />
            </a>
            <a href={'https://www.youtube.com/channel/UCMVs6_lGBg_2b4GslvN0OfQ'} target="_blank" rel="noreferrer" className="mr-6">
              <Youtube size={24} />
            </a>
            <a href={'https://www.facebook.com/ModelsModelManagementUlaanbaatar'} target="_blank" rel="noreferrer">
              <Facebook size={24} />
            </a>
          </div>
        </div>
        <div className=" grid grid-cols-2 justify-between pt-8">
          <div className="md:text justify-items:center col-start-2 flex flex-col justify-between text-sm md:flex-row md:justify-end">
            <Link className={smallLinkClasses} to="/privacy">
              Нууцлалын бодлого
            </Link>
            <Link to="/terms" className={smallLinkClasses}>
              Үйлчилгээний нөхцөл
            </Link>
            <Link className={smallLinkClasses} to="/faq">
              Тусламж
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
