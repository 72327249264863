import { initReactQueryAuth } from 'react-query-auth';

import { Spinner } from '@/components/Elements';
import { getUser, loginWithEmailAndPassword, registerWithEmailAndPassword } from '@/features/auth';
import storage from '@/utils/storage';

async function handleUserResponse(data) {
  const { token, user } = data;
  storage.setToken(token);
  return user;
}

async function loadUser() {
  try {
    if (storage.getToken()) {
      return await getUser();
    }
  } catch (e) {
    storage.clearToken();
    return null;
  }
  return null;
}

async function loginFn(data) {
  const response = await loginWithEmailAndPassword(data);
  return await handleUserResponse(response);
}

async function registerFn(data) {
  await registerWithEmailAndPassword(data);
  return null;
}

async function logoutFn() {
  storage.clearToken();
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Spinner size="xl" />
      </div>
    );
  }
};

export const { AuthProvider, useAuth } = initReactQueryAuth(authConfig);
