import { useQuery } from 'react-query';

import { axios, decodeUrl } from '@/libs/axios';

import { workKeys } from './workKeys';

const fetchWorks = (searchParams) => {
  return axios.get(`v1/work?${decodeUrl(searchParams.toString())}`);
};

export const useWorks = (searchParams, opt = {}) => {
  return useQuery(workKeys.list(searchParams.toString()), () => fetchWorks(searchParams), opt);
};
