import { useEffect, useMemo, useState } from 'react';
import { Box, Menu, Search, ShoppingCart } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

import { Button, IconButton, Logo } from '@/components/Elements';
import { CountItem } from '@/components/Layout/MainLayout/components/CountItem';
import { orderTypes } from '@/constants/order';
import { useHeader } from '@/features/home';
import { useOrder } from '@/features/order';
import { useCheckUser } from '@/hooks/useCheckUser';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';
import { useAuth } from '@/libs/auth';
import { cartSelector } from '@/store/cartSlice';
import { menuActions } from '@/store/menuSlice';
import { packSelector } from '@/store/packSlice';
import { searchActions } from '@/store/searchSlice';
import storage from '@/utils/storage';

import Footer from '../Footer';

import { CartModal, HeaderCategories, LoginModal, MenuModal, PackModal, PackOrderModal, ProfileDropdown, RateRemainderModal, ReviewModal, SearchModal } from './components';

const MobileHeaderMenu = ({ toggleLogin, toggleSearch, toggleMenu }) => {
  const { user, logout } = useAuth();

  const { isUserLoggedIn, isUserSubscriptionValid } = useCheckUser();

  return (
    <div className="grid h-10 grid-cols-2 md:hidden">
      <div className="flex items-center">
        <Logo />
      </div>
      <div className="row items-center justify-end space-x-4">
        <IconButton size="md" onClick={toggleSearch}>
          <Search />
        </IconButton>
        <div>
          {isUserLoggedIn || <Button onClick={toggleLogin} text="Нэвтрэх" variant="filled" />}
          {isUserLoggedIn && <ProfileDropdown user={user} isUserSubscriptionValid={isUserSubscriptionValid} logout={logout} />}
        </div>
        <IconButton size="md" onClick={toggleMenu}>
          <Menu />
        </IconButton>
      </div>
    </div>
  );
};

const HeaderMenu = ({ togglePack, toggleLogin, toggleCart, toggleSearch, toggleMenu }) => {
  const { isLg } = useWindowDimensions();
  const { user, logout } = useAuth();

  const { isUserLoggedIn } = useCheckUser();

  const { data } = useHeader();

  const headerData = data && JSON.parse(data);

  const cartItemCount = useSelector(cartSelector.cartItemCount);
  const packItemCount = useSelector(packSelector.getPackLength);

  return (
    <div className="hidden w-full justify-between md:flex">
      <div className="row items-center justify-center space-x-10">
        {isLg && (
          <span className="visible lg:hidden">
            <IconButton onClick={toggleMenu}>
              <Menu />
            </IconButton>
          </span>
        )}

        <Logo />

        <span className="hidden lg:flex">
          <div className="row items-center">
            {headerData?.map((header) => (
              <HeaderCategories key={`header-category-${header.categoryId}`} header={JSON.stringify(header)} />
            ))}
          </div>
        </span>
      </div>

      <div className="flex items-center justify-end space-x-2">
        <IconButton onClick={toggleSearch} className="row items-center">
          <Search />
        </IconButton>

        <IconButton onClick={toggleCart} className="row relative items-center space-x-2">
          <ShoppingCart />
          <CountItem count={cartItemCount} />
        </IconButton>

        <Button className="relative" onClick={togglePack} startIcon={<Box />}>
          <p className="hidden md:inline">Багц</p>
          <CountItem count={packItemCount} />
        </Button>

        <div className="pl-4">
          {isUserLoggedIn || <Button onClick={toggleLogin} text="Нэвтрэх" variant="filled" />}
          {isUserLoggedIn && <ProfileDropdown user={user} logout={logout} />}
        </div>
      </div>
    </div>
  );
};

export function MainLayout({ children, hasFooter = true }) {
  const searchParams = useMemo(() => new URLSearchParams({ type: orderTypes.service, ratingGiven: '0', perPage: '100', status: 'completed' }), []);
  const token = storage.getToken();
  const { data } = useOrder(searchParams, { enabled: !!token });

  const { isSearchOpen, isCartOpen, isLoginOpen, isPackOrderOpen, isPackOpen, isMenuOpen } = useSelector((store) => store.menu);
  const dispatch = useDispatch();

  const toggleSearch = () => {
    if (isSearchOpen) {
      dispatch(searchActions.resetSearch());
    }
    dispatch(menuActions.toggleSearch());
  };
  const toggleCart = () => dispatch(menuActions.toggleCart());
  const toggleLogin = () => dispatch(menuActions.toggleLogin());
  const togglePack = () => dispatch(menuActions.togglePack());
  const togglePackOrder = () => dispatch(menuActions.togglePackOrder());
  const toggleMenu = () => dispatch(menuActions.toggleMenu());

  const hasUnratedOrder = useMemo(() => data?.orders?.length > 0, [data?.orders?.length]);

  const [isRateRemainderOpen, setIsRateRemainderOpen] = useState(false);
  useEffect(() => {
    if (hasUnratedOrder) {
      setIsRateRemainderOpen(hasUnratedOrder);
    }
  }, [hasUnratedOrder]);

  return (
    <>
      <header className="border-b border-gray-200 bg-white">
        <SearchModal isSearchOpen={isSearchOpen} toggleSearch={toggleSearch} />
        <LoginModal isLoginOpen={isLoginOpen} toggleLogin={toggleLogin} />
        <PackOrderModal isPackOrderOpen={isPackOrderOpen} togglePackOrder={togglePackOrder} />
        <MenuModal isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
        <ReviewModal />
        <RateRemainderModal orders={data?.orders} isRateRemainderOpen={isRateRemainderOpen} close={() => setIsRateRemainderOpen(!isRateRemainderOpen)} />

        <nav className="fixed left-0 right-0 z-30 h-20 bg-white" aria-label="Top">
          <div className="mx-auto max-w-screen-xl p-5">
            <CartModal isCartOpen={isCartOpen} toggleCart={toggleCart} />
            <PackModal isPackOpen={isPackOpen} togglePack={togglePack} />

            <HeaderMenu toggleLogin={toggleLogin} togglePack={togglePack} toggleCart={toggleCart} toggleSearch={toggleSearch} toggleMenu={toggleMenu} />
            <MobileHeaderMenu toggleLogin={toggleLogin} toggleSearch={toggleSearch} toggleMenu={toggleMenu} />
          </div>
        </nav>
      </header>
      <main className="mt-20 grow bg-background">{children}</main>
      {hasFooter && <Footer />}
    </>
  );
}
