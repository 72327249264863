import { Link } from 'react-router-dom';

import modelsLogo from '@/assets/models_logo.png';

export const Logo = () => (
  <Link to="/" className="cursor-pointer">
    <span className="sr-only">Model.mn</span>
    <img className="h-8" src={modelsLogo} alt="Models.mn Logo" />
  </Link>
);
