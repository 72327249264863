import { useNavigate } from 'react-router-dom';

export const CompanyLabel = ({ item }) => {
  const { name, companyId } = item || {};
  const navigate = useNavigate();
  const goToObject = () => {
    navigate(`/company/${companyId}`);
  };

  return (
    <div className={'mr-4 flex'}>
      <a href={''} onClick={() => goToObject()}>
        <p className={'ml-1 text-sm text-zinc-800 hover:text-blue-500 hover:underline hover:underline-offset-1'}> {name}</p>
      </a>
    </div>
  );
};
