import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'password', function () {
  return this.test('password', '', function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: 'Нууц үг заавал оруулна.' });
    }
    if (value.length < 8) {
      return createError({ path, message: 'Нууц үг 8 тэмдэгээс дээх байх хэрэгтэй.' });
    }
    if (!/[a-z]/.test(value)) {
      return createError({ path, message: 'Нууц үг жижиг тэмдэгт агуулсан байх хэрэгтэй.' });
    }
    if (!/[A-Z]/.test(value)) {
      return createError({ path, message: 'Нууц үг том тэмдэгт агуулсан байх хэрэгтэй.' });
    }
    if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value)) {
      return createError({ path, message: 'Нууц үг тусгай тэмдэгт агуулсан байх хэрэгтэй.' });
    }

    return true;
  });
});

export const yup = Yup;
