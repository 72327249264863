import { Transition } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import React, { Fragment } from 'react';
import { XCircle } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CartItem } from '@/components/CartItem';
import { Button } from '@/components/Elements';
import useKeypress from '@/hooks/useKeypress';
import { TugCurrency } from '@/libs/react-number-format';
import { cartActions } from '@/store/cartSlice';
import { getTotalCartItemsPrice, getTotalDiscountCartItemsPrice } from '@/utils/products';

export const QtyButton = ({ type, onClick, disabled }) => {
  const Icon = type === 'minus' ? MinusIcon : PlusIcon;

  return (
    <button disabled={disabled} className="flex h-5 w-5 items-center justify-center  disabled:cursor-not-allowed" onClick={onClick}>
      <Icon className="h-3 w-3 text-black" />
    </button>
  );
};

const CartModalDetail = ({ toggleCart, cartItems, isConfirmation = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const totalPrice = getTotalCartItemsPrice(cartItems);
  const totalDiscountPrice = getTotalDiscountCartItemsPrice(cartItems);
  const handleOrder = () => {
    navigate('/cart');
    toggleCart();
  };
  const handleResetCart = () => {
    dispatch(cartActions.resetCart());
  };

  return (
    <>
      <div className="h-96 overflow-y-scroll pl-4 ">
        <ul className="divide-y divide-dashed">
          {cartItems.map((item) =>
            isConfirmation ? <CartItem item={item} key={item.id} canChange={false} isModal={true} /> : <CartItem item={item} key={item.id} isModal={true} />
          )}
        </ul>
      </div>
      <div className="w-full bg-primary ">
        <div className={'w-full p-6'}>
          <div className="row mb-6 justify-between">
            <p className="font-label-m text-primary-medium">Нийт бараа</p>
            <TugCurrency value={totalPrice} className="font-title-m text-white" />
          </div>

          <div className="row  mb-6  justify-between">
            <p className="font-label-m text-primary-medium">Хямдрал</p>
            <TugCurrency value={totalDiscountPrice} className="font-title-m text-white" />
          </div>

          <div className="row mb-6 justify-between text-xl">
            <p className="font-label-m text-primary-medium">Нийт үнэ</p>
            <TugCurrency value={(totalPrice - totalDiscountPrice).toFixed(0)} className="font-title-m text-white" />
          </div>

          <div className="mb-5 flex w-full justify-center space-x-4 ">
            <Button onClick={handleOrder} variant="outlined" text="Захиалах" className="flex-1 bg-white" color={'white'} />
            <Button variant="outlined" className="flex-1 border-white text-white" onClick={handleResetCart} text="Сагс хоослох" color={'primary'} />
          </div>
          <p className="font-label-m text-center text-primary-medium">{`Хэрвээ та багц хоосолсон тохиолдолд таны \nбүр бүтээгдэхүүн устах болно`}</p>
        </div>
      </div>
    </>
  );
};

export const CartModal = ({ isCartOpen, toggleCart, isConfirmation }) => {
  const items = useSelector((store) => store.cart.items);

  const hasCartItems = Boolean(items.length);

  const title = hasCartItems ? `Сагс (${items.length})` : 'Таны Сагс хоосон байна.';

  const handleParentOnClick = (e) => {
    if (e.target !== e.currentTarget) return;
    toggleCart();
  };

  useKeypress('Escape', () => isCartOpen && toggleCart());

  return (
    <Transition
      show={isCartOpen}
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 -translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-1">
      <div aria-hidden="true" onClick={handleParentOnClick} className="fixed inset-0 z-30 h-screen w-screen overflow-hidden bg-[rgba(0,0,0,0.5)]">
        <div aria-hidden="true">
          <div className="absolute top-0 right-0 w-full bg-white pr-4 md:w-[30rem]">
            <div className="flex items-center justify-between p-4">
              <p className="font-title-m text-primary">{title}</p>

              <button
                className=""
                onClick={(e) => {
                  toggleCart();
                  e.stopPropagation();
                }}>
                <XCircle className="h-5 w-5 text-primary" />
              </button>
            </div>

            {hasCartItems && <CartModalDetail isConfirmation={isConfirmation} toggleCart={toggleCart} cartItems={items} />}
            {!hasCartItems && (
              <div className="flex w-full justify-center">
                <img className="h-2/3 w-2/3" src={require('@/assets/emptyCart.png')} alt="Карт хоосон байна." />
              </div>
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
};
