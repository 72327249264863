import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { XCircle } from 'react-feather';
import Lottie from 'react-lottie';

import animationData from '@/assets/animation/success.json';

export const OrderSuccessModal = ({ isOpen, closeModal }) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto bg-[rgba(0,0,0,0.5)]" onClose={closeModal}>
        <div className="min-h-screen px-10 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="bg-overlay-zinc-900 fixed inset-0" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div className="relative my-8 inline-block w-full max-w-md transform overflow-hidden rounded bg-white p-10 text-left text-center align-middle shadow-xl transition-all">
              <button className="absolute top-4 right-4" onClick={closeModal}>
                <XCircle className="h-6 w-6 text-primary" />
              </button>
              <Lottie options={defaultOptions} height={60} width={60} />

              <p className="font-title-l mt-4 text-primary">Захиалга амжилттай бүртгэгдлээ </p>

              <p className="font-title-m mt-4 text-primary">Таны захиалга амжилттай баталгаажлаа. Бид тантай эргээд холбогдох болно.</p>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
