import React from 'react';

import { TreeMenu } from '@/components/Elements';
import { categoryTypes } from '@/constants/categoryTypes';
import { useCategories } from '@/features/category';

export const CompanyFilter = ({ toggleCategory = () => null }) => {
  const { data, isLoading } = useCategories(categoryTypes.company);

  return (
    <>
      {/*<p className="mb-5 border-b border-zinc-300 pb-4 text-xl">Төрөл</p>*/}
      <TreeMenu toggleCategory={toggleCategory} items={isLoading ? [] : data?.children} />
    </>
  );
};
