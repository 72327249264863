import { useQuery, useQueryClient } from 'react-query';

import { categoryTypes } from '@/constants/categoryTypes';
import { axios } from '@/libs/axios';

import { categoryKeys } from './categoryKeys';

const fetchCategoryTree = async ({ type }) => {
  try {
    return await axios.get(`v1/category/tree?${new URLSearchParams({ type }).toString()}`);
  } catch (e) {
    return [];
  }
};

export const useCategories = (type, opt) => {
  return useQuery(categoryKeys.tree(type), () => fetchCategoryTree({ type }), opt);
};

export const usePrefetchCategories = () => {
  const queryClient = useQueryClient();
  queryClient.prefetchQuery(categoryTypes.company, () => fetchCategoryTree({ type: categoryTypes.company }));
  queryClient.prefetchQuery(categoryTypes.commerce, () => fetchCategoryTree({ type: categoryTypes.commerce }));
  queryClient.prefetchQuery(categoryTypes.person, () => fetchCategoryTree({ type: categoryTypes.person }));
};
