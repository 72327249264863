import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { useAuth } from '@/libs/auth';
import { axios } from '@/libs/axios';

const updatePassword = (data) => axios.post('/customer/password/change', data);

export const useUpdatePassword = () => {
  const { refetchUser } = useAuth();

  return useMutation(updatePassword, {
    onSuccess: () => {
      refetchUser();
      toast.success('Та нууц үгээ амжилттай шинэчиллээ.');
    }
  });
};
