import { useNavigate } from 'react-router-dom';

export const ExecutorLabel = ({ item }) => {
  const { firstname, categories, executorId } = item || {};
  const navigate = useNavigate();
  const goToObject = () => {
    if (categories[0]?.type === 'person') {
      navigate(`/people/${executorId}`);
    }
  };

  return (
    <div className={'mr-4 flex'}>
      <p className="text-sm text-zinc-700">{categories?.map((cat) => cat?.name + ' ')}:</p>

      <a href={''} onClick={() => goToObject()}>
        <p className={'ml-1 text-sm text-zinc-800 hover:text-blue-500 hover:underline hover:underline-offset-1'}> {firstname}</p>
      </a>
    </div>
  );
};
