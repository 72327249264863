import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useLoginAndLogoutRedirect } from '@/hooks/useLoginAndLogoutRedirect';

export const ProfileDropdown = ({ user, logout }) => {
  const navigate = useNavigate();
  const { logoutRedirect } = useLoginAndLogoutRedirect();

  const handleGoToProUser = () => {
    navigate('/pricing');
  };

  const handleLogout = () => {
    logoutRedirect();
    logout();
  };

  return (
    <div className="z-20">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="center flex h-6 w-6 rounded-2xl bg-zinc-600 text-white md:h-8 md:w-8">
            <span className="font-body-l" aria-hidden="true">
              {user?.firstName?.[0]?.toUpperCase()}
            </span>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-0 mt-2 w-64 origin-top-right divide-y divide-gray-100 rounded bg-white p-3 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <Link to="/account/profile" className={`${active ? 'bg-zinc-500 text-white' : 'text-gray-900'} group flex w-full items-center rounded px-2 py-2 text-sm`}>
                    Хувийн мэдээлэл
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link to="/pack" className={`${active ? 'bg-zinc-500 text-white' : 'text-gray-900'} group flex w-full items-center rounded px-2 py-2 text-sm`}>
                    Миний багцууд
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link to="/orders" className={`${active ? 'bg-zinc-500 text-white' : 'text-gray-900'} group flex w-full items-center rounded px-2 py-2 text-sm`}>
                    Миний захиалгууд
                  </Link>
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button onClick={handleGoToProUser} className={`${active ? 'bg-zinc-500 text-white' : 'text-gray-900'} group flex w-full items-center rounded px-2 py-2 text-sm`}>
                    Pro хэрэглэгч болох
                  </button>
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button onClick={handleLogout} className={`${active ? 'bg-zinc-500 text-white' : 'text-gray-900'} group flex w-full items-center rounded px-2 py-2 text-sm`}>
                    Гарах
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
