import clsx from 'clsx';

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const SizeItem = ({ size, selected, onClick }) => {
  return (
    <button className={classNames('py-1 ', selected ? 'bg-black ' : 'border border-gray-300')} onClick={onClick}>
      <p className={classNames(' text-base', selected ? 'text-white' : 'text-black')}>{size}</p>
    </button>
  );
};

export const ColorItem = ({ color, selected, onClick, hasItem }) => {
  return (
    <button
      style={{ backgroundColor: color }}
      className={clsx('center h-8 py-1', selected ? 'border border-black  ' : 'border  ', !hasItem && 'cursor-not-allowed opacity-70')}
      onClick={(event) => (hasItem ? onClick() : event.preventDefault())}
    />
  );
};
