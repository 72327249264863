import { WorkDetail } from './WorkDetail';
import { Works } from './Works';

export const worksRoutes = [
  {
    children: [
      { path: '', element: <Works /> },
      { path: ':workId', element: <WorkDetail /> }
    ]
  }
];
