import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useLoginAndLogoutRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const loginRedirect = useCallback(() => {
    if (location.pathname.startsWith('/auth')) navigate('/');
  }, [location.pathname, navigate]);

  const logoutRedirect = useCallback(() => {
    if (location.pathname.startsWith('/account') || location.pathname.startsWith('/pack') || location.pathname.startsWith('/orders')) navigate('/');
  }, [location.pathname, navigate]);

  return { loginRedirect, logoutRedirect };
};
