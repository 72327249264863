import { Link } from 'react-router-dom';

import { periodFormat } from '@/utils/dateFormats';

export const BigNews = ({ item }) => {
  const { avatarOriginalName, avatarPath, headline, authorType, datePublished, articleId } = item || {};
  return (
    <div className={'border-b border-solid border-slate-200'}>
      <Link to={`/news/${articleId}`}>
        <div className="grid grid-cols-1 place-items-center gap-7 md:grid-cols-2">
          <div className="news-image">
            <img src={avatarOriginalName || avatarPath} className="object-cover" alt="bigNews" />
          </div>
          <div className="">
            <p className="font-body-m mb-4 text-secondary-medium">{authorType}</p>
            <p className="font-headline-l mb-14 text-secondary-high">{headline}</p>
            <p className="font-body-m text-secondary-medium">{periodFormat(datePublished)}</p>
          </div>
        </div>
      </Link>
      <div className="pb-20" />
    </div>
  );
};
