import clsx from 'clsx';
import React from 'react';
import { Star } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from '@/components/Elements';
import { TugCurrency } from '@/libs/react-number-format';
import { menuActions } from '@/store/menuSlice';

export const OrderProductItem = ({ item, orderId }) => {
  const { product, price, quantity, size, rating } = item || {};
  const { avatarPath, active, name, productId } = product || {};
  const dispatch = useDispatch();
  const toggleReview = React.useCallback(() => dispatch(menuActions.toggleReview({ reviewItem: { orderId, ...item } })), [dispatch, orderId, item]);

  return (
    <div className="relative py-4">
      <Link to={`/shop/${productId}`} onClick={(event) => !active && event.preventDefault()}>
        <div className={clsx('grid grid-cols-4 gap-4   ', !active && 'cursor-not-allowed opacity-70')}>
          <div className="image-container relative  ">
            <img src={avatarPath} alt={name} />
          </div>
          <div className=" col-span-3 ">
            <div className="w-full flex-1">
              <p className="font-label-s md:font-label-m text-secondary-medium md:mb-0.5">{active ? 'Идэвхитэй' : 'Идэвхгүй'}</p>
              <p className="font-title-s md:font-title-m mb-1 text-primary md:mb-4">{name}</p>
              <p className={'font-body-s md:font-body-m '}>Размер: {size?.toUpperCase()}</p>
              <div className={'flex-col '}>
                <div className=" flex flex-row items-center  text-sm	">
                  <p className={'font-body-s md:font-body-m '}>Тоо: </p>
                  <p className="font-body-s md:font-body-m">{quantity}ш</p>
                </div>
                {rating && (
                  <div className="flex items-center">
                    <p className="font-body-m md:font-body-m mr-1 text-primary">Үнэлгээ:</p>
                    <div className="flex">
                      {Array.from(Array(5).keys()).map((i) => (
                        <Star key={`rating.${rating.ratingId}.${i}`} color={i < rating.rating ? '#FFC043' : '#B7B7B7'} fill={i < rating.rating ? '#FFC043' : '#B7B7B7'} size={20} />
                      ))}
                    </div>
                  </div>
                )}
                <div className="flex ">
                  <div className="flex">
                    <p className="font-body-m md:font-body-l text-primary ">Үнэ:</p>
                    <TugCurrency value={price} className="font-body-m md:font-body-l text-primary " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      {!rating && (
        <div className="absolute right-4 bottom-4">
          <Button text="Үнэлгээ өгөх" variant="outlined" onClick={toggleReview} />
        </div>
      )}
    </div>
  );
};
