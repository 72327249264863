import { useQuery } from 'react-query';

import { axios, decodeUrl } from '@/libs/axios';

import { productsKeys } from './productsKeys';

const fetchProducts = (searchParams) => {
  return axios.get(`shop/product?${decodeUrl(searchParams.toString())}`);
};
export const useProducts = (searchParam, opt) => {
  // const [name] = useDebounce(filter?.name, 300);
  return useQuery(productsKeys.list(searchParam.toString()), () => fetchProducts(searchParam), opt);
};
