import { useMutation } from 'react-query';

import { axios } from '@/libs/axios';

export const passwordReset = (token, password) => {
  return axios.post('password/reset', {
    token,
    reset: { plainPassword: password }
  });
};

export const useMutatePasswordReset = () => {
  return useMutation(({ token, password }) => passwordReset(token, password));
};
