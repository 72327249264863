import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button } from '@/components/Elements';
import { Input } from '@/components/Form';
import { useAuth } from '@/libs/auth';
import { getError } from '@/libs/react-hook-form';
import { yup } from '@/libs/yup';

import { useUpdateUser } from '../api';

import { Password } from './Password';

const schema = yup.object({
  lastName: yup.string().required('Овгоо  оруулна уу.'),
  firstName: yup.string().required('Нэрээ оруулна уу.'),
  phone: yup.string().length(8, 'Утасны дугаараа зөв уу.').required('Утасны дугаараа оруулна уу.'),
  company: yup.object({
    isCompany: yup.boolean(),
    name: yup.string().when('isCompany', {
      is: true,
      then: yup.string().required('Байгууллагын нэрээ оруулна уу.')
    }),
    companyType: yup.string().when('isCompany', {
      is: true,
      then: yup.string().required('Албан тушаалаа оруулна уу.')
    }),
    nip: yup.string().when('isCompany', {
      is: true,
      then: yup.string().required('Байгууллагын регистрийн дугаараа оруулна уу.')
    })
  })
});

export const Profile = () => {
  const { isLoading, mutate } = useUpdateUser();
  const { user } = useAuth();
  const isCompany = user?.company;

  const {
    register,
    reset,
    formState: { errors, isSubmitted },
    control,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      lastName: '',
      firstName: '',
      phone: '',
      company: {
        isCompany: false,
        name: '',
        companyType: '',
        nip: ''
      }
    }
  });

  React.useEffect(() => {
    if (user && !isSubmitted) {
      reset({
        phone: user.phone,
        lastName: user.lastName,
        firstName: user.firstName,
        company: {
          isCompany: !!isCompany,
          name: user?.company?.name || '',
          companyType: user?.company?.companyType || '',
          nip: user?.company?.nip || ''
        }
      });
    }
  }, [isCompany, isSubmitted, reset, user]);

  const onSubmit = (values) => {
    delete values.company.isCompany;
    mutate(values);
    reset(values);
  };

  return (
    <div className="layout-space divide-y divide-border">
      <div className="space-y-6 py-5">
        <span className="row">
          <p className="font-title-m mr-2 font-medium">И-мэйл: </p>
          <p className="font-title-m">{user.email}</p>
        </span>

        <div className="grid gap-6 md:grid-cols-2">
          <Controller name="lastName" control={control} render={({ field }) => <Input error={getError(errors, 'lastName')} label="Овог" field={field} placeholder="Овог" />} />
          <Controller name="firstName" control={control} render={({ field }) => <Input error={getError(errors, 'firstName')} label="Нэр" field={field} placeholder="Нэр" />} />
        </div>
        <Controller
          name="phone"
          control={control}
          render={({ field }) => <Input error={getError(errors, 'phone')} label="Утасны дугаар" field={field} placeholder="Утасны дугаар" />}
        />

        <input type="checkbox" hidden {...register('company.isCompany')} />
        {isCompany && (
          <>
            <h3 className="font-title-m font-medium">Багуулгийн мэдээлэл</h3>
            <div className="grid gap-6 md:grid-cols-2">
              <Controller
                name="company.name"
                control={control}
                render={({ field }) => <Input error={getError(errors, 'company.name')} field={field} label="Байгууллагын нэр" placeholder="Байгууллагын нэр" />}
              />
              <Controller
                name="company.companyType"
                control={control}
                render={({ field }) => <Input error={getError(errors, 'company.companyType')} field={field} label="Албан тушаал" placeholder="Албан тушаал" />}
              />
              <Controller
                name="company.nip"
                control={control}
                render={({ field }) => (
                  <Input error={getError(errors, 'company.nip')} field={field} label="Байгууллагын регистрийн дугаар" placeholder="Байгууллагын регистрийн дугаар" />
                )}
              />
            </div>
          </>
        )}

        <div className="flex justify-end">
          <Button onClick={handleSubmit(onSubmit)} isLoading={isLoading} variant="filled" className="mt-10 w-full" text="Хадаглах" />
        </div>
      </div>

      <Password />
    </div>
  );
};
