import { categoryTypes } from '@/constants/categoryTypes';

export const getLinkToDetail = (item) => {
  if (!item) return '/';
  else if (item.executorId) return `/people/${item.executorId}`;
  else if (item.companyId) return `/company/${item.companyId}`;
  else if (item.productId) return `/shop/${item.productId}`;
  return '/';
};

export const getLinkFromPackToDetail = (item) => {
  if (!item) return '/';
  switch (item.type) {
    case categoryTypes.person:
      return `/people/${item.id}`;
    case categoryTypes.company:
      return `/company/${item.id}`;
  }
  return '/';
};

export const getLinkToList = (category) => {
  if (!category) return '/';
  switch (category.type) {
    case categoryTypes.person:
      return `/people?category=${category.categoryId}`;
    case categoryTypes.company:
      return `/company?category=${category.categoryId}`;
    case categoryTypes.commerce:
      return `/shop?category=${category.categoryId}`;
  }
  return '/';
};
