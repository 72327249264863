import { useEffect, useState } from 'react';
import { ArrowUp } from 'react-feather';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, search]);
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    showTopBtn && (
      <div className="fixed bottom-20 z-[1000] h-0 w-screen">
        <div className="layout layout-space relative max-h-0 py-0">
          <button
            onClick={goToTop}
            className="center absolute right-2 h-12 w-12 rounded-full border border-secondary bg-primary text-secondary transition-all hover:bg-support 2xl:right-[-6rem]">
            <ArrowUp size={30} />
          </button>
        </div>
      </div>
    )
  );
}
