import { MinusIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import React from 'react';
import { Check } from 'react-feather';

export const Checkbox = ({ error, disabled = false, field, text }) => {
  return (
    <>
      <label className="row font-body-m cursor-pointer items-center text-primary">
        <input className="hidden" disabled={disabled} type="checkbox" {...field} />
        <span className={clsx('center mr-5 h-4 w-4 rounded border border-primary text-white', field.value ? 'bg-primary' : 'bg-white')}>{field.value && <Check />}</span>
        <span className="select-none">{text}</span>
      </label>
      {error && (
        <p className="mt-2 flex items-center text-xs text-red">
          <MinusIcon className="mr-1 inline h-3 w-3" /> {error}
        </p>
      )}
    </>
  );
};
