import React from 'react';

import { TreeMenu } from '@/components/Elements';
import { FilterMenu } from '@/components/Elements/FilterMenu';
import { categoryTypes } from '@/constants/categoryTypes';
import { useCategories } from '@/features/category';
import { useFilter } from '@/features/filter';

export const PeopleFilter = ({ toggleCategory = () => null }) => {
  const { data, isLoading } = useCategories(categoryTypes.person);
  const { data: filterData, isLoading: isFilterLoading } = useFilter();

  return (
    <>
      <TreeMenu toggleCategory={toggleCategory} items={isLoading ? [] : data?.children ?? []} />
      <div className="mt-4  pt-4" />
      <FilterMenu toggleCategory={toggleCategory} type="rating" item={isFilterLoading ? { name: 'Үнэлгээ' } : { name: 'Үнэлгээ', children: filterData?.ratingMulti || [] }} />
      <div className="mt-4  pt-4" />
      <FilterMenu toggleCategory={toggleCategory} type="age" item={isFilterLoading ? { name: 'Нас' } : { name: 'Нас', children: filterData.age }} />
      <div className="mt-4  pt-4" />
      <FilterMenu toggleCategory={toggleCategory} type="gender" item={isFilterLoading ? { name: 'Хүйс' } : { name: 'Хүйс', children: filterData.gender }} />
      {/*<p className="mt-5 border-t border-zinc-300 pt-4">Үнийн хязгаар</p>*/}
    </>
  );
};
