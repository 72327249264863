import { useMutation } from 'react-query';

import { axios } from '@/libs/axios';

export const passwordResetRequest = (username) => {
  return axios.post('password/reset/request', { username });
};

export const useMutatePasswordResetRequest = () => {
  return useMutation((username) => passwordResetRequest(username));
};
