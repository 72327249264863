import React from 'react';
import { Link } from 'react-router-dom';

import { periodFormat } from '@/utils/dateFormats';

export const NewsItem = ({ item, isLoading = false }) => {
  const { avatarOriginalName, headline, avatarPath, authorType, datePublished, articleId } = item || {};
  if (isLoading) {
    return (
      <div className=" animate-pulse rounded">
        <div className="py-4 md:py-0">
          <div className="image-container-company mb-6 bg-slate-300" />
          <div className={`h-${5} mb-2 w-1/3 rounded bg-slate-200`} />
          <div className={`h-${16} mb-10 w-full rounded bg-slate-200`} />
          <div className={`h-${5} mb-2 w-1/3 rounded bg-slate-200`} />
        </div>
      </div>
    );
  }
  return (
    <Link to={`/news/${articleId}`}>
      <div className="py-4 md:py-0">
        <div className="image-container-company mb-6">
          <img className="w-full" src={avatarOriginalName || avatarPath} alt="" />
        </div>
        <p className="font-body-m mb-2 text-secondary-medium">{authorType}</p>
        <p className="font-title-l mb-10 text-secondary-high">{headline}</p>
        <p className="font-body-m text-secondary-medium">{periodFormat(datePublished)}</p>
      </div>
    </Link>
  );
};
