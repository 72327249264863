import { Disclosure } from '@headlessui/react';
import clsx from 'clsx';
import { ChevronDown, ChevronUp } from 'react-feather';

const DisclosureItem = ({ className, disclosure }) => {
  const { title } = disclosure;

  return (
    <div className={clsx('border-b border-light-layout', className)}>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className={clsx('text-title-l flex w-full justify-between py-3 pr-2 text-left text-secondary-high hover:bg-gray-50 focus:outline-none')}>
              <p className="flex-grow">{title}</p>
              {open ? <ChevronUp className="h-6 w-6 min-w-[1rem]" /> : <ChevronDown className="h-6 w-6 min-w-[1rem]" />}
            </Disclosure.Button>
            <Disclosure.Panel className="font-body-l space-y-4 pl-10 pt-4 pb-10">
              <p>
                To upload you need to be registered, and your account will need to be approved for uploading (you can request upload privileges in our short on-boarding application
                after registering).
              </p>
              <p>1. Click the upload icon on your account dashboard (the button will only appear if you have upload privileges)</p>
              <p>2. Start typing the name of the magazine or website in which the editorial was published. Choose the correct client.</p>
              <p>
                3. Enter the basic information including the publish date, the title of the editorial (if there is no title, choose a logical name like “Model Name by
                Photographer’s Name”), and a link to another site where the images can be found (optional).
              </p>
              <p>
                4. Next, you will be prompted to add crew credits for the shoot. To avoid duplicates being uploaded by other artists or agents, please credit everyone you can,
                especially the photographer. If the photographer is not in our database, please add their name to the TITLE of the work (ie. “Title by Photographer Name”)
              </p>
              <p>5. Click ADD IMAGES and select one or more files from your computer. Images must be under 5MB. Click UPLOAD ALL IMAGES.</p>
              <p>
                6. Once uploaded, you can tag specific artists and models in each different picture if needed. You can also click and drag these images to rearrange them to match
                their original order of publication.
              </p>
              <p>7. Click Submit to Models.com or Publish</p>
              <p>
                Please note: Magazine covers are uploaded separately from editorials. If the editorial is a cover story, please add the actual cover separately and categorize it as
                such.
              </p>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export const FaQ = () => {
  return (
    <div className="mx-6 max-w-screen-lg py-8 md:mx-10 lg:mx-auto">
      <p className="font-display-s pb-9">FAQ</p>

      <DisclosureItem className="border-t border-black" disclosure={{ title: 'How do I get my agency listed on Models.mn?' }} />
      <DisclosureItem disclosure={{ title: 'What are the benefits of having an Agency Listing on Models.mn?' }} />
      <DisclosureItem disclosure={{ title: 'How do I add my agency’s credit to a model’s page' }} />
      <DisclosureItem disclosure={{ title: 'How do I upload an editorial?' }} />
    </div>
  );
};
