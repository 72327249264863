import React from 'react';
import { useSelector } from 'react-redux';

import { packSelector } from '@/store/packSlice';

import { PackModalItem } from './PackModalItem';

const listStyle = 'divide-y divide-dashed divide-light-disabled space-y-4 mt-2';

export const PackModalList = ({ togglePack, hasEdit = false }) => {
  const { agency, person } = useSelector(packSelector.getItems);

  const hasPerson = person.length > 0;
  const hasCompany = agency.length > 0;

  return (
    <div className="space-y-10">
      <div className="bg-background p-4">
        <p className="font-medium text-primary">Хувь хүн</p>
        {hasPerson && (
          <div className={listStyle}>
            {person.map((person) => (
              <PackModalItem key={`pack.modal.list.${person.id}.hasEdit=${hasEdit}`} item={person} hasEdit={hasEdit} togglePack={togglePack} />
            ))}
          </div>
        )}
      </div>

      <div className="bg-background p-4">
        <p className="font-medium text-primary">Байгууллага</p>
        {hasCompany && (
          <div className={listStyle}>
            {agency.map((company) => (
              <PackModalItem key={`pack.modal.list.${company.id}.hasEdit=${hasEdit}`} item={company} hasEdit={hasEdit} togglePack={togglePack} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
