import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSearchOpen: false,
  isCartOpen: false,
  isCategoryOpen: false,
  isLoginOpen: false,
  isMenuOpen: false,
  isPackOpen: false,
  isPackOrderOpen: false,
  isOrderOpen: false,
  isReviewOpen: false,
  reviewItem: null
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggleSearch: (state) => {
      state.isSearchOpen = !state.isSearchOpen;
    },
    toggleOrder: (state) => {
      state.isOrderOpen = !state.isOrderOpen;
    },
    toggleCart: (state) => {
      state.isCartOpen = !state.isCartOpen;
    },
    toggleCategory: (state) => {
      state.isCategoryOpen = !state.isCategoryOpen;
    },
    toggleLogin: (state) => {
      state.isPackOrderOpen = false;
      state.isLoginOpen = !state.isLoginOpen;
    },
    togglePackOrder: (state) => {
      state.isPackOrderOpen = !state.isPackOrderOpen;
    },
    togglePack: (state) => {
      state.isPackOpen = !state.isPackOpen;
    },
    toggleMenu: (state) => {
      state.isMenuOpen = !state.isMenuOpen;
    },
    toggleReview: (state, action) => {
      const { reviewItem } = action.payload || {};

      if (!state.isReviewOpen) state.reviewItem = reviewItem;
      else state.reviewItem = null;

      state.isReviewOpen = !state.isReviewOpen;
    }
  }
});

export const menuActions = menuSlice.actions;

export const menuSliceReducer = menuSlice.reducer;
