import { nanoid } from '@reduxjs/toolkit';
import clsx from 'clsx';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { RouteHomePath } from '@/components/Elements/RoutePath';
import { Head } from '@/components/Head';
import { useOrderDetail } from '@/features/order';
import { TugCurrency } from '@/libs/react-number-format';

import { OrderProductItem } from '../components/OrderProductItem';
import { checkStatus } from '../utils/checkStatus';

const OrderProductItemSkeleton = () => {
  return (
    <div className="animate-pulse py-4">
      <div className="grid grid-cols-4 gap-4 md:grid-cols-6  ">
        <div className="image-container bg-secondary-disabled " />
        <div className=" col-span-3 md:col-span-5 ">
          <div className="w-full flex-1">
            <div className="my-0.5 h-3 w-24 rounded bg-secondary-disabled" />
            <div className="mb-4 mt-0.5 h-4 w-1/2 rounded bg-secondary-disabled" />
            <div className="h-[0.875rem] w-1/4 rounded bg-secondary-disabled" />
            <div className={'flex-col '}>
              <div className=" flex flex-row items-center  text-sm	">
                <div className="my-1 h-[0.875rem] w-14 rounded bg-secondary-disabled " />
              </div>
              <div className="flex">
                <div className="h-4 w-16 rounded bg-secondary-disabled" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Detail = () => {
  return (
    <div className={'bg-primary px-6 py-7 '}>
      <div className="row  mb-6  justify-between">
        <p className="font-label-m mr-4 text-primary-medium">Захиалгын дугаар</p>
        <div className="h-[0.875rem] w-1/5 rounded bg-secondary-disabled" />
      </div>
      <div className="row mb-6 justify-between">
        <p className="font-label-m text-primary-medium">Нийт бараа</p>
        <div className="h-[0.875rem] w-1/6 rounded bg-secondary-disabled" />
      </div>
      <div className="row  mb-6  justify-between">
        <p className="font-label-m text-primary-medium">Төлбөрийн мэдээлэл</p>
        <div className="h-[0.875rem] w-1/3 rounded bg-secondary-disabled" />
      </div>
      <div className="row  mb-6  justify-between">
        <p className="font-label-m text-primary-medium">Төлөв</p>
        <div className="h-[0.875rem] w-1/4 rounded bg-secondary-disabled" />
      </div>
      <hr className="my-4" />
      <div className="row  justify-between text-xl">
        <p className="font-title-m text-primary-medium">Нийт үнэ</p>
        <div className="h-[0.875rem] w-1/5 rounded bg-secondary-disabled" />
      </div>
    </div>
  );
};
export const OrderDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { orderId } = params;

  const { data, isLoading } = useOrderDetail(orderId);
  const { productOptions, totalAmount, paid, status, ratings } = data || {};
  const { paidText, statusText } = checkStatus(paid, status);

  React.useEffect(() => {
    if (!isLoading && !data) navigate('/404');
  }, [data, isLoading, navigate]);
  return (
    <>
      <Head title="Миний захиалга" description="Миний захиалга" />
      <div className="bg-primary">
        <div className="layout layout-space relative py-10">
          <RouteHomePath name="Миний захиалга" paths={[{ key: nanoid(), to: '/orders', name: 'Захиалгууд' }]} />
        </div>
      </div>
      <div className="layout md:layout-space grid grid-cols-1 pt-10 md:py-10 md:py-20">
        <p className="font-title-l mb-4 text-primary">Таны захиалсан бараа</p>
        <div className="grid gap-7 md:grid-cols-5">
          <div className="md:col-span-3 ">
            <div className={'bg-white px-4'}>
              {!isLoading &&
                productOptions?.length > 0 &&
                productOptions?.map((itemOption) => (
                  <OrderProductItem
                    orderId={orderId}
                    key={`item-option-${itemOption?.productOptionId}`}
                    item={{ ...itemOption, rating: ratings.find((r) => r.productOptionId === itemOption.productOptionId) }}
                  />
                ))}
              {isLoading && [1, 2].map((s) => <OrderProductItemSkeleton key={`skeleton-option-${s}`} />)}
            </div>
          </div>
          <div className="md:col-span-2">
            {isLoading ? (
              <Detail />
            ) : (
              <div className={'bg-primary px-6 py-7 '}>
                <div className="row  mb-6  justify-between">
                  <p className="font-label-m mr-4 text-primary-medium">Захиалгын дугаар</p>
                  <p className="font-title-s text-right text-white">{orderId}</p>
                </div>
                <div className="row mb-6 justify-between">
                  <p className="font-label-m text-primary-medium">Нийт бараа</p>
                  <p className="font-title-m text-white "> {productOptions?.length || 0} ш</p>
                </div>
                <div className="row  mb-6  justify-between">
                  <p className="font-label-m text-primary-medium">Төлбөрийн мэдээлэл</p>
                  <p className={clsx('font-title-s text-right', paid ? 'text-light-high' : 'text-yellow')}>{paidText}</p>
                </div>
                <div className="row  mb-6  justify-between">
                  <p className="font-label-m text-primary-medium">Төлөв</p>
                  <p className="font-title-s  text-right text-white">{statusText}</p>
                </div>
                <hr className="my-4" />
                <div className="row  justify-between text-xl">
                  <p className="font-title-m text-primary-medium">Нийт үнэ</p>
                  <TugCurrency value={totalAmount} className="font-title-m text-white" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
