import { Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { XCircle } from 'react-feather';
import { useSelector } from 'react-redux';

import { CartItem } from '@/components/CartItem';
import { Button } from '@/components/Elements';
import { TugCurrency } from '@/libs/react-number-format';
import { getTotalCartItemsPrice, getTotalDiscountCartItemsPrice } from '@/utils/products';

import { useMutateOrderItems } from '../../../../../features/cart/api/useMutateOrderItems';

const CartModalDetail = ({ toggleCart, cartItems, callback = () => null, canChange = false }) => {
  const { isLoading, mutateAsync } = useMutateOrderItems();

  const totalPrice = getTotalCartItemsPrice(cartItems);
  const totalDiscountPrice = getTotalDiscountCartItemsPrice(cartItems);
  const handleOrder = async () => {
    await mutateAsync({ totalPrice, items: cartItems });
    if (typeof callback !== 'function') {
      return;
    }
    toggleCart();
    callback();
  };

  return (
    <>
      <div className="h-96 overflow-y-scroll pl-4 ">
        <ul className="divide-y divide-dashed">
          {cartItems.map((item) => (
            <CartItem item={item} key={item.id} canChange={canChange} isModal={true} />
          ))}
        </ul>
      </div>
      <div className="w-full bg-primary ">
        <div className={'w-full p-6'}>
          <div className="row mb-6 justify-between">
            <p className="font-label-m text-primary-medium">Нийт бараа</p>
            <TugCurrency value={totalPrice} className="font-title-m text-white" />
          </div>

          <div className="row  mb-6  justify-between">
            <p className="font-label-m text-primary-medium">Хямдрал</p>
            <TugCurrency value={totalDiscountPrice} className="font-title-m text-white" />
          </div>

          <div className="row mb-6 justify-between text-xl">
            <p className="font-label-m text-primary-medium">Нийт үнэ</p>
            <TugCurrency value={getTotalCartItemsPrice(cartItems)} className="font-title-m text-white" />
          </div>

          <div className=" flex w-full justify-center  ">
            <Button isLoading={isLoading} onClick={handleOrder} variant="outlined" text="Баталгаажуулах" className="flex-1 bg-white" color={'white'} />
          </div>
        </div>
      </div>
    </>
  );
};
export const OrderProductModal = ({ isCartOpen, items, toggleCart, callback = () => null }) => {
  const hasCartItems = Boolean(items.length);

  const title = hasCartItems ? `Захиалга баталгаажуулах` : 'Таны захиалга  хоосон байна.';
  const handleParentOnClick = (e) => {
    if (e.target !== e.currentTarget) return;
    toggleCart();
  };
  return (
    <Transition
      show={isCartOpen}
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 -translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-1">
      <div aria-hidden="true" onClick={handleParentOnClick} className="fixed inset-0 z-30 h-screen w-screen overflow-hidden bg-[rgba(0,0,0,0.5)]">
        <div aria-hidden="true">
          <div className="absolute top-0 right-0 w-full bg-white pr-4 md:w-[30rem]">
            <div className="flex items-center justify-between p-4">
              <p className="font-title-m text-primary">{title}</p>

              <button
                className=""
                onClick={(e) => {
                  toggleCart();
                  e.stopPropagation();
                }}>
                <XCircle className="h-5 w-5 text-primary" />
              </button>
            </div>

            {hasCartItems && <CartModalDetail toggleCart={toggleCart} cartItems={items} callback={callback} />}
            {!hasCartItems && (
              <div className="flex w-full justify-center">
                <img className="h-2/3 w-2/3" src={require('@/assets/emptyCart.png')} alt="Карт хоосон байна." />
              </div>
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
};
