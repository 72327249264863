const storagePrefix = 'model.mn_react_';

const storage = {
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`));
  },
  setToken: (token) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  setCartItems: (products) => {
    window.localStorage.setItem(`${storagePrefix}cart`, JSON.stringify(products));
  },
  getCartItems: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}cart`));
  },
  setPackItems: (pack) => {
    window.localStorage.setItem(`${storagePrefix}pack`, JSON.stringify(pack));
  },
  getPackItems: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}pack`));
  }
};

export default storage;
