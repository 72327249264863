import React from 'react';

import { CompanySwiper } from '@/components/Company';
import { PeopleSwiper } from '@/components/People';
import { classNames } from '@/utils/classNames';
const loadingData = [1, 2, 3];
export const HomeSkeleton = () => {
  return (
    <>
      {loadingData.map((l) => (
        <div className={classNames(l % 2 === 0 && 'bg-light-layout')} key={l}>
          <div className="layout  layout-space py-20">
            <div className="mb-8 animate-pulse">
              <div className="mb-[19px] mt-[3px] h-[22px]   w-1/2 rounded bg-secondary-disabled md:w-1/5" />
              <div className=" w-fill h-4 rounded bg-secondary-disabled md:w-2/6" />
            </div>
            {l % 2 === 0 ? <CompanySwiper isLoading={true} /> : <PeopleSwiper isLoading={true} />}
          </div>
        </div>
      ))}
    </>
  );
};
