import { useQuery } from 'react-query';

import { filterKeys } from '@/features/filter';
import { axios, decodeUrl } from '@/libs/axios';

const fetchFilter = (filter) => {
  const searchParams = new URLSearchParams(filter);
  return axios.get(`/v1/executor/filter?${decodeUrl(searchParams.toString())}`);
};

export const useFilter = (filter, opt) => {
  return useQuery(filterKeys.person(filter), () => fetchFilter(filter), opt);
};
