import clsx from 'clsx';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Head } from '@/components/Head';
import { ProductSwiper } from '@/components/Product/ProductSwiper';
import { useProducts } from '@/features/shop';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';
import { getPhoto } from '@/utils/image';

import { useProductDetail } from '../api/useProductDetail';
import { ProductController } from '../components/ProductController';
import { ProductDisclosure } from '../components/ProductDisclosure';
import { ShowProductImages } from '../components/ShowProductImages';

const ProductDetailSkeleton = () => {
  const { isSm } = useWindowDimensions();

  return (
    <div className="space-y-20 md:pt-[75px] lg:pt-24">
      <div className="md:layout md:layout-space grid gap-8 md:grid-cols-5 md:gap-7 ">
        <div className="md:col-span-3">
          <div className="grid gap-7 md:grid-cols-5 lg:grid-cols-8">
            <div className=" hidden animate-pulse flex-col space-y-7 md:flex ">
              {[1, 2, 3].map((i) => (
                <div className="image-container bg-secondary-disabled" key={`product-detail-skeleton-${i}`} />
              ))}
            </div>
            <div className={clsx(isSm && 'mobile-product-image', ' animate-pulse md:col-span-4 lg:col-span-7')}>
              <div className="image-container bg-secondary-disabled" />
            </div>
          </div>
        </div>
        <div className={clsx(isSm && 'layout layout-space', 'col-span-2 animate-pulse')}>
          <div className="my-0.5 h-3 w-12 rounded bg-secondary-disabled" />
          <div className="my-3.5 h-5  w-full rounded bg-secondary-disabled" />
          <div className="my-3.5 h-5 w-1/3  rounded bg-secondary-disabled" />
          <div className="mb-6" />
          <div className="rounder my-1 h-4 w-12 rounded bg-secondary-disabled" />
          <div className="rounder my-1 mb-2 h-8 w-24 rounded bg-secondary-disabled" />
          <div className="mb-6 flex	 items-center justify-between">
            <div className="my-1 h-4  w-1/4 rounded bg-secondary-disabled" />
          </div>
          <div className="mb-12 flex	items-center">
            {[1, 2, 3].map((i) => (
              <div className="mr-6 h-6 w-1/5 bg-secondary-disabled" key={`skeleton-button-${i}`} />
            ))}
          </div>
          <div>
            <div className="mb-8 h-4 w-16 rounded bg-secondary-disabled" />
            <div className="flex items-center justify-between	">
              <div className="h-10 w-1/3 bg-secondary-disabled md:h-12" />
              <div className="h-10 w-1/3 bg-secondary-disabled md:h-12" />
            </div>
          </div>
          <div className="flex animate-none flex-col space-y-6">
            <hr />
            <ProductDisclosure text={'Материал'} />
            <hr />
            <ProductDisclosure text={'Тайлбар'} />
            {/*<hr />*/}
            {/*<ProductDisclosure text={'Сэтгэгдэл'} />*/}
          </div>
        </div>
      </div>
      <div className=" bg-light-layout py-20">
        <div className="layout layout-space">
          <p className="font-headline-s mb-9 text-primary">Төстэй бараанууд</p>
          <ProductSwiper isLoading={true} />
        </div>
      </div>
    </div>
  );
};

export const ProductDetail = () => {
  let params = useParams();
  const { isSm } = useWindowDimensions();

  const { id } = params;
  const { data, isLoading } = useProductDetail(id, { enabled: !!id });
  const { data: productsData, isLoading: isProductLoading } = useProducts({ isRandom: true, perPage: 4 }, { enabled: !!id });
  const { products = [] } = productsData || {};
  const { avatarOriginalName, photos } = data || {};
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!isLoading && !data) navigate('/404');
  }, [data, isLoading, navigate]);
  if (isLoading) {
    return <ProductDetailSkeleton />;
  }
  return (
    <>
      <Head title={data?.name} description={data?.description} image={data?.avatarOriginalName} width={666} height={1000} />
      <div className="md:space-y-20 md:pt-[75px] lg:pt-24">
        <div className="md:layout md:layout-space bg-background">
          {!isLoading && (
            <div className="grid gap-8  md:grid-cols-5 md:gap-7 ">
              <div className="md:col-span-3 ">
                <ShowProductImages mainImage={avatarOriginalName} photos={photos} />
              </div>
              <div className={clsx(isSm && 'layout layout-space', ' md:col-span-2 ')}>
                <ProductController product={data} />
              </div>
            </div>
          )}
        </div>
        {!isProductLoading && products?.length > 0 && (
          <div className="bg-light-layout py-20">
            <div className="layout layout-space">
              <p className="font-headline-s mb-9 text-primary">Төстэй бараанууд</p>
              <ProductSwiper data={products} isLoading={isProductLoading} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
