import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useCheckUser } from '@/hooks/useCheckUser';
import { useAuth } from '@/libs/auth';

import { PricingCard } from '../components';

export const Pricing = () => {
  const { isUserSubscriptionValid } = useCheckUser();
  const [searchParams] = useSearchParams();
  const { refetchUser, user } = useAuth();

  useEffect(() => {
    if (searchParams.get('result')) {
      let isSuccess = searchParams.get('result');
      if (isSuccess === 'true') {
        refetchUser();
        toast.success('Таны төлбөр амжилттай төлөгдлөө.');
      } else if (isSuccess === 'false') {
        toast.warning('Таны төлбөр амжилтгүй.');
      }
    }
  }, [refetchUser, searchParams]);

  return (
    <div className="px-4 py-10 md:py-20">
      <div className="flex flex-col items-center">
        <p className="font-title-l md:font-display-s mb-10 text-primary">Гишүүнчлэлийн эрх</p>

        <div className="md:row justify-center space-y-8 md:space-x-8 md:space-y-0">
          <PricingCard isFree isCurrent={!isUserSubscriptionValid} />
          <PricingCard isCurrent={isUserSubscriptionValid} />
        </div>
      </div>
    </div>
  );
};
