import React from 'react';

import { TreeMenu } from '@/components/Elements';
import { FilterMenu } from '@/components/Elements/FilterMenu';
import { categoryTypes } from '@/constants/categoryTypes';
import { useCategories } from '@/features/category';
import { useProductFilter } from '@/features/filter';

export const ProductFilter = ({ toggleCategory = () => null }) => {
  const { data, isLoading } = useCategories(categoryTypes.commerce);
  const { data: filterData, isLoading: isFilterLoading } = useProductFilter();
  return (
    <>
      <TreeMenu toggleCategory={toggleCategory} items={isLoading ? [] : data?.children} />
      <div className="mt-4 pt-4" />
      <FilterMenu toggleCategory={toggleCategory} type="rating" item={isFilterLoading ? {} : { name: 'Үнэлгээ', children: filterData?.ratingMulti || [] }} />
      <div className="mt-4 pt-4" />
      <FilterMenu toggleCategory={toggleCategory} type="gender" item={isFilterLoading ? {} : { name: 'Хүйс', children: filterData?.genderMulti || [] }} />
      <div className="mt-4 pt-4" />
      <FilterMenu toggleCategory={toggleCategory} type="price" item={isFilterLoading ? {} : { name: 'Үнэ', children: filterData?.basePriceMulti || [] }} />
    </>
  );
};
