import React from 'react';
import { Lock } from 'react-feather';

import { categoryTypes, conditionTypes } from '@/constants/categoryTypes';
import { useAuth } from '@/libs/auth';
export const About = ({ detail, isLoading, type }) => {
  const { user } = useAuth();
  const isSubscription = user && user?.isSubscription;
  const { photos = [], description = '' } = detail || {};
  const tempPhotos = [1, 2, 3, 4, 5, 6];
  const sortForArray = [...photos];
  const sortedPhotos = sortForArray?.sort(compare);

  function compare(a, b) {
    if (a?.photoType < b?.photoType) {
      return -1;
    } else return 1;
  }

  function conditionalInfo(type) {
    if (type?.toUpperCase() === conditionTypes.ALL) {
      return { status: true };
    }
    if (type?.toUpperCase() === conditionTypes.LOGIN) {
      if (user) {
        return { status: true };
      }
      return { status: false, text: 'Нэвтэрч үзэх боломжтой' };
    }
    if (type?.toUpperCase() === conditionTypes.PREMIUM) {
      if (isSubscription) {
        return { status: true };
      }
      return { status: false, text: 'Pro хэрэглэгч үзэх боломжтой' };
    }
    return { status: false };
  }

  if (isLoading) {
    return (
      <div className="grid grid-cols-1 gap-4 px-4 md:grid-cols-4">
        <div className="col-span-4 ">
          <div className=" animate-pulse rounded">
            <div className="mb-5">
              <div className={`h-${8} mb-4 w-1/12 rounded bg-slate-200`} />
              <div className={`h-${32} mb-2 w-2/3 rounded bg-slate-200`} />
            </div>
            <hr className="mb-5 bg-light-layout" />
            <div className="grid gap-8 md:grid-cols-3 lg:grid-cols-3">
              {tempPhotos.map((p, index) => (
                <div key={`about-loader-${index}`} className="people-image-container bg-slate-300" />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="grid grid-cols-1 gap-4 px-4 md:grid-cols-4">
      <div className="col-span-4 ">
        <div className="mb-5">
          <p className="font-title-l mb-4 text-primary">Тухай</p>
          <p className="font-body-lg text-secondary-medium">{description || 'Мэдээлэл байхгүй'}</p>
        </div>
        <hr className="mb-5 bg-light-layout" />
        <div className="grid gap-8 md:grid-cols-3 lg:grid-cols-3">
          {sortedPhotos?.map((p) =>
            conditionalInfo(p?.photoType)?.status ? (
              <div key={`about-${p?.photoId}`} className={type === categoryTypes.person ? 'people-image-container' : 'image-container-company'}>
                <img alt="" src={p?.originalName} className="object-cover" />
              </div>
            ) : (
              <div key={`about-${p?.photoId}`} className="people-image-container bg-slate-300">
                <div className={'center people-image-container-center flex-col'}>
                  <Lock size={36} strokeWidth={3} className={'text-support'} />
                  <p className={'font-body-lg text-center text-secondary-medium'}>{conditionalInfo(p?.photoType)?.text}</p>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};
