import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { useAuth } from '@/libs/auth';
import { axios } from '@/libs/axios';

const updateUser = (data) => axios.put('/customer/edit/self', { customer: data });

export const useUpdateUser = () => {
  const { refetchUser } = useAuth();

  return useMutation(updateUser, {
    onSuccess: () => {
      refetchUser();
      toast.success('Та мэдээллээ амжилттай шинэчиллээ.');
    }
  });
};
