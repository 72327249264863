import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

export const useFilterName = () => {
  const location = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [name, setName] = React.useState('');

  const [debouncedName] = useDebounce(name, 500);

  const searchName = searchParams.get('searchValue');

  React.useEffect(
    () => {
      if (searchName) {
        setName(searchName);
      }
    },
    // eslint-disable-next-line
    []
  );

  React.useEffect(
    () => {
      if (location.state?.fromSearch) {
        setName(searchName);
      }
    },
    // eslint-disable-next-line
    [searchName]
  );

  return { debouncedName, name, setName };
};
