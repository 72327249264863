import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronUp, ChevronDown, ChevronRight } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { shadow } from 'tailwindcss/lib/util/dataTypes';

import { FilterName } from '@/components/Elements/FilterName';
import { menuActions } from '@/store/menuSlice';

const isChildrenActive = (item, active) => {
  const { categoryId, children: items } = item;
  if (categoryId === active) return true;
  else if (!items) return false;
  else return items.map((_item) => isChildrenActive(_item, active)).some((v) => v === true);
};

export const TreeMenuItem = ({ className, item, depth = 0, isParentActive = false }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isCategoryOpen = useSelector((state) => state.menu.isCategoryOpen);
  const toggleCategory = () => isCategoryOpen && dispatch(menuActions.toggleCategory());

  const searchParams = new URLSearchParams(location.search);

  const { categoryId, name, children: items, parentId } = item;

  const active = searchParams.get('category');
  const isActive = isChildrenActive(item, active);

  function onClick() {
    const { pathname } = location;
    const newSearchParams = searchParams;
    if (newSearchParams.get('category') !== categoryId) {
      newSearchParams.set('category', categoryId);
    } else {
      newSearchParams.set('category', parentId);
    }
    navigate(`${pathname}?${newSearchParams.toString()}`);
    toggleCategory();
  }

  let buttonStyle = '';
  let expandIcon = <ChevronRight className={clsx('h-5 w-5 group-hover:text-support', isActive && ' text-support')} />;
  if (Array.isArray(items) && items.length) {
    expandIcon = !isActive ? <ChevronDown className="h-5 w-5" /> : <ChevronUp className="h-5 w-5 text-support" />;
  }

  const hasActive = isParentActive || isActive;

  return (
    <motion.div className={clsx('rounded-md', hasActive && depth === 0 && shadow)} animate={{ backgroundColor: hasActive ? 'white' : 'transparent' }}>
      <motion.li
        animate={{ paddingLeft: depth * 20 + 16 }}
        initial={false}
        onClick={onClick}
        className={clsx('group rounded-md group-hover:text-support', hasActive ? 'group-hover:bg-[#f6f6f6]' : 'group-hover:bg-[#eeeeee]', buttonStyle, className)}>
        <button
          className={clsx(
            'text-start focus:text-accent font-label-l  ease-in-expo flex flex w-full justify-between py-3 pr-4 capitalize outline-none transition-all focus:outline-none focus:ring-0 group-hover:text-support',
            isActive ? ' text-support' : 'text-secondary-high'
          )}>
          <span>{name}</span>
          <p className="font-label-l text-secondary-high">{expandIcon}</p>
        </button>
      </motion.li>
      <AnimatePresence initial={false}>
        {Array.isArray(items) && isActive && (
          <motion.li layout>
            <motion.ul
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 }
              }}
              transition={{ duration: 0.35, ease: [0.04, 0.62, 0.23, 0.98] }}>
              {items.map((currentItem) => {
                const childDepth = depth + 1;
                return (
                  <TreeMenuItem isParentActive={isActive} key={`${currentItem.name}${currentItem.slug}`} item={currentItem} depth={childDepth} className={clsx('text-body ms-5')} />
                );
              })}
            </motion.ul>
          </motion.li>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export const TreeMenu = ({ name = 'Төрөл', items, className }) => {
  return (
    <ul className={clsx('relative rounded-md bg-[#E6E6E6] px-2 pt-10 pb-2 text-base', className)}>
      <FilterName name={name} />
      {items?.map((item, index) => (
        <TreeMenuItem isLast={index === items?.length - 1} key={`${item.categoryId}`} item={item} />
      ))}
    </ul>
  );
};
