import { useQuery } from 'react-query';

import { axios, decodeUrl } from '@/libs/axios';

import { orderKeys } from './orderKeys';

const fetchOrders = (searchParams) => {
  return axios.get(`/customer/order?${decodeUrl(searchParams.toString())}`);
};

export const useOrder = (searchParams, opt) => {
  return useQuery(orderKeys.filter(searchParams.toString()), () => fetchOrders(searchParams), opt);
};
