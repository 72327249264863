import { Confirmation } from './Confirmation';
import { PasswordReset } from './PasswordReset';
import { Register } from './Register';

export const authRoutes = [
  {
    children: [
      { path: 'register', element: <Register /> },
      { path: 'register/confirmation', element: <Confirmation /> },
      { path: 'password-reset', element: <PasswordReset /> }
    ]
  }
];
