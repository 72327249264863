import clsx from 'clsx';

import { AwardItem } from '@/components/Awards/AwardItem';
import { SearchResultNotFound } from '@/components/Elements';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';
import { useAuth } from '@/libs/auth';

export const AwardContainer = ({ name, awards }) => {
  const { user } = useAuth();
  const isSubscription = user && user?.isSubscription;
  const { isSm } = useWindowDimensions();
  if (!user) {
    return (
      <div className="center w-full">
        <div className={clsx('opacityBackground  rounded p-10 text-center', isSm ? 'w-full p-1' : 'w-1/2')}>
          <span className="font-body-l mr-1 text-support">Нэвтрэх</span>
          <span className="font-body-l mr-1">эсвэл</span>
          <span className="font-body-l mr-1 text-support">Бүртгүүлээд</span>
          <span className="font-body-l ">{`“${name}”-йин талаар илүү дэлгэрэнгүй мэдээллийг авна уу.`}</span>
        </div>
      </div>
    );
  }
  if (!isSubscription) {
    return (
      <div className="center w-full ">
        <div className={clsx('opacityBackground  rounded p-10 text-center', isSm ? 'w-full p-2' : 'w-1/2')}>
          <span className="font-body-l mr-1 text-support">Pro</span>
          <span className="font-body-l text-center">{`хэрэглэгч болж “${name}”-йин талаар илүү дэлгэрэнгүй мэдээллийг авна уу.`}</span>
        </div>
      </div>
    );
  }
  if (awards?.length === 0) {
    return <SearchResultNotFound />;
  }
  return (
    <div className=" grid grid-cols-1 gap-4 px-4 md:grid-cols-4 md:gap-8">
      {awards?.map((a) => (
        <AwardItem awardItem={a} key={`award-${a?.awardId}`} />
      ))}
    </div>
  );
};
