export const categoryTypes = {
  person: 'person',
  company: 'agency',
  commerce: 'commerce'
};
export const tabTypes = {
  ABOUT: 'ABOUT',
  AWARDS: 'AWARDS',
  ARTICLES: 'ARTICLES',
  WORKS: 'WORKS',
  ALL_MEN: 'ALL_MEN',
  ALL_WOMAN: 'ALL_WOMAN'
};
export const conditionTypes = {
  ALL: 'ALL',
  LOGIN: 'LOGIN',
  PREMIUM: 'PREMIUM'
};
