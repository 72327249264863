import clsx from 'clsx';
import { isArray, isObject } from 'lodash';
import * as React from 'react';
import { useState } from 'react';

import { RoutePath } from '@/components/Elements/RoutePath';
import { Categories } from '@/components/TopDetail/Components/Categories';
import { Labels } from '@/components/TopDetail/Components/Labels';
import { categoryTypes } from '@/constants/categoryTypes';
import { usePack } from '@/features/pack';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';

export const TopDetail = ({ detail = {}, type, isLoading = false }) => {
  const { avatarOriginalName, lastname, firstname, name: companyName, labels, categories, categoryDetail, averageRating } = detail || {};
  const [labelType, setLabelType] = useState('standard');
  const isPerson = type === categoryTypes.person;
  const logo = avatarOriginalName;
  const name = isPerson ? firstname : companyName;
  const packItemName = isPerson ? `${lastname}. ${firstname}` : companyName;
  const categoryItems = isPerson ? categories : [categoryDetail];
  const tempLabels = [{}, {}, {}, {}, {}, {}];
  const { PackButton } = usePack();
  const { isSm } = useWindowDimensions();

  const item = {
    id: detail?.executorId ?? detail?.companyId,
    image: detail?.avatarOriginalName,
    name: packItemName,
    categories: categoryItems,
    ...detail
  };

  return (
    <div className="bg-primary py-10">
      <div className="layout px-4 md:px-10 2xl:px-0">
        <div className="mb-6 h-8">
          <RoutePath type={type} isDetail categoryId={isArray(categoryItems) && categoryItems?.length > 0 && categoryItems[0]?.categoryId} />
        </div>
        <div className="layout sm:flex-col md:grid md:grid-cols-8 md:content-center md:gap-7">
          <div className=" grid grid-cols-4 content-center md:col-span-2 lg:col-span-1">
            {isLoading ? (
              <div className="image-container col-span-2 col-start-2 mb-6 animate-pulse rounded rounded bg-slate-200 md:col-span-4  md:mb-0 lg:col-span-4" />
            ) : (
              <div className="image-container col-span-2 col-start-2 mb-6 md:col-span-4 md:mb-0 lg:col-span-4 ">
                <img alt="" src={logo} className="company-image-container-center bg-white" />
              </div>
            )}
          </div>
          {isLoading ? (
            <div className="md:center col-span-4  animate-pulse rounded  md:col-span-6 lg:col-span-7">
              <div className="flex-1">
                <div className={clsx('mb-5 flex justify-between', isSm ? 'flex-col justify-center' : '')}>
                  <div className={clsx('', isSm ? 'center mb-6 flex-col' : '')}>
                    <div className="mb-1 h-10 w-64 rounded bg-slate-200" />
                    <div className="h-4 w-16 rounded bg-slate-200" />
                  </div>
                  <div className="center flex">
                    <div className="h-10 w-32 rounded bg-support" />
                  </div>
                </div>
                <hr />
                <div className="mt-5">
                  <Labels labels={tempLabels} type={type} />
                </div>
              </div>
            </div>
          ) : (
            <div className="md:center col-span-4  md:col-span-6 lg:col-span-7">
              <div className="flex-1">
                <div className={clsx('mb-5 flex justify-between', isSm ? 'flex-col justify-center' : '')}>
                  <div className={clsx('', isSm ? 'center mb-6 flex-col' : '')}>
                    <p className="font-headline-l mb-1 text-white">{name}</p>
                    {categoryItems?.length > 0 && <Categories categories={categoryItems} type={type} />}
                  </div>
                  <div className="center flex">
                    {isArray(labels) && isObject(labels?.find((l) => l.type === 'international')) && (
                      <button
                        className={'font-label-l mr-4 box-border h-10 rounded-sm bg-zinc-200 py-2 px-6 text-sm text-support hover:bg-zinc-400'}
                        onClick={() => {
                          if (labelType === 'standard') {
                            setLabelType('international');
                          } else {
                            setLabelType('standard');
                          }
                        }}>
                        {labelType === 'standard' ? 'International' : 'Standard'}
                      </button>
                    )}
                    <PackButton item={item} type={type} />
                  </div>
                </div>
                <hr />
                <div className="mt-5">
                  <Labels avgRating={averageRating} labels={labels} type={type} labelType={labelType} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
