import React from 'react';

import NoResult from '@/assets/search-not-found.png';

export const SearchResultNotFound = () => {
  return (
    <div className="flex w-full flex-col items-center">
      <img src={NoResult} className="h-96 w-96 object-cover" alt="хайлт олдсонгүй" />
      <p className="text-zinc-700 sm:text-base md:text-xl lg:text-2xl">Илэрц олдсонгүй...</p>
    </div>
  );
};
