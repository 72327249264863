import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { X } from 'react-feather';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Button, IconButton } from '@/components/Elements';
import { categoryTypes } from '@/constants/categoryTypes';
import { useCheckUser } from '@/hooks/useCheckUser';
import { menuActions } from '@/store/menuSlice';
import { packActions, packSelector } from '@/store/packSlice';

export const PackOrderModal = ({ isPackOrderOpen, togglePackOrder }) => {
  const dispatch = useDispatch();
  const { isUserLoggedIn, isUserSubscription, isUserSubscriptionValid, handleOnClickSubscriptionRequired } = useCheckUser();
  const packItem = useSelector(packSelector.getSelectedPackItem);
  const { image, name, type, categories } = packItem || {};

  if (!name) {
    return null;
  }

  const categoryNames = categories.map((category) => category.name).join(', ');

  const handleOnClose = () => {
    dispatch(packActions.removeSelectedPackItem());
    togglePackOrder();
  };

  const handleOnClickAddPack = () => {
    batch(() => {
      dispatch(packActions.addPackItem({ type, item: packItem }));
      dispatch(menuActions.togglePack());
    });
  };

  const getButtonText = () => {
    if (!isUserLoggedIn) return 'Нэвтрэх';
    if (!isUserSubscriptionValid) return 'Pro хэрэглэгч боолох';
    if (isUserSubscription && !isUserSubscriptionValid) return 'Эрх сунгах';
    return 'Багцанд нэмэх';
  };

  const getDescription = () => {
    if (!isUserLoggedIn) return 'Уучлаарай та бүртгэлгүй байна! Бүртгэл үүсгээд өөрийн багцаа үүсгэх боломжтой...';
    if (!isUserSubscriptionValid) return 'Уучлаарай та Pro хэрэглэгч биш байна! Эрхээ нэмээд өөрийн багцаа үүсгэх боломжтой...';
    if (isUserSubscription && !isUserSubscriptionValid) return 'Уучлаарай таны Pro дууссан байна! Эрхээ сунгаад өөрийн багцаа үүсгэх боломжтой...';
    return `Та ${name}-г өөрийн багцанд хэмэх`;
  };

  return (
    <Transition
      show={isPackOrderOpen}
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 -translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-1">
      <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto" onClose={handleOnClose}>
        <div className="min-h-screen text-center">
          <Transition.Child as={Fragment}>
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child as={Fragment}>
            <div className="inline-block w-full max-w-3xl transform overflow-hidden rounded-sm bg-white text-left align-middle shadow-xl transition-all">
              <div className="grid grid-cols-2">
                <div className="bg-primary-high p-6">
                  <div className={clsx(type === categoryTypes.person && 'image-container', type === categoryTypes.company && 'square-image')}>
                    <img src={image} alt={`${type} avatar`} />
                  </div>
                  <div className="mt-4">
                    <p className="font-title-m text-secondary-high">{name}</p>
                    <p className="font-body-s text-secondary-medium">{categoryNames}</p>
                  </div>
                </div>
                <div className="bg-light-layout p-6">
                  <div className="row items-center justify-between">
                    <p className="font-title-l">Багцад нэмэх</p>
                    <IconButton onClick={handleOnClose} size="md">
                      <X />
                    </IconButton>
                  </div>
                  <div className="font-body-m flex h-full flex-col items-center justify-center text-center text-secondary-medium">
                    <p>{getDescription()}</p>
                    <Button className="mt-4" variant="filled" onClick={handleOnClickSubscriptionRequired(handleOnClickAddPack, handleOnClose)}>
                      {getButtonText()}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
