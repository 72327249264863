import clsx from 'clsx';
import { compareAsc, format } from 'date-fns';
import { useRef, useState } from 'react';
import { CheckCircle } from 'react-feather';

import { Button } from '@/components/Elements';
import { API_URL, STS_URL, WEB_URL } from '@/config';
import { useCheckUser } from '@/hooks/useCheckUser';
import { useAuth } from '@/libs/auth';
import { TugCurrency } from '@/libs/react-number-format';

import { useSubscription } from '../api';

const pricingPlans = {
  free: {
    title: 'Үнэгүй эрх',
    price: 0,
    items: ['Агентлагуудын мэдээлэл харах', 'Моделуудын мэдээлэл харах', 'Хувь хүмүүсийн мэдээлэл харах', 'Онлайн дэлгүүрээс худалдан авалт хийх']
  },
  premium: {
    title: 'Премиум эрх',
    price: 25000,
    items: ['Агентлагуудын мэдээлэл харах', 'Моделуудын мэдээлэл харах', 'Хувь хүмүүсийн мэдээлэл харах', 'Онлайн дэлгүүрээс худалдан авалт хийх', 'Багц захиалга өгөх']
  }
};

const PricingItem = ({ text }) => (
  <li className="font-body-m row py-3.5 text-primary">
    <CheckCircle className="mr-4 h-6 w-6 text-green" />
    {text}
  </li>
);

const getPricingText = (endAt) => (compareAsc(new Date(), new Date(endAt)) >= 0 ? 'Та эрхээ сунгана уу' : 'Таны одоогийн гишүүнчлэл');
const getPricingTextColor = (endAt) => (compareAsc(new Date(), new Date(endAt)) >= 0 ? 'text-[#9F1200]' : 'text-green');
const getPricingBgColor = (endAt) => (compareAsc(new Date(), new Date(endAt)) >= 0 ? 'bg-red' : 'bg-green');
const getPricingBgOpacity = (endAt) => (compareAsc(new Date(), new Date(endAt)) >= 0 ? 'bg-opacity-5' : 'bg-opacity-10');

const PricingCurrentPlan = ({ isBlocked, endAt }) =>
  isBlocked ? (
    <div className="mb-4 space-y-1 rounded bg-[#FFC043] bg-opacity-10 p-4 text-[#B57C0A]">
      <p className="font-body-m">Таний эрх блок хийгдсэн байна.</p>
      <p className="font-body-l">{format(new Date(endAt), 'yyyy.MM.dd')}</p>
    </div>
  ) : (
    <div className={clsx(`mb-4 space-y-1 rounded p-4`, getPricingTextColor(endAt), getPricingBgColor(endAt), getPricingBgOpacity(endAt))}>
      <p className="font-body-m">{getPricingText(endAt)}</p>
      <p className="font-body-l">{format(new Date(endAt), 'yyyy.MM.dd')}</p>
    </div>
  );

export const PricingCard = ({ isCurrent = false, isFree }) => {
  const { handleOnClickLoggedInRequired } = useCheckUser();
  const { user } = useAuth();
  const { subscriptionEndAt, subscription } = user || {};
  const formRef = useRef();
  const { isLoading, mutateAsync } = useSubscription();
  const shopperResultUrl = API_URL + 'subscription/payment';
  const shopperCancelUrl = WEB_URL + 'pricing?result=false';
  const [data, setData] = useState({ checkoutId: '', lang: 'mn' });
  const selectedPlan = pricingPlans[isFree ? 'free' : 'premium'];

  const handleSubscription = async () => {
    const res = await mutateAsync();
    setData({ ...data, checkoutId: res.checkoutId });
    formRef.current.submit(new Event('submit'));
  };

  const isBlocked = !subscription && subscriptionEndAt;
  const isNewUser = !subscription && !subscriptionEndAt;

  return (
    <div className="w-full rounded mymd:w-96">
      <div className="rounded-t bg-primary py-4 text-center text-primary-high">
        <p className="font-title-m">{selectedPlan.title}</p>

        <p className="font-display-s mt-2 text-4xl">
          <TugCurrency value={selectedPlan.price} className="text-4xl" /> MNT
        </p>
      </div>

      <div className="rounded-b border-2 border-t-0 border-light-layout p-4 pt-6">
        <ul className="mb-10">
          {selectedPlan.items.map((text) => (
            <PricingItem key={`${selectedPlan.title}.${selectedPlan.price}.${text}`} text={text} />
          ))}
        </ul>

        {!!subscriptionEndAt && !isFree && <PricingCurrentPlan isBlocked={isBlocked} endAt={subscriptionEndAt} />}
        {((subscription && !isCurrent && !isFree) || isNewUser) && (
          <Button isLoading={isLoading} className="w-full" onClick={handleOnClickLoggedInRequired(handleSubscription)} disabled={isCurrent} variant="filled">
            Идэвхжүүлэх
          </Button>
        )}

        <form className="invisible h-0" ref={formRef} id="form1" name="form1" method="post" action={STS_URL + 'payment/'}>
          <input readOnly type="text" name="checkoutId" value={data.checkoutId} />
          <input readOnly type="text" name="shopperResultUrl" value={shopperResultUrl} />
          <input readOnly type="text" name="shopperCancelUrl" value={shopperCancelUrl} />
          <input readOnly type="text" name="lang" value={data.lang} />
        </form>
      </div>
    </div>
  );
};
