import { useMutation } from 'react-query';

import { axios } from '@/libs/axios';

const customerActivateRequest = (token) => {
  return axios.post(`customer/activate/${token}`);
};

export const useMutateActive = () => {
  return useMutation((token) => customerActivateRequest(token));
};
