export const getDiscount = (productOptions) => productOptions?.find((po) => po.offAmount > 0 || po.offPercentage > 0) || {};

export const getDiscountPrice = (discount) => {
  if (discount?.offAmount > 0) {
    return Math.max(discount?.price - discount?.offAmount, 0);
  } else if (discount?.offPercentage > 0) {
    return Math.ceil(discount?.price * (1 - discount?.offPercentage / 100));
  }
  return null;
};

export const getTotalCartItemsPrice = (items = []) => items.reduce((total, item) => total + (getDiscountPrice(item) || item.price) * item.qty, 0);

export const getTotalDiscountCartItemsPrice = (items = []) =>
  Math.ceil(
    items.reduce((total, item) => {
      const discountPrice = getDiscountPrice(item);
      return total + (discountPrice ? item.price - discountPrice : 0) * item.qty;
    }, 0) || 0
  );
