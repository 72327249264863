import React from 'react';

import { ProductSwiper } from '@/components/Product/ProductSwiper';

import { HomeItem } from '../HomeItem';

const ProductSkeleton = () => {
  return (
    <div className="odd:bg-white even:bg-light-layout">
      <div className="layout  py-20">
        <div className="mb-8 animate-pulse">
          <div className="mb-2 h-6 w-1/5 rounded bg-secondary-disabled" />
          <div className=" h-4 w-2/6 rounded bg-secondary-disabled" />
        </div>
        <ProductSwiper isLoading={true} />
      </div>
    </div>
  );
};
export const ProductWrapper = ({ isLoading, productData }) => {
  if (isLoading) {
    return <ProductSkeleton />;
  }
  return <HomeItem category={{ title: 'Онцлох хувцаснууд', description: 'Models.mn холбооны санал болгож буй хувцаснууд', data: productData, type: 'product' }} />;
};
