import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { TugCurrency } from '@/libs/react-number-format';
import { getDiscount, getDiscountPrice } from '@/utils/products';

export const ProductItem = ({ value, toggleSearch = () => null }) => {
  const { id, name, basePrice, productOptions, avatarOriginalName, brandDetail } = value;
  const { name: brandName } = brandDetail || {};
  const productDiscount = getDiscount(productOptions);

  const discountPrice = getDiscountPrice(productDiscount);

  return (
    <li className="list-none">
      <Link to={`/shop/${id}`} onClick={() => toggleSearch()}>
        <div className="relative flex flex-1 flex-col text-left">
          <div className=" relative">
            {!!discountPrice && (
              <div className="absolute top-3 right-0 bg-primary px-3 text-white">
                {productDiscount?.offPercentage > 0 && <p className="text-sm md:text-base">{productDiscount.offPercentage}%</p>}
              </div>
            )}
            <div className="image-container">
              <img className="duration-500 hover:scale-125" src={avatarOriginalName} alt={name} />
            </div>

            <div className="space-y-1 p-2 ">
              <p className="font-body-xs text-secondary-medium">{brandName}</p>
              <p className="font-title-m text-primary">{name}</p>

              <div className={clsx('font-title-s flex text-support', discountPrice ? 'justify-between' : 'justify-start')}>
                {discountPrice ? (
                  <>
                    <TugCurrency value={basePrice} className="text-zinc-300 line-through" />
                    <TugCurrency value={discountPrice} />
                  </>
                ) : (
                  <TugCurrency value={basePrice} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
};
