import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@/components/Elements';
import { categoryTypes } from '@/constants/categoryTypes';
import { getLinkToList } from '@/utils/link';

import { CategoryHoverContent } from './components/CategoryHoverContent';

export const HeaderCategories = ({ header }) => {
  const headerData = JSON.parse(header);

  const [isShow, setIsShow] = useState(false);

  const handleMouseEnter = useCallback(() => setIsShow(true), []);
  const handleMouseLeave = useCallback(() => setIsShow(false), []);

  return (
    <span key={headerData.categoryId} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Link to={getLinkToList(headerData)}>
        <Button onClick={handleMouseLeave}>{headerData.name}</Button>
      </Link>

      <CategoryHoverContent
        isCommerce={headerData.type === categoryTypes.commerce}
        isShow={isShow}
        handleMouseLeave={handleMouseLeave}
        parentCategory={{ type: headerData.type, categoryId: headerData.categoryId, name: 'Бүгд', isParent: true }}
        categories={headerData.children}
        recommendation={JSON.stringify(headerData.recommendation)}
      />
    </span>
  );
};
