import { ProductDetail } from './ProductDetail';
import { ProductList } from './ProductList';

export const shopRoutes = [
  {
    path: '/shop',
    children: [
      {
        path: '',
        element: <ProductList />
      },
      {
        path: ':id',
        element: <ProductDetail />
      }
    ]
  }
];
