import { MinusIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import React, { useState } from 'react';

export const Input = ({ autoComplete, disabled = false, error, containerStyle, field, label, placeholder, right = {}, type = 'text' }) => {
  const { text, onClick } = right;
  return (
    <div className={clsx('flex flex-col text-primary', containerStyle)}>
      <label className="font-body-l mb-2">{label}</label>
      <div className="flex flex-row border border-border">
        <input
          autoComplete={autoComplete}
          type={type}
          disabled={disabled}
          className="font-body-m w-full flex-1 bg-transparent px-4 py-3.5 placeholder:text-primary focus:outline-none"
          placeholder={placeholder}
          {...field}
        />
        {!!text && (
          <button type="button" className="ml-1 mr-4 text-sm text-gray-400" onClick={() => onClick && onClick()}>
            {text}
          </button>
        )}
      </div>
      {error && (
        <p className="mt-2 flex items-center text-xs text-support">
          <MinusIcon className="mr-1 inline h-3 w-3" /> {error}
        </p>
      )}
    </div>
  );
};

export const PasswordInput = ({ field, ...props }) => {
  const [psType, setPsType] = useState('password');
  return (
    <Input
      field={field}
      right={{
        text: psType === 'password' ? 'Харах' : 'Нуух',
        onClick: () => setPsType((c) => (c === 'password' ? 'text' : 'password'))
      }}
      type={psType}
      autoComplete="new-password"
      {...props}
    />
  );
};
