import { Disclosure } from '@headlessui/react';

export const ProductDisclosure = ({ text, children }) => {
  return (
    <div className="">
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <>
            <Disclosure.Button disabled className="font-body-lg flex w-full justify-between text-primary">
              <span>{text}</span>
            </Disclosure.Button>
            <Disclosure.Panel className="font-body-m pt-2  text-secondary-medium">{children}</Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
