import React from 'react';

import { WorkerItem } from '@/components/Company';
import { SearchResultNotFound } from '@/components/Elements';
import { PeopleItem } from '@/components/People';

export const Workers = ({ type, data }) => {
  if (data?.length === 0) {
    return <SearchResultNotFound />;
  }
  return (
    <div className="grid grid-cols-3 gap-4">
      {data.map((item, key) => (type === 'worker' ? <WorkerItem value={item} key={'worker' + key} /> : <PeopleItem value={item} key={item?.executorId} />))}
    </div>
  );
};
