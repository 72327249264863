import clsx from 'clsx';

import { Button } from '@/components/Elements';

const categories = [
  { value: 'agencies', label: 'Байгууллага' },
  { value: 'people', label: 'Хувь Хүн' },
  { value: 'shop', label: 'Дэлгүүр' }
];

export const SearchCategories = ({ selectedCategory, setSelectedCategory }) => {
  return (
    <div className="md:row my-4 flex-col text-base">
      {categories.map((category) => (
        <Button key={category.value} onClick={() => setSelectedCategory(category)}>
          <p className={clsx(selectedCategory.value === category.value && 'text-support')}>{category.label}</p>
        </Button>
      ))}
    </div>
  );
};
