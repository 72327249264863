import React from 'react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useProducts } from '@/features/shop';

import { useBanners, useHomeList } from '../api';
import { HomeBanner, HomeItem, HomeNewsContainer, HomeSkeleton, ProductWrapper } from '../components';

SwiperCore.use([Autoplay, Pagination]);

export const Home = () => {
  const { data, isLoading } = useHomeList();
  const { data: banners, isLoading: isBannerLoading } = useBanners();
  const { data: productData, isLoading: isProductLoading } = useProducts(new URLSearchParams()) || {};
  const { news } = data || {};
  const { products } = productData || {};
  return (
    <>
      <HomeBanner data={banners} isLoading={isBannerLoading} />
      <div>
        {isLoading && <HomeSkeleton />}
        {isLoading || data?.main?.map((category) => <HomeItem key={`home-item-${category.categoryId}`} category={category} />)}
        <HomeNewsContainer articles={news} isLoading={isLoading} />
        <ProductWrapper isLoading={isProductLoading} productData={products} />
      </div>
    </>
  );
};
