import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { ProfileLayout } from '@/components/Layout';

import { Profile } from './Profile';

export const App = ({ hasFooter }) => {
  return (
    <ProfileLayout hasFooter={hasFooter}>
      <Suspense
        fallback={
          <div className="flex h-full w-full items-center justify-center">
            <Spinner size="xl" />
          </div>
        }>
        <Outlet />
      </Suspense>
    </ProfileLayout>
  );
};

export const accountRoutes = {
  path: '/account',
  element: <App />,
  children: [{ element: <Profile />, path: '/account/profile' }]
};
