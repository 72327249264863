import clsx from 'clsx';
import { useRef, useState } from 'react';
import { ArrowLeft, ArrowRight, Circle } from 'react-feather';
import ReactPlayer from 'react-player';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useWindowDimensions } from '@/hooks/useWindowDimensions';
import { classNames } from '@/utils/classNames';
const SkeletonBanner = () => {
  return (
    <div className="layout layout-space">
      <div className="home-banner-container animate-pulse bg-secondary-disabled" />
      <div className={'center mt-10 w-full flex-col'}>
        <div className="mb-3.5 h-5 w-1/6 animate-pulse rounded bg-secondary-disabled" />
        <div className="mb-3.5 h-9 w-full animate-pulse rounded bg-secondary-disabled md:h-16" />
        <div className="mb-3.5 h-9 w-1/2 animate-pulse rounded bg-secondary-disabled md:h-16" />
      </div>
    </div>
  );
};

export const HomeBanner = ({ data, isLoading }) => {
  const banner = isLoading ? [1, 2, 3] : data?.banner?.filter((v) => v?.active) || [];
  const swiperRef = useRef(null);
  const initialIndex = isLoading ? 0 : Math.floor(banner.length / 2);
  const divRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(initialIndex);
  const myRef = useRef(null);
  const [entered, setEntered] = useState(false);
  const { isSm } = useWindowDimensions();

  if (banner?.length === 0) {
    return null;
  }
  return (
    <div className={'bg-primary py-10 md:py-20'} ref={divRef}>
      <div className={' relative'}>
        <div className={'absolute z-10 flex  hidden  w-full justify-between px-4 md:top-1/2 md:flex md:px-5 lg:px-[15%]'}>
          <button
            onClick={() => {
              swiperRef.current.swiper.slidePrev();

              // swiperRef.current.swiper.autoplay.start();
            }}
            className={classNames('bg-orange', 'center h-10 w-10 rounded-3xl ')}>
            <ArrowLeft size={16} color={'white'} />
          </button>
          <button
            onClick={() => {
              swiperRef.current.swiper.slideNext();
              // swiperRef.current.swiper.autoplay.start();
            }}
            className={classNames('bg-orange', 'center h-10 w-10 rounded-3xl ')}>
            <ArrowRight size={16} color={'white'} />
          </button>
        </div>

        {isLoading && <SkeletonBanner />}
        {isLoading || (
          <>
            <Swiper
              ref={swiperRef}
              initialSlide={initialIndex}
              spaceBetween="15%"
              slidesPerView={isSm ? 1 : 1.5}
              autoplay={{ delay: 5000 }}
              onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}
              centeredSlides={true}
              onSlideChange={(index) => setActiveIndex(index.realIndex)}
              modules={[Navigation]}>
              {banner?.map((item, i) => (
                <SwiperSlide key={`home-banner-${item?.id + i}`}>
                  {item?.type === 'video' ? (
                    <>
                      <div className={'home-banner-container'}>
                        <ReactPlayer
                          ref={myRef}
                          url={item?.url}
                          width={'100%'}
                          height={'100%'}
                          muted={true}
                          playing={i === activeIndex}
                          controls={true}
                          onReady={() => {
                            myRef.current.props.playing = true;
                          }}
                          config={{
                            youtube: {
                              playerVars: { autoPlay: 1 },
                              onUnstarted: () => {
                                myRef.current.props.playing = true;
                              }
                            }
                          }}
                        />
                      </div>
                      <div className={'mt-10 flex-col text-center'}>
                        <p className={clsx('lg:font-headline-s mb-2 font-medium text-orange md:mb-0')}>{banner[activeIndex]?.name}</p>
                        <div className="md:h-16 lg:h-36">
                          <p className={clsx('font-headline-m lg:font-banner-l text-white duration-300 line-clamp-2 hover:text-primary-medium', entered && 'text-primary-medium')}>
                            {banner[activeIndex]?.description}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <a rel="noopener noreferrer" href={item?.url} target="_blank" className="select-none">
                      <div className="home-banner-container px-4 md:px-0" onMouseEnter={() => setEntered(true)} onMouseLeave={() => setEntered(false)}>
                        <img alt="banner" src={item?.avatarOriginalName} />
                      </div>
                      <div className={'mt-10 flex-col text-center'}>
                        <p className={clsx('lg:font-headline-s mb-2 font-medium text-orange md:mb-0')}>{banner[activeIndex]?.name}</p>
                        <div className="md:h-16 lg:h-36">
                          <p className={clsx('font-headline-m lg:font-banner-l text-white duration-300 hover:text-primary-medium', entered && 'text-primary-medium')}>
                            {banner[activeIndex]?.description}
                          </p>
                        </div>
                      </div>
                    </a>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        )}
      </div>

      <div className="center relative mt-10 flex md:mt-20  ">
        <div className={'visible  absolute z-10  flex w-full justify-between px-4 md:hidden '}>
          <button
            onClick={() => {
              swiperRef.current.swiper.slidePrev();
              // swiperRef.current.swiper.autoplay.start();
            }}
            className={classNames('bg-orange', 'center h-10 w-10 rounded-3xl ')}>
            <ArrowLeft size={16} color={'white'} />
          </button>
          <button
            onClick={() => {
              swiperRef.current.swiper.slideNext();
              // swiperRef.current.swiper.autoplay.start();
            }}
            className={classNames('bg-orange', 'center h-10 w-10 rounded-3xl ')}>
            <ArrowRight size={16} color={'white'} />
          </button>
        </div>
        {banner.map((val, i) => (
          <Circle
            color={i === activeIndex ? '#E93323' : '#AEAEAE'}
            fill={i === activeIndex ? '#E93323' : 'transparent'}
            className={classNames(i === activeIndex ? 'h-4 w-4' : 'h-3 w-3', 'mx-2')}
            key={i + 'title'}
          />
        ))}
      </div>
    </div>
  );
};
