import { yupResolver } from '@hookform/resolvers/yup';
import { isUndefined } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Logo } from '@/components/Elements';
import { Input, PasswordInput } from '@/components/Form';
import { useLoginAndLogoutRedirect } from '@/hooks/useLoginAndLogoutRedirect';
import { useAuth } from '@/libs/auth';
import { getError } from '@/libs/react-hook-form';
import { yup } from '@/libs/yup';

const schema = yup.object({ email: yup.string().email('И-мэйлээ зөв оруулна уу.').required('И-мэйлээ оруулна уу.'), password: yup.string().password() });

export const LoginForm = ({ toggleLogin, togglePasswordReset }) => {
  const { loginRedirect } = useLoginAndLogoutRedirect();
  const { refetchUser, login, isLoggingIn } = useAuth();

  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setFocus,
    reset,
    watch
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { password: '', email: '' }
  });

  const handleCloseLogin = () => {
    reset();
    toggleLogin();
  };

  const onSubmit = async (values) => {
    await login({ ...values });
    refetchUser();
    handleCloseLogin();
    loginRedirect();
  };

  const handleRegister = () => {
    navigate('/auth/register');
    handleCloseLogin();
  };

  const email = watch('email');

  useEffect(() => {
    if (!isUndefined(email)) {
      setFocus('email');
    }
  }, [email, setFocus]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row justify-center">
        <Logo />
      </div>

      <Controller
        name="email"
        control={control}
        render={({ field }) => <Input containerStyle="mt-12" error={getError(errors, 'email')} field={field} label="И-мэйл хаяг" placeholder="И-мэйл хаяг" />}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => <PasswordInput containerStyle="mt-6" error={getError(errors, 'password')} field={field} label="Нууц үг" placeholder="Нууц үгээ оруулна уу" />}
      />

      <div className="mt-4 flex justify-end">
        <button onClick={togglePasswordReset} type="button">
          <p className="font-label-l text-primary hover:text-primary-medium">Нууц үг сэргээх</p>
        </button>
      </div>

      <Button isLoading={isLoggingIn} type="submit" disabled={isLoggingIn} variant="filled" className="mt-4 w-full" text="Нэвтрэх" />
      <Button isLoading={isLoggingIn} type="button" disabled={isLoggingIn} className="mt-4 w-full" text="Бүртгүүлэх" onClick={handleRegister} />
    </form>
  );
};

LoginForm.displayName = 'LoginForm';
