import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { FreeMode, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CompanyItem } from '@/components/Company';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';
import { classNames } from '@/utils/classNames';

const RenderCompanyItem = () => {
  return (
    <div className="w-full animate-pulse rounded bg-white py-2	px-4">
      <div className="home-image-image-container mb-4 rounded bg-slate-200" />
      <div className="my-1 mb-1 h-3.5 w-24 rounded bg-slate-200 md:w-40" />
      <div className="center my-1 h-3 w-16 rounded bg-slate-200 md:w-20" />
    </div>
  );
};

export const CompanySwiper = ({ data = [], isLoading }) => {
  const loadingData = [1, 2, 3, 4, 5];
  const { isSm, isMd } = useWindowDimensions();
  const slidePerView = isSm ? 1.7 : isMd ? 3.5 : 5;
  const realData = isLoading ? loadingData : data;
  const swiperRef = useRef(null);
  const [c, setC] = useState(0);
  return (
    <div className="relative">
      <div className={clsx('absolute top-[-74px] right-[0] z-10  hidden place-self-center md:flex')}>
        <button
          onClick={() => {
            swiperRef?.current?.swiper?.slidePrev();
          }}
          className={classNames(c !== 0 ? 'bg-orange' : 'bg-light-disabled', 'center mr-4 h-10 w-10 rounded-3xl ')}>
          <ArrowLeft size={16} color={'white'} />
        </button>
        <button
          onClick={() => {
            swiperRef?.current?.swiper?.slideNext();
          }}
          className={classNames(c < realData?.length - slidePerView ? 'bg-orange' : 'bg-light-disabled', 'center mr-4 h-10 w-10 rounded-3xl ')}>
          <ArrowRight size={16} color={'white'} />
        </button>
      </div>

      <Swiper
        ref={swiperRef}
        spaceBetween={30}
        slidesPerView={slidePerView}
        modules={[Navigation, FreeMode]}
        onSlideChange={(s) => {
          setC(s.realIndex);
        }}>
        {realData?.map((item, i) => (
          <SwiperSlide key={i}>{isLoading ? <RenderCompanyItem /> : <CompanyItem value={item} />}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
