import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { XCircle } from 'react-feather';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, IconButton } from '@/components/Elements';
import { useMutateOrderService } from '@/features/order';
import { PackModalList } from '@/features/pack';
import { useCheckUser } from '@/hooks/useCheckUser';
import { packSelector } from '@/store/packSlice';

export const PackConfirmationModal = ({ isOpen, close }) => {
  const navigate = useNavigate();
  const { agency, person } = useSelector(packSelector.getItems);

  const { isLoading, mutateAsync } = useMutateOrderService();
  const { handleOnClickSubscriptionRequired } = useCheckUser();

  const handlePackOrder = async () => {
    try {
      await mutateAsync({ people: person, companies: agency });
      navigate('/pack');
    } finally {
      close();
    }
  };

  return (
    <Transition
      show={isOpen}
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 -translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-1">
      <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto" onClose={close}>
        <div className="min-h-screen text-center">
          <Transition.Child as={Fragment}>
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child as={Fragment}>
            <span className="inline-block w-full max-w-lg	transform	space-y-10 overflow-hidden bg-white p-10 text-left align-middle shadow-xl transition-all">
              <div className="flex items-center justify-between">
                <p className="font-headline-s text-primary">Баталгаажуулах</p>

                <IconButton onClick={close} size="md" color="primary">
                  <XCircle />
                </IconButton>
              </div>

              <PackModalList />

              <Button isLoading={isLoading} onClick={handleOnClickSubscriptionRequired(handlePackOrder)} className="w-full" variant="filled">
                Баталгаажуулах
              </Button>

              <div className="font-body-l bg-yellow-a1 p-4 text-center text-primary">
                <p>Таны захиалга баталгаажсаны дараа бид</p>
                <p>таньтай холбогдох болно.</p>
              </div>
            </span>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
