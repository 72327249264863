import { useQuery } from 'react-query';

import { axios, decodeUrl } from '@/libs/axios';

import { companyKeys } from './companyKeys';

const fetchCompany = (searchParams) => {
  return axios.get(`v1/company?${decodeUrl(searchParams.toString())}`);
};
export const useCompany = (searchParam, opt) => {
  return useQuery(companyKeys.list(searchParam.toString()), () => fetchCompany(searchParam), opt);
};
