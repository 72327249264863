import { useQuery } from 'react-query';

import { axios } from '@/libs/axios';

import { homeKeys } from './homeKeys';

export const fetchHomeList = () => axios.get('v1/home');

export const useHomeList = () => {
  return useQuery(homeKeys.homeList(), fetchHomeList);
};
