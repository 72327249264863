import { Link } from 'react-router-dom';

import noRecommendation from '@/assets/no-recommendation.png';
import { getLinkToDetail } from '@/utils/link';

export const RecommendationItem = ({ item, close }) => {
  const name = item.name || `${item.lastname}. ${item.firstname}`;

  const to = getLinkToDetail(item);
  return (
    <Link to={to} onClick={close}>
      <div className="image-recommendation-container">
        <img src={item.avatarPathSecond || noRecommendation} alt={name} />
      </div>

      <p className="font-label-l mt-6">{name}</p>
    </Link>
  );
};
