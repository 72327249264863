import { ChevronLeftIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { useWindowDimensions } from '@/hooks/useWindowDimensions';

import image from '../assets/img.png';
import image1 from '../assets/img_1.png';

export const NotFound = () => {
  const navigate = useNavigate();
  const { isSm, isMd } = useWindowDimensions();
  return (
    <div className={clsx('center flex h-full w-full justify-items-center', isSm ? ' p-4' : '')}>
      <div className={clsx('block flex h-full flex-col items-center justify-center', isSm ? 'w-full' : isMd ? 'w-1/2' : 'w-1/3')}>
        <p className={'font-display-s pb-4 text-secondary-high'}>Хуудас олдсонгүй</p>
        <p className={'font-body-m pb-10 text-center text-secondary-medium'}>
          Таны хайж буй хуудас олдсонгүй. Уг хуудас нь устсан, нэр нь өөрчлөгдсөн эсвэл анхнаасаа байгаагүй байж болзошгүй.
        </p>
        <button onClick={() => navigate('/')} className="bg-black px-6 py-2">
          <p className={'font-label-l text-primary-high'}>Буцах</p>
        </button>
      </div>
    </div>
  );
};
