import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';

import { Button } from '@/components/Elements';

const ProfileLinkItem = ({ text, to, isSelected }) => {
  return (
    <Link to={to}>
      <Button color={isSelected ? 'support' : 'primary'} className={clsx('h-14 px-6', isSelected && 'border-b-2 border-support')}>
        {text}
      </Button>
    </Link>
  );
};

export const ProfileLayout = ({ children }) => {
  const location = useLocation();

  const isProfileSelected = location.pathname === '/account/profile';
  const isPackSelected = location.pathname === '/pack';
  const isOrderHistorySelected = location.pathname === '/orders';

  return (
    <div className="p-5 md:p-10">
      <div className="mx-auto w-full max-w-3xl border-b border-border">
        <ul className="row w-full justify-center">
          <ProfileLinkItem isSelected={isProfileSelected} text="Хувийн мэдээлэл" to="/account/profile" />
          <ProfileLinkItem isSelected={isPackSelected} text="Багцийн түүх" to="/pack" />
          <ProfileLinkItem isSelected={isOrderHistorySelected} text="Захиалгууд" to="/orders" />
        </ul>
      </div>
      <div className="mx-auto w-full max-w-xl">{children}</div>
    </div>
  );
};
