import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import { FreeMode, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CompanyItem } from '@/components/Company';
import { Button } from '@/components/Elements';
import { WorkContainer } from '@/components/Tabs/Containers';
import { About } from '@/components/Tabs/Containers/About';
import { AwardContainer } from '@/components/Tabs/Containers/AwardContainer';
import { NewsContainer } from '@/components/Tabs/Containers/NewsContainer';
import { Workers } from '@/components/Tabs/Containers/Workers';
import { categoryTypes, tabTypes } from '@/constants/categoryTypes';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';

export const Tabs = ({ detail = {}, type, isLoading = false }) => {
  const { articles, executorId, companyId, awards } = detail || {};
  const isPerson = type === categoryTypes.person;
  const { isSm, isMd } = useWindowDimensions();
  let tabsItems = [];
  if (isPerson) {
    tabsItems = [
      { tabName: 'Хувийн мэдээлэл', tabCategory: tabTypes.ABOUT, container: () => <About type={categoryTypes.person} detail={detail} isLoading={isLoading} /> },
      { tabName: 'Туршлага', tabCategory: tabTypes.WORKS, container: () => <WorkContainer name={detail?.firstname} type={categoryTypes.person} id={executorId} /> },
      { tabName: 'Мэдээ мэдээлэл', tabCategory: tabTypes.ARTICLES, container: () => <NewsContainer news={articles} type={categoryTypes.person} id={executorId} /> },
      {
        tabName: 'Шагнал',
        tabCategory: tabTypes.AWARDS,
        container: () => <AwardContainer awards={awards} name={detail?.firstname} type={categoryTypes.person} id={executorId} />
      }
    ];
  } else {
    tabsItems = [
      { tabName: 'Профайл', tabCategory: tabTypes.ABOUT, container: () => <About type={categoryTypes.company} detail={detail} /> },
      { tabName: 'Ажилчид', tabCategory: tabTypes.ALL_MEN, container: () => <Workers type={'worker'} data={detail?.workers || []} /> },
      // { tabName: 'Харьяалтай хүмүүс', tabCategory: tabTypes.ALL_WOMAN, container: () => <Workers type={'people'} data={detail?.executors || []} /> },
      { tabName: 'Туршлага', tabCategory: tabTypes.WORKS, container: () => <WorkContainer name={detail?.name} type={categoryTypes.company} id={companyId} /> },
      { tabName: 'Шагнал', tabCategory: tabTypes.AWARDS, container: () => <AwardContainer awards={awards} name={detail?.name} type={categoryTypes.company} id={companyId} /> }
    ];
  }

  return (
    <div className="layout layout-space">
      <Tab.Group>
        <Tab.List className="mb-10 border-b-[1px]">
          {!isSm &&
            tabsItems.map((tab) => (
              <Tab key={tab.tabCategory}>
                {({ selected }) => (
                  <Button
                    className={clsx('flex h-14 items-center border-b-2 px-6', selected && 'border-b-2 border-b-support text-support')}
                    color={selected ? 'support' : 'primary'}>
                    {tab.tabName}
                  </Button>
                )}
              </Tab>
            ))}
          {isSm && (
            <Swiper slidesPerView={2.5} spaceBetween={18}>
              {tabsItems.map((tab) => (
                <SwiperSlide key={tab.tabCategory}>
                  <Tab key={tab.tabCategory}>
                    {({ selected }) => (
                      <Button
                        className={clsx('h-14 w-full items-center border-b-2  px-6', selected && 'border-b-2 border-b-support text-support')}
                        color={selected ? 'support' : 'primary'}>
                        {tab.tabName}
                      </Button>
                    )}
                  </Tab>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Tab.List>
        <Tab.Panels>
          {tabsItems.map((tabPanel) => (
            <Tab.Panel key={`tab-panel-${tabPanel.tabCategory}`}>
              <tabPanel.container />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
