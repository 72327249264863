import clsx from 'clsx';
import { Star } from 'react-feather';

import { conditionTypes } from '@/constants/categoryTypes';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';
import { useAuth } from '@/libs/auth';

export const Labels = ({ labels = [], avgRating = 0, labelType = 'standard' }) => {
  const { isSm } = useWindowDimensions();
  const { user } = useAuth();
  const isSubscription = user && user?.isSubscription;
  const sortForArray = [...labels];
  const sortedLabels = sortForArray?.sort(compare);
  function compare(a, b) {
    if (a?.type < b?.type) {
      return -1;
    } else return 1;
  }
  const rating = +avgRating || 0;
  const conditionalInfo = (permission, value) => {
    if (permission?.toUpperCase() === conditionTypes.ALL) {
      return value;
    } else if (permission?.toUpperCase() === conditionTypes.LOGIN && user) {
      return value;
    } else if (permission?.toUpperCase() === conditionTypes.PREMIUM && isSubscription) {
      return value;
    } else {
      return '********';
    }
  };
  return (
    <div className="grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-6">
      <div className={clsx('', isSm ? 'center col-span-2 flex-col' : '')}>
        <p className={'font-body-s pb-2 pl-1 font-semibold text-primary-medium'}>{rating ? `${Math.round(rating)} одтой` : 'Үнэлгээ байхгүй'}</p>
        <div className={'flex'}>
          {Array.from(Array(Math.round(rating)), (e, i) => {
            return <Star fill={'#FFC043'} size={20} className="px-1 text-yellow" key={`star-${i}`} />;
          })}
          {Array.from(Array(Math.round(5 - rating)), (e, i) => {
            return <Star fill={'#B7B7B7'} size={20} className="px-1 text-secondary-disabled" key={`not-star-${i}`} />;
          })}
        </div>
      </div>
      {sortedLabels?.map(
        (l, i) =>
          l?.type === labelType && (
            <div key={`label-${l?.key}-${i}`}>
              <div className={'center border-x-none h-6 border-2 border-dashed border-slate-400 hover:border-dotted'}>
                <p className="font-body-s mb-1 text-primary-medium">{l?.key}</p>
              </div>
              <div className={'center h-6'}>
                <p className="font-label-l text-white">{conditionalInfo(l?.permission, l?.value)}</p>
              </div>
            </div>
          )
      )}
    </div>
  );
};
