import clsx from 'clsx';
import React from 'react';
import { MinusCircle } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { IconButton } from '@/components/Elements';
import { packActions } from '@/store/packSlice';
import { getLinkFromPackToDetail } from '@/utils/link';

export const PackModalItem = React.memo(({ item, hasEdit, togglePack }) => {
  const dispatch = useDispatch();
  const { id, image, name, type } = item;

  const ItemWrapper = hasEdit ? Link : ({ children, ...props }) => <div {...props}>{children}</div>;

  const handleRemove = React.useCallback(() => dispatch(packActions.removePackItem({ type, id })), [dispatch, id, type]);

  return (
    <div className="pt-4">
      <div className="relative">
        <ItemWrapper onClick={togglePack} to={getLinkFromPackToDetail({ type, id })} className={clsx('row items-center justify-between', hasEdit && 'hover:bg-primary-a1')}>
          <div className="row items-center space-x-6">
            <div className="h-20 w-20 bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url('${image}')` }} />
            <p className="font-body-l text-black">{name}</p>
          </div>
        </ItemWrapper>
        {hasEdit && (
          <span className="absolute right-2 top-1/2 translate-y-[-50%]">
            <IconButton onClick={handleRemove} size="md">
              <MinusCircle />
            </IconButton>
          </span>
        )}
      </div>
    </div>
  );
});

PackModalItem.displayName = 'PackModalItem';
