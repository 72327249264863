import { useRoutes } from 'react-router-dom';

import { usePrefetchCategories } from '@/features/category';
import { useAuth } from '@/libs/auth';

import { authMainRoutes, mainRoutes, protectedMainRoutes } from './main';

export const AppRoutes = () => {
  const auth = useAuth();

  usePrefetchCategories();

  const routes = auth.user ? protectedMainRoutes : authMainRoutes;

  const element = useRoutes([{ ...mainRoutes, children: [...mainRoutes.children, ...routes] }]);

  return <>{element}</>;
};
