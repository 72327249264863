import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ListFilter, SearchResultNotFound } from '@/components/Elements';
import { FilterSearch } from '@/components/Elements/FilterSearch/FilterSearch';
import { RoutePath } from '@/components/Elements/RoutePath';
import { Head } from '@/components/Head';
import { categoryTypes } from '@/constants/categoryTypes';
import { useCompany, CompanyItem } from '@/features/company';
import { useFilterName } from '@/hooks/useFilterName';
import { useSearchState } from '@/hooks/useSearchState';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';

import { useInfiniteCompanies } from '../api/useInfiniteCompanies';
import { CompanyFilter } from '../components/CompanyFilter';

const RenderCompanyItem = () => {
  return (
    <div className=" animate-pulse rounded">
      <div className="home-image-image-container bg-slate-300" />
      <div className="mt-4">
        <div className={`h-${5} mb-2 w-2/3 rounded bg-slate-200`} />
        <div className={`h-${3} w-1/2 rounded bg-slate-200`} />
      </div>
    </div>
  );
};
export const Companies = () => {
  const { isLg } = useWindowDimensions();
  const { name, debouncedName, setName } = useFilterName();
  const { searchParams, featuredSearchParams } = useSearchState(debouncedName);
  searchParams.set('searchValue', debouncedName);
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteCompanies(searchParams);
  const { data: featured, isLoading: isFeaturedLoading } = useCompany(featuredSearchParams);
  const featuredData = [1, 2, 3];

  const standardData = [1, 2, 3, 4, 5, 6];
  return (
    <div>
      <Head title="Байгууллага" description="Байгууллага хайх" />
      <>
        <div className="w-full bg-primary">
          <div className="layout layout-space py-10">
            <RoutePath type={categoryTypes.company} categoryId={searchParams.get('category')} />
          </div>
        </div>
        <div className="mymd:row layout layout-space h-full pt-20 mymd:space-x-8">
          <ListFilter>
            <CompanyFilter />
          </ListFilter>

          <div className="flex flex-1 flex-col items-start">
            <FilterSearch name={name} setName={setName} />
            {isFeaturedLoading ? (
              <div className="mt-10 w-full md:mt-20">
                <div className=" animate-pulse rounded">
                  <div className="mb-6 h-8 w-24 bg-slate-300" />
                </div>
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
                  {featuredData?.slice(0, isLg ? 4 : 3)?.map((id) => (
                    <RenderCompanyItem key={`featured-skeleton-${id}`} />
                  ))}
                </div>
              </div>
            ) : (
              <div className="w-full">
                {featured?.companies?.length > 0 && (
                  <div className="mt-10 w-full md:mt-20">
                    <p className="font-title-l mb-6">Онцгой</p>
                    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
                      {featured?.companies?.slice(0, isLg ? 4 : 3)?.map((company) => (
                        <CompanyItem key={`featured-${company?.companyId}`} value={company} />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            {isLoading && !data?.pages && (
              <div className="mt-10 w-full md:mt-20">
                <div className=" animate-pulse rounded">
                  <div className="mb-6 h-8 w-24 bg-slate-300" />
                </div>
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
                  {standardData.slice(0, isLg ? 4 : 6).map((i, index) => (
                    <RenderCompanyItem key={index + 'standardLoading' + i} />
                  ))}
                </div>
              </div>
            )}
            <div className="mt-10 w-full md:mt-20">
              <p className="font-title-l mb-6 text-zinc-700">Cтандарт</p>
              <InfiniteScroll
                className="space-y-2 md:space-y-5"
                dataLength={data?.pages?.length || 0} //This is important field to render the next data
                next={fetchNextPage}
                hasMore={hasNextPage}
                loader={
                  <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
                    {standardData.slice(0, isLg ? 4 : 6).map((i, index) => (
                      <RenderCompanyItem key={index + 'standardLoading' + i} />
                    ))}
                  </div>
                }>
                {data?.pages?.map((page, i) => (
                  <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3" key={i}>
                    {page?.companies?.map((company) => (
                      <CompanyItem key={company?.companyId} value={company} />
                    ))}
                  </div>
                ))}
              </InfiniteScroll>
            </div>
            {!isLoading && data?.pages[0]?.companies?.length === 0 && <SearchResultNotFound />}
          </div>
        </div>
      </>
    </div>
  );
};
