import { useMutation, useQueryClient } from 'react-query';

import { orderKeys } from '@/features/order';
import { axios } from '@/libs/axios';

const rateItem = ({ rateItem, values }) => {
  const { orderId, productOptionId, executorId, companyId } = rateItem;
  return axios.post('/customer/rating', {
    rating: {
      productOptionId,
      orderId,
      executorId,
      companyId,
      ...values,
      rating: ((values.rating || 0) / 20).toFixed(0)
    }
  });
};

export const useMutateRating = () => {
  const queryClient = useQueryClient();
  return useMutation(rateItem, {
    onSuccess: (_, variables) => {
      const { orderId } = variables.rateItem;
      queryClient.invalidateQueries(orderKeys.detail(orderId));
    }
  });
};
