import { identity, pickBy } from 'lodash';
import { useQuery } from 'react-query';

import { filterKeys } from '@/features/filter';
import { axios, decodeUrl } from '@/libs/axios';

const fetchFilter = (filter) => {
  const cleanedObject = pickBy(filter, identity);
  const searchParams = new URLSearchParams({ ...cleanedObject });
  return axios.get(`v1/shop/product/filter?${decodeUrl(searchParams.toString())}`);
};

export const useProductFilter = (filter, opt) => {
  return useQuery(filterKeys.product(filter), () => fetchFilter(filter), opt);
};
