import clsx from 'clsx';

import { BigNews } from '@/components/News/BigNews';
import { NewsItem } from '@/components/News/NewsItem';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';

const NORMAL_ARTICLE_START_POS = 1;

export const HomeNewsContainer = ({ articles = [], isLoading }) => {
  const { isSm, isMd, isMyMd } = useWindowDimensions();
  const LAST_ARTICLE_INDEX = !isSm && (isMd || isMyMd) ? NORMAL_ARTICLE_START_POS + 2 : NORMAL_ARTICLE_START_POS + 3;

  if (articles?.length === 0) {
    return null;
  }

  return (
    <div className="layout-space  py-20 odd:bg-white even:bg-light-layout ">
      {isLoading && (
        <div className="layout space-y-20">
          <div className="grid grid-cols-1 place-items-center gap-7 md:grid-cols-2">
            <div className="news-image animate-pulse bg-slate-200" />
            <div className="w-full ">
              <div className="mb-4 h-5 w-40 animate-pulse rounded bg-slate-200" />
              <div className="mb-1 h-9 w-full animate-pulse rounded bg-slate-200" />
              <div className="mb-14 h-9 w-1/3 animate-pulse rounded bg-slate-200" />
              <div className="h-5 w-40 animate-pulse rounded  bg-slate-200" />
            </div>
          </div>
          <hr className="bg-slate-200" />
          <div className={clsx('grid grid-cols-1 gap-x-7 md:grid-cols-2 lg:grid-cols-3')}>
            {[1, 2, 3].map((sn) => (
              <div className="py-4 md:py-0" key={`news-skeleton-${sn}`}>
                <div className="image-container-company mb-6 animate-pulse bg-slate-200" />
                <div className="mb-2 h-5 w-1/3 animate-pulse rounded bg-slate-200" />
                <div className="w-fill mb-10 h-7 animate-pulse rounded rounded bg-slate-200" />
                <div className="h-5 w-1/2 animate-pulse rounded bg-slate-200" />
              </div>
            ))}
          </div>
        </div>
      )}
      {isLoading || (
        <div className="layout space-y-20">
          <BigNews item={articles[0]} />
          <div className={clsx('grid grid-cols-1 gap-x-7 md:grid-cols-2 lg:grid-cols-3')}>
            {articles?.slice(NORMAL_ARTICLE_START_POS, LAST_ARTICLE_INDEX).map((article) => (
              <NewsItem key={`home-article-${article.articleId}`} item={article} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
