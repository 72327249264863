import { isFunction } from 'lodash';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@/libs/auth';
import { menuActions } from '@/store/menuSlice';

export const useCheckUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useAuth();

  const isUserLoggedIn = useMemo(() => Boolean(user?.customerId), [user?.customerId]);
  const isUserSubscription = useMemo(() => Boolean(user?.subscription), [user?.subscription]);
  const isUserSubscriptionValid = useMemo(() => Boolean(user?.isSubscription), [user?.isSubscription]);

  const handleOnClickLoggedInRequired = useMemo(
    () => (callback, preCallback) => () => {
      if (isFunction(preCallback)) preCallback();

      if (!isUserLoggedIn) {
        return dispatch(menuActions.toggleLogin());
      }
      callback();
    },
    [dispatch, isUserLoggedIn]
  );

  const handleOnClickSubscriptionRequired = useMemo(
    () => (callback, preCallback) => () => {
      if (isFunction(preCallback)) preCallback();

      if (!isUserLoggedIn) {
        return dispatch(menuActions.toggleLogin());
      }

      if (!isUserSubscriptionValid) {
        return navigate('/pricing');
      }

      callback();
    },
    [dispatch, isUserLoggedIn, isUserSubscriptionValid, navigate]
  );

  return {
    isUserLoggedIn,
    isUserSubscription,
    isUserSubscriptionValid,
    handleOnClickLoggedInRequired,
    handleOnClickSubscriptionRequired
  };
};
