import { memo, useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Button } from '@/components/Elements';
import { menuActions } from '@/store/menuSlice';
import { packActions, packSelector } from '@/store/packSlice';

export const usePack = () => {
  const dispatch = useDispatch();

  const selectPackItem = useCallback(
    ({ item, type }) => {
      batch(() => {
        dispatch(packActions.selectPackItem({ item: { ...item, type } }));
        dispatch(menuActions.togglePackOrder());
      });
    },
    [dispatch]
  );

  const removePackItem = useCallback(
    ({ item, type }) => {
      dispatch(packActions.removePackItem({ type, id: item.id }));
      toast.success('Амжилттай багцнаас хасагдлаа.');
    },
    [dispatch]
  );

  const PackButton = memo(function PackButton({ item, type }) {
    const isInPack = useSelector((state) => packSelector.hasIdInPack(state, item.id));
    return isInPack ? (
      <Button onClick={() => removePackItem({ type, item })} color="support" variant="outlined">
        Багцаас хасах
      </Button>
    ) : (
      <Button onClick={() => selectPackItem({ type, item })} color="support" variant="filled">
        Багцад нэмэх
      </Button>
    );
  });

  return { PackButton };
};
