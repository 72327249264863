import { Helmet } from 'react-helmet-async';

export const Head = ({ title = '', description = '', image = '', width, height }) => {
  return (
    <Helmet defaultTitle="Models.mn">
      <title>{title ? `${title} | Models.mn` : 'Models.mn'}</title>
      <meta name="description" content={description} />
      <meta property="og:url" content="https://models.mn/" />
      <meta property="og:site_name" content="Models.mn - web site created by Heli Solutions LLC" />

      <meta property="og:title" content={title ? `${title} | Models.mn` : 'Models.mn'} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content={`${width}px`} />
      <meta property="og:image:height" content={`${height}px`} />
    </Helmet>
  );
};
