import { format } from 'date-fns';
import { isDate } from 'lodash/lang';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', '11 сар', 'December'];

export const formatMonth = (date) => {
  const d = new Date(date);
  let year = d.getFullYear();
  let month = months[d.getMonth() + 1];
  let day = d.getDay();
  return `${year} ${month} ${day}`;
};
export const slashFormat = (date) => {
  const d = new Date(date);
  let year = d.getFullYear();
  let day = d.getDate();
  let mount = d.getMonth() + 1;
  let formatMount = mount?.toString()?.length > 1 ? mount : '0' + mount;
  return `${year} / ${formatMount} / ${day}`;
};
export const periodFormat = (date) => {
  let d = new Date();
  if (date) {
    d = new Date(date);
  }
  return format(d, 'yyyy.MM.dd');
};
export const yearFormat = (date) => {
  const d = new Date(date);
  return format(d, 'yyyy');
};
