import { useQuery } from 'react-query';

import { axios } from '@/libs/axios';

import { workKeys } from './workKeys';

const fetchWorkDetail = (id) => axios.get(`v1/work/${id}`);

export const useWorkDetails = (id, enabled) => {
  return useQuery(workKeys.detail(id), () => fetchWorkDetail(id), { enabled: enabled && !!id });
};
