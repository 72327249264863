import React from 'react';
import { useLocation } from 'react-router-dom';

export const useSearchState = () => {
  const location = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  const featuredSearchParams = React.useMemo(() => new URLSearchParams(), []);
  featuredSearchParams.set('category', searchParams.get('category'));
  featuredSearchParams.set('featured', '1');
  featuredSearchParams.set('isRandom', '1');
  featuredSearchParams.set('perPage', '4');

  const workSearchParam = React.useMemo(() => new URLSearchParams(), []);
  featuredSearchParams.set('perPage', '6');
  featuredSearchParams.set('page', '1');

  const awardSearchParam = React.useMemo(() => new URLSearchParams(), []);
  awardSearchParam.set('perPage', '6');
  awardSearchParam.set('page', '1');

  const newsSearchParam = React.useMemo(() => new URLSearchParams(), []);
  awardSearchParam.set('perPage', '4');
  awardSearchParam.set('page', '1');
  return { searchParams, featuredSearchParams, workSearchParam, awardSearchParam, newsSearchParam };
};
