import React, { memo } from 'react';
import { MinusCircle } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, IconButton } from '@/components/Elements';
import { ServiceRating } from '@/components/ServiceRating';
import { categoryTypes } from '@/constants/categoryTypes';
import { menuActions } from '@/store/menuSlice';
import { packActions } from '@/store/packSlice';

export const PeopleItem = memo(({ value, toggleSearch = () => null, isShowRemovePack, rating, isShowReview, orderId }) => {
  const dispatch = useDispatch();
  const { executorId, lastname, firstname, categories, image, avatarPath, avatarOriginalName } = value;

  const handleRemove = React.useCallback(() => dispatch(packActions.removePackItem({ type: categoryTypes.person, id: executorId })), [executorId, dispatch]);

  const toggleReview = React.useCallback(() => dispatch(menuActions.toggleReview({ reviewItem: { orderId, ...value } })), [dispatch, orderId, value]);

  return (
    <div className="relative">
      <Link to={`/people/${executorId}`} onClick={toggleSearch}>
        <div className="group text-left">
          <div className="people-image-container relative mb-2">
            <div className="people-image-container absolute top-0 bg-transparent  duration-1000 hover:bg-light-medium " />
            <img className="duration-300 hover:scale-105" src={avatarOriginalName || image || avatarPath} alt={`${lastname} ${firstname}`} />
          </div>
          <div className="relative">
            <p className="font-body-lg duration-800 truncate font-semibold  text-secondary-high group-hover:text-support	">
              {lastname}. {firstname?.charAt(0).toUpperCase() + firstname.slice(1, firstname.length).toLowerCase()}
            </p>
            <p className="font-body-xs text-secondary-medium">{categories[0]?.name || 'Хувь хүн'}</p>
          </div>
        </div>
      </Link>

      {(isShowReview || !!rating) && (
        <div className="mt-4">
          {rating && <ServiceRating rating={rating} />}

          {isShowReview && !rating && (
            <Button onClick={toggleReview} variant="outlined" className="w-full">
              Үнэлгээ өгөх
            </Button>
          )}
        </div>
      )}

      {isShowRemovePack && (
        <IconButton className="absolute right-0 bottom-3" size="md" onClick={handleRemove}>
          <MinusCircle size={20} />
        </IconButton>
      )}
    </div>
  );
});

PeopleItem.displayName = 'PeopleItem';
