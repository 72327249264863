import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '@/components/Elements';
import { PasswordInput } from '@/components/Form';
import { getError } from '@/libs/react-hook-form';

import { useUpdatePassword } from '../api';

const schema = yup.object({
  currentPassword: yup.string().password(),
  plainPassword: yup.string().password(),
  confirm_password: yup.string().oneOf([yup.ref('plainPassword'), null], 'Шинэ нууц үг таарахгүй байна')
});

export const Password = () => {
  const { isLoading, mutate } = useUpdatePassword();

  const {
    reset,
    formState: { errors },
    control,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      plainPassword: '',
      confirm_password: '',
      currentPassword: ''
    }
  });

  const onSubmit = (values) => {
    delete values.confirm_password;
    mutate(values);
    reset({ plainPassword: '', confirm_password: '', currentPassword: '' });
  };

  return (
    <div className="pt-5">
      <h1 className="mb-6 text-2xl">Нууц үгээ солих</h1>
      <div className="space-y-6">
        <Controller
          name="currentPassword"
          control={control}
          render={({ field }) => <PasswordInput error={getError(errors, 'currentPassword')} field={field} label="Хүүчин нууц үг" placeholder="Хүүчин нууц үг" />}
        />
        <Controller
          name="plainPassword"
          control={control}
          render={({ field }) => <PasswordInput error={getError(errors, 'plainPassword')} field={field} label="Шинэ нууц үг" placeholder="Шинэ нууц үг" />}
        />
        <Controller
          name="confirm_password"
          control={control}
          render={({ field }) => (
            <PasswordInput error={getError(errors, 'confirm_password')} field={field} label="Шинэ нууц үг баталгаажуулах" placeholder="Шинэ нууц үг баталгаажуулах" />
          )}
        />
        <div className="py-4">
          <Button onClick={handleSubmit(onSubmit)} isLoading={isLoading} variant="filled" className="w-full" text="Хадаглах" />
        </div>
      </div>
    </div>
  );
};
