import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Elements';
import { Checkbox, Input, PasswordInput } from '@/components/Form';
import { useAuth } from '@/libs/auth';
import { getError } from '@/libs/react-hook-form';
import { yup } from '@/libs/yup';

import AuthContainer, { authTypes } from '../components/AuthContainer';

const schema = yup.object({
  email: yup.string().email('И-мэйлээ зөв оруулна уу.').required('И-мэйлээ оруулна уу.'),
  lastname: yup.string().required('Овгоо  оруулна уу.'),
  phone: yup.string().length(8, 'Утасны дугаараа зөв уу.').required('Утасны дугаараа оруулна уу.'),
  firstname: yup.string().required('Нэрээ оруулна уу.'),
  password: yup.string().password(),
  agreement1: yup.boolean().required('Үйлчилгээний нөхцөл заавал зөвшөөрөх естэй.').oneOf([true], 'Үйлчилгээний нөхцөл заавал зөвшөөрөх естэй.'),
  confirm_password: yup.string().oneOf([yup.ref('password'), null], 'Шинэ нууц үг таарахгүй байна'),
  isCompany: yup.boolean(),
  companyName: yup.string().when('isCompany', {
    is: true,
    then: yup.string().required('Байгууллагын нэрээ оруулна уу.')
  }),
  companyPosition: yup.string().when('isCompany', {
    is: true,
    then: yup.string().required('Албан тушаалаа оруулна уу.')
  }),
  companyRegister: yup.string().when('isCompany', {
    is: true,
    then: yup.string().required('Байгууллагын регистрийн дугаараа оруулна уу.')
  })
});

export const Register = () => {
  const { register, isRegistering } = useAuth();
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setFocus,
    watch
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
      lastname: '',
      firstname: '',
      phone: '',
      confirm_password: '',
      isCompany: false,
      companyName: '',
      companyPosition: '',
      companyRegister: '',
      agreement1: false,
      agreement2: false
    }
  });

  const onSubmit = async (values) => {
    await register(values);
    navigate('/auth/register/confirmation');
  };

  useEffect(() => {
    setFocus('email');
  }, [navigate, setFocus]);

  const isCompany = watch('isCompany');

  return (
    <AuthContainer type={authTypes.register}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6 bg-white p-5 shadow md:p-20">
          <h1 className="font-headline-s md:font-headline-m text-primary md:pb-4">Бүртгэл</h1>

          <Controller
            name="email"
            control={control}
            render={({ field }) => <Input error={getError(errors, 'email')} field={field} label="И-мэйл хаяг" placeholder="И-мэйл хаяг" />}
          />
          <Controller name="phone" control={control} render={({ field }) => <Input label="Утас" error={getError(errors, 'phone')} field={field} placeholder="Утас" />} />
          <div className="row space-x-6">
            <Controller
              name="lastname"
              control={control}
              render={({ field }) => <Input containerStyle="flex-1" label="Овог" error={getError(errors, 'lastname')} field={field} placeholder="Овог" />}
            />
            <Controller
              name="firstname"
              control={control}
              render={({ field }) => <Input containerStyle="flex-1" label="Нэр" error={getError(errors, 'firstname')} field={field} placeholder="Нэр" />}
            />
          </div>
          <Controller
            name="password"
            control={control}
            render={({ field }) => <PasswordInput label="Нууц үг" error={getError(errors, 'password')} field={field} placeholder="Нууц үгээ оруулна уу" />}
          />
          <Controller
            name="confirm_password"
            control={control}
            render={({ field }) => <PasswordInput label="Нууц үг давтах" error={getError(errors, 'confirm_password')} field={field} placeholder="Нууц үгээ дахин оруулна уу" />}
          />
          {isCompany && (
            <>
              <h2 className="font-headline-s md:font-headline-m text-primary md:py-4">Байгууллагын мэдээлэл</h2>
              <Controller
                name="companyName"
                control={control}
                render={({ field }) => <Input label="Байгууллагын нэр" error={getError(errors, 'companyName')} field={field} placeholder="Байгууллагын нэр" />}
              />
              <Controller
                name="companyPosition"
                control={control}
                render={({ field }) => <Input label="Албан тушаал" error={getError(errors, 'companyPosition')} field={field} placeholder="Таны албан тушаал" />}
              />
              <Controller
                name="companyRegister"
                control={control}
                render={({ field }) => (
                  <Input label="Байгууллагын регистрийн дугаар" error={getError(errors, 'companyRegister')} field={field} placeholder="Байгууллагын регистрийн дугаар" />
                )}
              />
            </>
          )}
          <Controller label="Албан байгууллага" name="isCompany" control={control} render={({ field }) => <Checkbox field={field} text="Албан байгууллага" />} />

          <Controller
            name="agreement1"
            control={control}
            render={({ field }) => <Checkbox error={getError(errors, 'agreement1')} field={field} text="Үйлчилгээний нөхцөл зөвшөөрөх" />}
          />
          <Controller name="agreement2" control={control} render={({ field }) => <Checkbox field={field} text="Мэдээлэл и-мейлээр авах" />} />
          <Button isLoading={isRegistering} type="submit" variant="filled" size="lg" text="БҮРТГҮҮЛЭХ" />
        </div>
      </form>
    </AuthContainer>
  );
};
