import { useQuery } from 'react-query';

import { axios } from '@/libs/axios';

import { newsKeys } from '../api/newsKeys';

const fetchNewsDetail = (id) => axios.get(`/v1/article/${id}`);

export const useNewsDetail = (id, opt) => {
  return useQuery(newsKeys.detail(id), () => fetchNewsDetail(id), opt);
};
