import { Companies } from './Companies';
import { CompanyDetail } from './CompanyDetail';

export const companyRoutes = {
  path: '/company',
  children: [
    { index: true, element: <Companies /> },
    { path: '/company/:agencyId', element: <CompanyDetail /> }
  ]
};
