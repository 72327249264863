import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RouteHomePath } from '@/components/Elements/RoutePath';
import { orderTypes } from '@/constants/order';
import { useOrder } from '@/features/order';
import { PackItem, PackItemSkeleton } from '@/features/pack';
import { packSelector } from '@/store/packSlice';
import { createSkeletonArray } from '@/utils/skeleton';

export const PackList = () => {
  const searchParams = useMemo(() => new URLSearchParams({ type: orderTypes.service, perPage: '100', sort: 'createdAt', direction: 'asc' }), []);

  const newPackOrder = useSelector(packSelector.getNewPackOrder);

  const { data, isLoading } = useOrder(searchParams);

  const displayOrders = [newPackOrder, ...(data?.orders ?? [])];

  return (
    <>
      <div className="bg-primary">
        <div className="layout layout-space py-10">
          <RouteHomePath name="Миний багцууд" />
        </div>
      </div>

      <div className="layout layout-space grid grid-cols-1 gap-7 py-10 md:grid-cols-3 md:py-20 lg:grid-cols-4">
        {isLoading && createSkeletonArray(4).map((key) => <PackItemSkeleton key={key} />)}
        {isLoading || displayOrders.map((order, index) => <PackItem key={`pack.item.${order.orderId}`} order={order} name={index === 0 ? 'Одоогийн багц' : `Багц ${index}`} />)}
      </div>
    </>
  );
};
