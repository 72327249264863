import { ArrowRight } from 'react-feather';
import { Link } from 'react-router-dom';

import { getLinkToList } from '@/utils/link';

export const MenuItem = ({ headerItem, toggleMenu }) => {
  return (
    <div>
      <Link to={getLinkToList(headerItem)}>
        <button onClick={toggleMenu} className="row w-full justify-between px-6 py-4 text-secondary-high">
          <span className="font-label-l font-bold">{headerItem.name}</span>

          <ArrowRight size={24} />
        </button>
      </Link>
    </div>
  );
};
