import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { CheckSquare, Square, Star } from 'react-feather';
import { useLocation, useNavigate } from 'react-router-dom';

import { FilterName } from '@/components/Elements/FilterName';
import { decodeUrl } from '@/libs/axios';
import { TugCurrency } from '@/libs/react-number-format';

const checkIsActive = (item, searchParams, type) => {
  if (type === 'age') {
    const age = searchParams.getAll('age[]');
    return age?.length > 0 && age.indexOf(`${item.ageFrom}_${item.ageTo}`) !== -1;
  } else if (type === 'gender') {
    const gender = searchParams.getAll('genderMulti[]');
    return gender?.length > 0 && gender.indexOf(item.gender) !== -1;
  } else if (type === 'price') {
    const prices = searchParams.getAll('basePriceMulti[]');
    return prices?.length > 0 && prices.indexOf(`${item.from}_${item?.to || ''}`) !== -1;
  } else if (type === 'rating') {
    const rating = searchParams.getAll('ratingMulti[]');
    return rating?.length > 0 && rating.indexOf(item.value?.toString()) !== -1;
  }
};

export const FilterMenuItem = ({ className, item, type = 'age' }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const { name, from, to, value } = item;
  const isActive = checkIsActive(item, searchParams, type);

  const onClick = () => {
    const { pathname } = location;
    const newSearchParams = new URLSearchParams({ category: searchParams.get('category') });
    let age = searchParams.getAll('age[]');
    let gender = searchParams.getAll('genderMulti[]');
    let prices = searchParams.getAll('basePriceMulti[]');
    let rating = searchParams.getAll('ratingMulti[]');
    if (type === 'age') {
      if (isActive) {
        age = age.filter((ageItem) => ageItem !== `${item.ageFrom}_${item.ageTo}`);
      } else {
        age = [...age, `${item.ageFrom}_${item.ageTo}`];
      }
    } else if (type === 'gender') {
      if (isActive) {
        gender = gender.filter((genderItem) => genderItem !== item.gender);
      } else {
        gender = [...gender, item.gender];
      }
    } else if (type === 'price') {
      if (isActive) {
        prices = prices.filter((priceItem) => priceItem !== `${item.from}_${item?.to || ''}`);
      } else {
        prices = [...prices, `${item.from}_${item?.to || ''}`];
      }
    } else if (type === 'rating') {
      if (isActive) {
        rating = rating.filter((ratingItem) => ratingItem?.toString() !== item.value?.toString());
      } else {
        rating = [...rating, item.value];
      }
    }
    age?.map((item) => newSearchParams.append('age[]', item));
    gender?.map((item) => newSearchParams.append('genderMulti[]', item));
    rating?.map((item) => newSearchParams.append('ratingMulti[]', item));
    prices?.map((item) => newSearchParams.append('basePriceMulti[]', item));
    navigate(`${pathname}?${decodeUrl(newSearchParams.toString())}`);
  };

  const checkBox = isActive ? <CheckSquare className="h-6 w-6" color="#E93323" /> : <Square className="h-6 w-6" color="#707070" />;
  const displayName =
    type === 'price' ? (
      <div className="flex">
        {from ? (
          <div className="flex">
            <div className="pr-1">{!to && ' + '}</div>
            <TugCurrency className={''} value={from} />
            <div className="px-1">{to && '-'}</div>
          </div>
        ) : (
          <div className="flex">
            <TugCurrency className={''} value={0} />
            <div className="px-1">-</div>
          </div>
        )}
        {to ? <TugCurrency className={''} value={to} /> : ''}
      </div>
    ) : type === 'rating' ? (
      <div className="flex justify-between">
        <p>{`${value} одтой`}</p>
        <div className="flex justify-end">
          {Array.from(Array(value), (e, i) => {
            return <Star fill={'#FFC043'} className="h-13 w-13 px-1 text-yellow" key={`star-${i}`} />;
          })}
          {Array.from(Array(5 - value), (e, i) => {
            return <Star className="h-13 w-13 px-1 text-secondary-disabled" key={`not-star-${i}`} />;
          })}
        </div>
      </div>
    ) : (
      name
    );
  return (
    <button
      onClick={onClick}
      className={clsx(
        className,
        'my-[2px] flex w-full items-center rounded-md px-4 py-3 font-medium hover:text-support',
        isActive ? 'hover:bg-[#f6f6f6]' : 'hover:bg-[#eeeeee]',
        isActive ? 'text-support shadow' : 'text-secondary-medium',
        isActive && 'bg-white'
      )}>
      {type !== 'rating' && checkBox} <div className={clsx('font-label-l w-full text-left', type !== 'rating' && 'ml-4')}>{displayName}</div>
    </button>
  );
};

export const FilterMenu = ({ item, type }) => {
  const { name, children: items } = item;

  return (
    <ul className="relative rounded-md bg-[#E6E6E6] px-2 pt-10 pb-2 text-zinc-700">
      <FilterName name={name} />
      <AnimatePresence initial={false}>
        {Array.isArray(items) ? (
          <li>
            <motion.ul
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 }
              }}
              transition={{ duration: 0.35, ease: [0.04, 0.62, 0.23, 0.98] }}>
              {items.map((currentItem, index) => {
                return (
                  <FilterMenuItem
                    isLast={index === items?.length - 1}
                    key={`${type + currentItem?.name}${currentItem.slug}`}
                    item={currentItem}
                    type={type}
                    className={clsx('py-4')}
                  />
                );
              })}
            </motion.ul>
          </li>
        ) : null}
      </AnimatePresence>
    </ul>
  );
};
