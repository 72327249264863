import { configureStore } from '@reduxjs/toolkit';

import { authSliceReducer } from '@/features/auth';
import { cartSliceReducer } from '@/store/cartSlice';
import { menuSliceReducer } from '@/store/menuSlice';
import { packSliceReducer } from '@/store/packSlice';
import { searchSliceReducer } from '@/store/searchSlice';

export const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    search: searchSliceReducer,
    cart: cartSliceReducer,
    menu: menuSliceReducer,
    pack: packSliceReducer
  }
});
