import { createAsyncThunk, createSelector, createSlice, nanoid } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { toast } from 'react-toastify';

import { categoryTypes } from '@/constants/categoryTypes';
import { menuActions } from '@/store/menuSlice';
import { getTotalCartItemsPrice, getTotalDiscountCartItemsPrice } from '@/utils/products';
import storage from '@/utils/storage';

const initialState = {
  items: []
};

export const addToCart = createAsyncThunk('models/add-to-cart', ({ item }, { getState, dispatch, rejectWithValue }) => {
  const state = getState().cart;

  if (item) {
    const foundItemIdx = state.items.findIndex((product) => product.id === item.id);
    if (foundItemIdx !== -1) {
      const newQty = state.items[foundItemIdx].qty + item.qty;
      if (item.remainingCount >= newQty) {
        batch(() => {
          dispatch(cartActions.updateQuantity({ id: item.id, value: item.qty }));
          dispatch(menuActions.toggleCart());
        });
      } else {
        const { name, size, color } = item;
        toast.error(`${name} (${size}/${color})-н үлдэгдэл хүрэлцэхгүй байна.`);
      }
      return rejectWithValue();
    } else {
      dispatch(menuActions.toggleCart());
      return { item };
    }
  }
});

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartItems: (state, action) => {
      const { items } = action.payload;
      state.items = items;
    },
    updateQuantity: (state, action) => {
      const { id, value } = action.payload;
      const item = state.items.find((item) => item.id === id);
      if (item) {
        const newQty = item.qty + value;
        if (item.remainingCount >= newQty && newQty > 0) {
          item.qty = newQty;
        }
      }
      storage.setCartItems(state.items);
    },
    removeItem: (state, action) => {
      const { id } = action.payload;
      state.items = state.items.filter((item) => item.id !== id);
      storage.setCartItems(state.items);
    },
    resetCart: (state) => {
      state.items = [];
      storage.setCartItems([]);
    }
  },
  extraReducers: {
    [addToCart.fulfilled]: (state, action) => {
      const { item } = action.payload;
      state.items = [item, ...state.items];
      storage.setCartItems(state.items);
    }
  }
});

export const cartActions = cartSlice.actions;

export const cartSliceReducer = cartSlice.reducer;

export const cartSelector = {
  cartItemCount: createSelector([(state) => state.cart], (cart) => Number(cart?.items?.length || 0)),
  getNewCommerceOrder: createSelector([(state) => state.cart], (order) => {
    const totalPrice = getTotalCartItemsPrice(order.items);
    const totalDiscountPrice = getTotalDiscountCartItemsPrice(order.items);
    return {
      orderId: nanoid(),
      productOptions: order.items,
      totalDiscountAmount: totalDiscountPrice,
      totalFullAmount: totalPrice,
      totalAmount: (totalPrice - totalDiscountPrice).toFixed(0),
      isNew: true
    };
  })
};
