import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  registerValue: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {}
});

export const authActions = authSlice.actions;

export const authSliceReducer = authSlice.reducer;
