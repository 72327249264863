import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@/components/Elements';
import { getLinkToList } from '@/utils/link';

import { RecommendationItem } from './RecommendationItem';
import { RecommendationProductItem } from './RecommendationProductItem';

const perChunk = 4; // items per chunk

export const CategoryHoverContent = ({ isCommerce, isShow, handleMouseLeave, categories, recommendation, parentCategory }) => {
  const result = categories.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  if (result) {
    result[0].push(parentCategory);
  }

  const recommendationData = JSON.parse(recommendation);

  const recommendationNum = result.length >= 4 ? 0 : 4 - result.length;

  return (
    <Transition show={isShow}>
      <div className="absolute left-0 right-0 w-full overflow-hidden border-b border-b-border bg-primary-high">
        <div className="mx-auto max-w-screen-xl p-5 py-12">
          <div className="grid grid-cols-4 gap-x-12 overflow-hidden">
            {isCommerce || (
              <>
                {result.map((categoryArray) => {
                  const key = categoryArray.map((category) => category.categoryId).join('-');
                  return (
                    <div className="flex flex-col space-y-4" key={key}>
                      {categoryArray.map((category) => (
                        <Link key={category.categoryId} to={getLinkToList(category)}>
                          <button onClick={handleMouseLeave}>
                            <span className={clsx('font-label-l', category.isParent ? 'mt-2 text-support' : 'text-secondary-high')}>{category.name}</span>
                          </button>
                        </Link>
                      ))}
                    </div>
                  );
                })}
                {recommendationData.splice(0, recommendationNum).map((item) => (
                  <RecommendationItem close={handleMouseLeave} key={`recommendation-item-${item.executorId || item.companyId || item.productId}`} item={item} />
                ))}
              </>
            )}
            {isCommerce && (
              <>
                {recommendationData.splice(0, 4).map((product) => (
                  <RecommendationProductItem key={`recommendation-item-${product.executorId || product.companyId || product.productId}`} item={product} close={handleMouseLeave} />
                ))}
              </>
            )}
          </div>
          {isCommerce && (
            <div className="center mt-14">
              <Link to="/shop">
                <Button onClick={handleMouseLeave} variant="filled">
                  Бүгдийг харах
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </Transition>
  );
};
