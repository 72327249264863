import clsx from 'clsx';
import React from 'react';

export const authTypes = {
  init: '@authTypes.init',
  login: '@authTypes.login',
  register: '@authTypes.register',
  verify: '@authTypes.verify',
  passwordResetRequest: '@authTypes.passwordResetRequest',
  passwordResetRequestSent: '@authTypes.passwordResetRequestSent',
  passwordReset: '@authTypes.passwordReset'
};

// const authTypeText = {
//   [authTypes.login]: {
//     title: 'Нэвтрэх',
//     description: 'Нууц үгээ оруулаад нэвтэрнэ үү.'
//   },
//   [authTypes.register]: {
//     title: 'Бүртгэл үүсгэх',
//     description: 'Өөрийн мэдээллээ оруулна уу.'
//   },
//   [authTypes.verify]: {
//     title: 'Create an account',
//     description: 'Бүртгэл үүсгэх хаяг: '
//   },
//   [authTypes.passwordResetRequest]: {
//     title: 'Нууц үгээ сэргээх',
//     description: 'доорх емайлруу нууц үгээ сэргээх линк илгээх.'
//   },
//   [authTypes.passwordResetRequestSent]: {
//     title: 'Нууц үгээ сэргээх',
//     description: 'нууц үгээ сэргээх линк илгээсэн емайлээ шалгана уу'
//   },
//   [authTypes.passwordReset]: {
//     title: 'Нууц үгээ сэргээх',
//     description: 'шинэ нууц үгээ оруулна уу'
//   }
// };

const AuthContainer = ({ children, className }) => {
  return (
    <div className={clsx(className, 'flex w-full flex-col items-center justify-center py-20 px-6')}>
      <div className="w-full md:w-[40rem]">
        <div className="">{children}</div>
      </div>
    </div>
  );
};

export default AuthContainer;
