import clsx from 'clsx';

import { Spinner } from '@/components/Elements';

const variants = {
  primary: 'primary',
  outlined: 'outlined',
  filled: 'filled'
};

const colors = {
  primary: 'primary',
  support: 'support'
};

const getButtonStyle = (variant, color, disabled) => {
  let buttonStyle = 'py-2 px-6 rounded-sm font-label-l h-10 box-border';

  if (disabled) {
    buttonStyle = clsx(buttonStyle, 'cursor-not-allowed');
    switch (color) {
      case colors.primary:
        buttonStyle = clsx(buttonStyle, 'text-secondary-disabled');
        break;
      case colors.support:
        buttonStyle = clsx(buttonStyle, 'text-support-disabled');
        break;
    }
  } else {
    buttonStyle = clsx(buttonStyle, 'cursor-pointer');
  }

  switch (variant) {
    case variants.primary:
    case variants.outlined:
      switch (color) {
        case colors.primary:
          buttonStyle = clsx(buttonStyle, 'text-primary hover:bg-primary-a1 active:bg-primary-a2 focus:bg-primary-a2');
          break;
        case colors.support:
          buttonStyle = clsx(buttonStyle, 'text-support hover:bg-support-a1 active:bg-support-a2 focus:bg-support-a2');
          break;
      }
      if (variant === variants.outlined) {
        switch (color) {
          case colors.primary:
            buttonStyle = clsx(buttonStyle, 'border', disabled ? 'border-primary-disabled' : 'border-primary');
            break;
          case colors.support:
            buttonStyle = clsx(buttonStyle, 'border', disabled ? 'border-support-disabled' : 'border-support');
            break;
        }
      }
      break;
    case variants.filled:
      switch (color) {
        case colors.primary:
          buttonStyle = clsx(buttonStyle, disabled ? 'bg-primary-disabled' : 'bg-primary text-primary-high hover:opacity-[.92] active:opacity-[.88]	focus:opacity-[.88]');
          break;
        case colors.support:
          buttonStyle = clsx(buttonStyle, disabled ? 'bg-support-disabled' : 'bg-support text-support-high hover:opacity-[.92] active:opacity-[.88]	focus:opacity-[.88]');
          break;
      }
      break;
  }

  return buttonStyle;
};

export const Button = ({ children, text, variant = variants.primary, color = colors.primary, onClick, isLoading, disabled, startIcon, endIcon, className, ...props }) => {
  const buttonStyle = getButtonStyle(variant, color, disabled);
  return (
    <button onClick={onClick} disabled={isLoading || disabled} className={clsx('flex items-center justify-center text-sm', buttonStyle, className)} {...props}>
      {isLoading && (
        <div className="center flex">
          <Spinner size="sm" variant={variant === 'filled' ? 'light' : 'primary'} />
        </div>
      )}
      {!isLoading && (
        <span className="row items-center space-x-2">
          {startIcon}
          {text || children}
          {endIcon}
        </span>
      )}
    </button>
  );
};
