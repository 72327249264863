import { orderStatus } from '@/constants/order';

export const NEW_PACK = 'new-pack';

export const getStatusInfo = (status) => {
  if (!status) {
    return {
      text: 'Шинэ багц',
      bgColor: 'bg-yellow',
      textColorDetail: 'text-yellow',
      textColor: 'text-primary'
    };
  }
  switch (status) {
    case orderStatus.initiated:
      return {
        text: 'Хүлээгдэж буй',
        bgColor: 'bg-support-medium',
        textColorDetail: 'text-support-medium',
        textColor: 'text-primary'
      };
    case orderStatus.ongoing:
      return {
        text: 'Явагдаж байгаа',
        bgColor: 'bg-light-disabled',
        textColorDetail: 'text-light-disabled',
        textColor: 'text-primary'
      };
    case orderStatus.completed:
      return {
        text: 'Дууссан',
        bgColor: 'bg-green',
        textColorDetail: 'text-green',
        textColor: 'text-primary-high'
      };
    case orderStatus.cancelled:
      return {
        text: 'Цуцалсан',
        bgColor: 'bg-red',
        textColorDetail: 'text-red',
        textColor: 'text-primary-high'
      };
  }
};
