import { Star } from 'react-feather';

export const ServiceRating = ({ rating }) => {
  return (
    <div className="row center h-10 space-x-2 border border-border">
      {Array.from(Array(5).keys()).map((i) => (
        <Star key={`rating.${rating.ratingId}.${i}`} color={i < rating.rating ? '#FFC043' : '#B7B7B7'} fill={i < rating.rating ? '#FFC043' : '#B7B7B7'} size={20} />
      ))}
    </div>
  );
};
