import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import SwiperCore, { FreeMode, Mousewheel, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useWindowDimensions } from '@/hooks/useWindowDimensions';
import { classNames } from '@/utils/classNames';

import { ChooseImage } from '../components/ChooseImage';

ChooseImage.displayName = 'SwiperSlide';
SwiperCore.use([FreeMode, Mousewheel, Navigation, Thumbs]);

export const ShowProductImages = ({ photos = [] }) => {
  const swiperRef = useRef(null);
  const { isSm } = useWindowDimensions();
  const initialIndex = 1;
  return (
    <div className="md:grid md:grid-cols-5 md:gap-7 lg:grid-cols-8">
      <div className="hidden md:block">
        {photos?.map((image, index) => (
          <button onClick={() => swiperRef.current.swiper.slideTo(index)} key={`side-${image.photoId}`}>
            <div className="image-container">
              <img src={image.originalName} alt={'as'} />
            </div>
          </button>
        ))}
      </div>
      <div className={clsx(isSm && 'mobile-product-image', 'md:col-span-4 lg:col-span-7')}>
        <Swiper ref={swiperRef} modules={[FreeMode, Navigation, Thumbs]} initialSlide={initialIndex} className="" direction={'horizontal'}>
          {photos.map((image) => (
            <SwiperSlide key={`product-controller-${image.photoId}`}>
              <div className="image-container ">
                <img src={image.originalName} alt={image.photoId} className="m-auto" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
