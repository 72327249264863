import clsx from 'clsx';
import React from 'react';

import { SearchResultNotFound } from '@/components/Elements';
import { WorkItem } from '@/components/WorkItem';
import { categoryTypes } from '@/constants/categoryTypes';
import { useWorks } from '@/features/work';
import { useFilterName } from '@/hooks/useFilterName';
import { useSearchState } from '@/hooks/useSearchState';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';
import { useAuth } from '@/libs/auth';

export const WorkContainer = ({ id, type, name }) => {
  const { user } = useAuth();
  const { isSm } = useWindowDimensions();
  const isSubscription = user && user?.isSubscription;
  const isLogin = user;
  const { debouncedName } = useFilterName();
  const { workSearchParam } = useSearchState(debouncedName);
  if (type === categoryTypes.person) workSearchParam.set('executor', id);
  if (type === categoryTypes.company) workSearchParam.set('company', id);
  const { data, isLoading } = useWorks(workSearchParam);
  const { works } = data || {};
  const tempWorks = [{ workId: 'temp1' }, { workId: 'temp2' }, { workId: 'temp3' }];
  if (!isLogin) {
    return (
      <div className="center w-full">
        <div className={clsx('opacityBackground  rounded p-10 text-center', isSm ? 'w-full p-1' : 'w-1/2')}>
          <span className="font-body-l mr-1 text-support">Нэвтрэх</span>
          <span className="font-body-l mr-1">эсвэл</span>
          <span className="font-body-l mr-1 text-support">Бүртгүүлээд</span>
          <span className="font-body-l ">{`“${name}”-йин талаар илүү дэлгэрэнгүй мэдээллийг авна уу.`}</span>
        </div>
      </div>
    );
  }
  if (!isSubscription) {
    return (
      <div className="center w-full ">
        <div className={clsx('opacityBackground  rounded p-10 text-center', isSm ? 'w-full p-2' : 'w-1/2')}>
          <span className="font-body-l mr-1 text-support">Pro</span>
          <span className="font-body-l text-center">{`хэрэглэгч болж “${name}”-йин талаар илүү дэлгэрэнгүй мэдээллийг авна уу.`}</span>
        </div>
      </div>
    );
  }
  if (!isLoading && works?.length === 0) {
    return <SearchResultNotFound />;
  }
  return isLoading ? (
    <div className=" grid grid-cols-1 gap-4 px-3 md:grid-cols-3 md:gap-8">
      {tempWorks?.map((w) => (
        <WorkItem work={w} isLoading={true} key={`work-${w?.workId}`} />
      ))}
    </div>
  ) : (
    <div className=" grid grid-cols-1 gap-4 px-3 md:grid-cols-3 md:gap-8">
      {works?.map((w) => (
        <WorkItem work={w} isLoading={false} key={`work-${w?.workId}`} />
      ))}
    </div>
  );
};
