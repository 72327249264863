import { nanoid } from '@reduxjs/toolkit';
import clsx from 'clsx';
import * as React from 'react';
import { Plus } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CompanyBigSwiper } from '@/components/Company';
import { Button } from '@/components/Elements';
import { RouteHomePath } from '@/components/Elements/RoutePath';
import { PeopleSwiper } from '@/components/People';
import { orderStatus } from '@/constants/order';
import { useOrderDetail } from '@/features/order';
import { packSelector } from '@/store/packSlice';

import { PackConfirmationModal } from '../components';
import { getStatusInfo, NEW_PACK } from '../constants';

const getButtonText = (status) => {
  if (!status) {
    return 'Захиалга хийх';
  }
  // switch (status) {
  //   case orderStatus.ongoing:
  //     return 'Дуусгах';
  // }
};

export const PackDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { orderId } = params;

  const isNewPack = orderId === NEW_PACK;
  const isFetchOrder = !isNewPack;

  const { data, isLoading } = useOrderDetail(orderId, isFetchOrder);

  const newPackOrder = useSelector(packSelector.getNewPackOrder);
  const hasPackItem = useSelector(packSelector.hasPackItem);

  const displayOrder = React.useMemo(() => {
    if (isFetchOrder) {
      return data ?? {};
    } else if (isNewPack) {
      return newPackOrder;
    }
  }, [data, isFetchOrder, isNewPack, newPackOrder]);

  React.useEffect(() => {
    if (isFetchOrder && !isLoading && !displayOrder) navigate('/404');
  }, [displayOrder, isFetchOrder, isLoading, navigate]);

  const statusInfo = getStatusInfo(displayOrder.status);
  const buttonText = getButtonText(displayOrder.status);
  const isCompleted = displayOrder.status === orderStatus.completed;

  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handlePackDetailButtonOnClick = () => {
    if (!displayOrder.status) {
      if (hasPackItem) {
        handleOpen();
      } else {
        toast.error('Таны багц хоосон байна. Та багцдаа хувь хүн, байгуулга нэмнэ үү.');
      }
    }
  };

  const ratings = displayOrder?.ratings ?? [];

  return (
    <>
      <PackConfirmationModal isOpen={isOpen} close={handleClose} />
      <div className="bg-primary">
        <div className="layout layout-space relative py-10">
          <RouteHomePath name="Миний багц" paths={[{ key: nanoid(), to: '/pack', name: 'Багц' }]} />

          <div className="mt-2">
            <p className={clsx('font-label-m', statusInfo.textColorDetail)}>{statusInfo.text}</p>
          </div>

          {buttonText && (
            <Button
              onClick={handlePackDetailButtonOnClick}
              className="mt-6 w-full md:absolute md:right-5 md:top-1/2 md:mt-0 md:w-auto md:translate-y-[-50%]"
              color="support"
              variant="filled">
              {buttonText}
            </Button>
          )}
        </div>
      </div>

      <div className="super-swiper mt-14 space-y-10 divide-y divide-dashed divide-light-disabled overflow-hidden py-14 md:space-y-16 ">
        <div className="layout px-5">
          <div className="row mb-7 justify-between">
            <p className="font-title-l md:font-headline-s">Хувь хүн</p>

            {isNewPack && (
              <Link to="/people" className="hidden md:block">
                <Button color="support" startIcon={<Plus size={24} className="mr-2" />}>
                  Шинэ хувь хүн нэмэх
                </Button>
              </Link>
            )}
          </div>

          <PeopleSwiper
            ratings={ratings}
            isShowButton={false}
            data={displayOrder.executors}
            isShowRemovePack={isNewPack}
            isShowReview={isCompleted}
            orderId={orderId}
            isLoading={isLoading}
          />

          <div className="center mt-10 block flex md:hidden">
            {isNewPack && (
              <Link to="/people">
                <Button color="support" variant="filled" startIcon={<Plus size={24} className="mr-2" />}>
                  Шинэ хувь хүн нэмэх
                </Button>
              </Link>
            )}
          </div>
        </div>

        <div className="layout px-5 pt-10 md:pt-16">
          <div className="row mb-7 justify-between">
            <p className="font-title-l md:font-headline-s">Байгууллага</p>

            {isNewPack && (
              <Link to="/company" className="hidden md:block">
                <Button color="support" startIcon={<Plus size={24} className="mr-2" />}>
                  Шинэ байгуулга нэмэх
                </Button>
              </Link>
            )}
          </div>

          <CompanyBigSwiper ratings={ratings} data={displayOrder.companies} isShowRemovePack={isNewPack} isShowReview={isCompleted} orderId={orderId} />

          <div className="center mt-10 block flex md:hidden">
            {isNewPack && (
              <Link to="/company">
                <Button color="support" variant="filled" startIcon={<Plus size={24} className="mr-2" />}>
                  Шинэ байгуулга нэмэх
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
