import { yupResolver } from '@hookform/resolvers/yup';
import { isUndefined } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Button, Logo } from '@/components/Elements';
import { Input } from '@/components/Form';
import { useMutatePasswordResetRequest } from '@/features/auth';
import { getError } from '@/libs/react-hook-form';
import { yup } from '@/libs/yup';

const schema = yup.object({ email: yup.string().email('И-мэйлээ зөв оруулна уу.').required('И-мэйлээ оруулна уу.') });

export const PasswordResetForm = ({ toggleLogin, togglePasswordReset }) => {
  const { isLoading, mutateAsync } = useMutatePasswordResetRequest();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setFocus,
    reset,
    watch
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { email: '' }
  });

  const handleCloseLogin = () => {
    reset();
    toggleLogin();
  };

  const onSubmit = async (values) => {
    await mutateAsync(values.email);
    toast.success('Таны бүртгэлтэй и-мэйл хаяг руу нүүц үг сэргээх заавар илгээсэн. Та и-мэйлээ шалгана уу.');
    handleCloseLogin();
  };

  const email = watch('email');

  useEffect(() => {
    if (!isUndefined(email)) {
      setFocus('email');
    }
  }, [email, setFocus]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row justify-center">
        <Logo />
      </div>

      <Controller
        name="email"
        control={control}
        render={({ field }) => <Input containerStyle="mt-12" error={getError(errors, 'email')} field={field} label="И-мэйл хаяг" placeholder="Мэйл хаягаа оруулна уу" />}
      />

      <div className="mt-4 flex justify-end">
        <button onClick={togglePasswordReset} type="button">
          <p className="font-label-l text-primary hover:text-primary-medium">Нэвтрэх</p>
        </button>
      </div>
      <Button isLoading={isLoading} type="submit" variant="filled" className="mt-4 w-full" text="Нууц үг сэргээх" />
    </form>
  );
};
