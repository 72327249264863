import React from 'react';

import { CompanySwiper } from '@/components/Company';
import { PeopleSwiper } from '@/components/People';
import { ProductSwiper } from '@/components/Product/ProductSwiper';
import { swiperTypes } from '@/constants/swiperTypes';

export const SuperSwiper = ({ category }) => {
  const { data, type } = category || {};
  const isPerson = type === swiperTypes.PERSON;
  const isCompany = type === swiperTypes.COMPANY;
  const RenderSwiper = () => {
    return isPerson ? <PeopleSwiper data={data} /> : isCompany ? <CompanySwiper data={data} /> : <ProductSwiper data={data} />;
  };

  return <RenderSwiper />;
};
