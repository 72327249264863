import React, { useRef } from 'react';
import { Search, X } from 'react-feather';

export const FilterSearch = ({ name, setName }) => {
  const inputRef = useRef();

  return (
    <div aria-hidden="true" onClick={() => inputRef.current.focus()} className="row w-full cursor-pointer items-center rounded border border-slate-200 bg-white px-5 py-2">
      <Search className="mr-3 h-5 w-5" />
      <input value={name} onChange={(e) => setName(e.target.value)} ref={inputRef} placeholder="Хайх" className="flex-1 flex-grow bg-inherit focus:outline-none focus:ring-0" />
      <button
        onClick={(e) => {
          e.stopPropagation();
          setName('');
        }}>
        <X className="mr-3 h-5 w-5" />
      </button>
    </div>
  );
};
