import { Link } from 'react-router-dom';

export const WorkerItem = ({ value, toggleSearch = () => null }) => {
  const { name, role, description, photoPath } = value || {};

  return (
    <Link to={'#'} onClick={toggleSearch}>
      <div className="group text-left">
        <div className="worker-image-container relative mb-2">
          <img className="w-full" src={photoPath} alt={`${name}`} />
        </div>
        <div className={'flex items-start items-center	'}>
          <p className="font-body-xs mr-2 text-secondary-medium ">{role}: </p>
          <p className="font-body-lg duration-800 truncate  text-secondary-high group-hover:text-support	">
            {name?.charAt(0).toUpperCase() + name.slice(1, name?.length).toLowerCase()}
          </p>
        </div>
        <p className={'font-body-xs mr-2 text-secondary-medium '}>{description}</p>
      </div>
    </Link>
  );
};
