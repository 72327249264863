import { useQuery } from 'react-query';

import { axios } from '@/libs/axios';

import { companyKeys } from './companyKeys';

const fetchAgencyDetail = (id) => axios.get(`/v1/company/${id}`);

export const useCompanyDetail = (id, opt) => {
  return useQuery(companyKeys.detail(id), () => fetchAgencyDetail(id), opt);
};
