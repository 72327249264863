import { nanoid } from '@reduxjs/toolkit';
import React from 'react';
import { Link } from 'react-router-dom';

const LinkElement = React.memo(({ item }) => (
  <Link to={item.to} className="pb- ml-1 space-x-4 pb-4 text-xs font-medium text-primary-medium">
    <span>{item.name}</span>
    <span>/</span>
  </Link>
));
LinkElement.displayName = 'LinkElement';

export const RouteHomePath = ({ name, paths = [] }) => {
  const displayPath = [
    {
      key: nanoid(),
      to: '/',
      name: 'Нүүр хуудас'
    },
    ...paths
  ];

  return (
    <>
      <div className="flex space-x-4">
        {displayPath.map((item) => (
          <LinkElement key={item.key} item={item} />
        ))}
      </div>

      <p className="font-headline-l text-primary-high">{name}</p>
    </>
  );
};
