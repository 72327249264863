import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CartItem } from '@/components/CartItem';
import { Button } from '@/components/Elements';
import { Head } from '@/components/Head';
import { OrderProductModal } from '@/components/Layout/MainLayout/components/OrderProductModal';
import { orderKeys } from '@/features/order';
import { useCheckUser } from '@/hooks/useCheckUser';
import { TugCurrency } from '@/libs/react-number-format';
import { cartActions } from '@/store/cartSlice';
import { menuActions } from '@/store/menuSlice';
import { getTotalCartItemsPrice, getTotalDiscountCartItemsPrice } from '@/utils/products';

import { OrderSuccessModal } from '../components';

export const Cart = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let [isOpen, setIsOpen] = useState(false);
  const { isOrderOpen } = useSelector((store) => store.menu);
  const { isUserLoggedIn } = useCheckUser();
  const { item } = location.state || {};
  const toggleOrder = () => dispatch(menuActions.toggleOrder());
  const reduxItems = useSelector((store) => store.cart.items);

  const items = item ? [item] : reduxItems;
  const totalPrice = getTotalCartItemsPrice(items);
  const totalDiscountPrice = getTotalDiscountCartItemsPrice(items);

  const hasItems = items?.length > 0;

  const handleCheckout = async () => {
    if (isUserLoggedIn) {
      toggleOrder();
    } else {
      toast.warn('Та захиалгаа өгөхийн тулд нэвтэрсэн байх хэрэгтэй.');
      dispatch(menuActions.toggleLogin());
    }
  };

  const handleResetCart = () => {
    navigate('/cart', { state: null });
    dispatch(cartActions.resetCart());
  };

  const closeSuccessModal = () => {
    setIsOpen(false);
    navigate('/shop');
    queryClient.invalidateQueries(orderKeys.all);
    dispatch(cartActions.resetCart());
  };

  return (
    <div className="border-b border-white md:mb-28 md:border-b-0">
      <OrderSuccessModal isOpen={isOpen} closeModal={closeSuccessModal} />
      <OrderProductModal isCartOpen={isOrderOpen} toggleCart={toggleOrder} callback={() => setIsOpen(true)} items={items} />
      <div className="mb-6 w-full bg-primary md:mb-20">
        <div className="layout layout-space">
          <div className="flex space-x-4 ">
            <Link to={'/'} className="pb- ml-1 space-x-4 pb-4 text-xs font-medium text-primary-medium">
              <span>Нүүр хуудас</span>
              <span>/</span>
            </Link>
          </div>
          <p className="font-headline-l text-primary-high">Сагс</p>
        </div>
      </div>
      <Head title="Сагс" description="Сагс" />
      <div className="md:layout md:layout-space">
        <div className="grid md:grid-cols-5 md:gap-7">
          <div className="md:col-span-3">
            {hasItems || (
              <div className="my-16 flex w-full flex-col items-center">
                <h2 className="text-2xl text-zinc-700">Таны Сагс хоосон байна.</h2>
                <img className="h-2/3 w-1/3" src={require('@/assets/emptyCart.png')} alt="Карт хоосон байна." />
              </div>
            )}
            {hasItems && (
              <div className=" divide-y divide-dashed  md:bg-white md:py-0 md:px-8 lg:py-2 lg:px-10">
                {items.map((product) => (
                  <CartItem key={product.id} item={product} localRemove={item && handleResetCart} />
                ))}
              </div>
            )}
          </div>
          <div className="md:col-span-2 ">
            <div className={'bg-primary px-6 py-7 '}>
              <div className="row mb-6 justify-between">
                <p className="font-label-m text-primary-medium">Нийт бараа</p>
                <TugCurrency value={totalPrice} className="font-title-m text-white" />
              </div>

              <div className="row  mb-6  justify-between">
                <p className="font-label-m text-primary-medium">Хямдрал</p>
                <TugCurrency value={totalDiscountPrice} className="font-title-m text-white" />
              </div>

              <div className="row mb-6 justify-between text-xl">
                <p className="font-label-m text-primary-medium">Нийт үнэ</p>
                <TugCurrency value={totalPrice} className="font-title-m text-white" />
              </div>

              <div className="mb-5 flex w-full justify-center space-x-4 ">
                <Button onClick={handleCheckout} variant="outlined" text="Захиалах" className="flex-1 bg-white" color={'white'} />
                <Button variant="outlined" className="flex-1 border-white text-white" onClick={handleResetCart} text="Багц хоослох" color={'primary'} />
              </div>
              <p className="font-label-m text-center text-primary-medium">{`Хэрвээ та багц хоосолсон тохиолдолд таны \nбүр бүтээгдэхүүн устах болно`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
