import { useInfiniteQuery } from 'react-query';

import { peopleKeys } from '@/features/people';
import { productsKeys } from '@/features/shop';
import { axios, decodeUrl } from '@/libs/axios';
import { getNextPageParam } from '@/libs/react-query';

const PER_PAGE = '6';
const fetchProducts = (pageParam, searchParams) => {
  searchParams.set('page', pageParam);
  searchParams.set('perPage', PER_PAGE);
  return axios.get(`/v1/shop/product?${decodeUrl(searchParams.toString())}`);
};

export const useInfiniteProducts = (searchParams, opt) => {
  return useInfiniteQuery(productsKeys.infiniteFilter(searchParams.toString()), ({ pageParam = 1 }) => fetchProducts(pageParam, searchParams), {
    getNextPageParam: (lastPage, allPages) => getNextPageParam(lastPage, allPages, PER_PAGE),
    ...opt
    // enabled: searchParams.includes('searchValue')
  });
};
