import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { orderTypes } from '@/constants/order';
import { axios } from '@/libs/axios';
import { packActions } from '@/store/packSlice';

import { orderKeys } from '../api/orderKeys';

const getId = (item) => item.id;

const customerOrderService = ({ people = [], companies = [] }) => {
  return axios.post(`/customer/order`, {
    order: {
      paid: 0,
      status: 'initiated',
      type: orderTypes.service,
      executorIds: people.map(getId),
      companyIds: companies.map(getId)
    }
  });
};

export const useMutateOrderService = () => {
  const dispatch = useDispatch();
  const queryClient = new useQueryClient();

  return useMutation(({ people, companies }) => customerOrderService({ people, companies }), {
    onSuccess: () => {
      dispatch(packActions.resetPack());
      queryClient.invalidateQueries(orderKeys.all);
      toast.success('Таны захиалга амжилтай бүрэгдлээ.');
    }
  });
};
