import React from 'react';
import { Link } from 'react-router-dom';

export const CompanyItem = ({ value, toggleSearch = () => null }) => {
  const { name, companyId, avatarPath, avatarPathSecond, category, avatarOriginalName } = value;
  const { name: categoryName } = category || {};
  return (
    <Link to={`/company/${companyId}`} onClick={toggleSearch}>
      <div className="group">
        <div className="home-image-image-container relative mb-4 rounded bg-white bg-cover bg-center">
          <div className="home-image-image-container absolute top-0 bg-transparent  duration-1000 hover:bg-light-medium" />
          <img className="w-full duration-300 hover:scale-105" src={avatarOriginalName || avatarPath || avatarPathSecond} alt={`${name}`} />
        </div>
        <p className="center font-body-lg duration-800 truncate font-semibold  text-secondary-high group-hover:text-support">{name}</p>
        <p className="center font-title-s text-secondary-medium">{categoryName}</p>
      </div>
    </Link>
  );
};
