import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { accountRoutes } from '@/features/account';
import { authRoutes } from '@/features/auth';
import { awardRoutes } from '@/features/awards';
import { Cart } from '@/features/cart';
import { companyRoutes } from '@/features/company';
import { Home } from '@/features/home';
import { FaQ, NotFound, Privacy, Terms } from '@/features/misc';
import { newsRoutes } from '@/features/news';
import { orderRoutes } from '@/features/order';
import { packRoutes } from '@/features/pack';
import { peopleRoutes } from '@/features/people';
import { Pricing } from '@/features/pricing';
import { shopRoutes } from '@/features/shop';
import { worksRoutes } from '@/features/work';

export const App = ({ hasFooter, hasLayout }) => {
  return (
    <MainLayout hasFooter={hasFooter} hasLayout={hasLayout}>
      <Suspense
        fallback={
          <div className="flex h-full w-full items-center justify-center">
            <Spinner size="xl" />
          </div>
        }>
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const mainRoutes = {
  path: '/',
  breadcrumb: 'Нүүр',
  element: <App />,
  children: [
    {
      children: [
        { path: '/', element: <Home /> },
        { path: '/shop', children: shopRoutes },
        { path: '/works', children: worksRoutes },
        { path: '/award', children: awardRoutes },
        { path: '/faq', element: <FaQ /> },
        { path: '/privacy', element: <Privacy /> },
        { path: '/terms', element: <Terms /> },
        { path: '/cart', element: <Cart /> },
        { path: '/pricing', element: <Pricing /> },
        companyRoutes,
        { path: '/people', children: peopleRoutes },
        { path: '/news', children: newsRoutes },
        { path: '*', element: <NotFound /> }
      ]
    }
  ]
};

export const authMainRoutes = [{ path: '/auth', children: authRoutes }];

export const protectedMainRoutes = [accountRoutes, packRoutes, orderRoutes];
