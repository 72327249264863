import React, { useMemo } from 'react';

import { RouteHomePath } from '@/components/Elements/RoutePath';
import { Head } from '@/components/Head';
import { orderTypes } from '@/constants/order';
import { useOrder } from '@/features/order';
import { createSkeletonArray } from '@/utils/skeleton';

import { OrderItem } from '../components/OrderListItem';

const SkeletonOrderList = () => {
  return (
    <div className="animate-pulse overflow-hidden rounded border bg-white pt-4 ">
      <div className=" mb-2 flex items-center px-4">
        <div className="mr-2 h-2 w-2 rounded bg-secondary-disabled  " />
        <div className="my-[3px] h-3 w-1/5 rounded bg-secondary-disabled" />
      </div>
      <div className="py-2 px-4">
        <div className="flex justify-between ">
          <div className="my-[3px] h-3  w-1/4 rounded bg-secondary-disabled md:h-[0.875rem]" />
          <div className="my-[3px] h-3  w-1/4 rounded bg-secondary-disabled text-right md:h-[0.875rem]" />
        </div>
        <div className="flex justify-between">
          <div className="mr-2 h-[0.875rem] w-1/3 rounded bg-secondary-disabled text-primary md:mr-0 md:h-4" />
          <div className="h-[0.875rem] w-1/3 rounded  bg-secondary-disabled text-right text-primary md:h-4" />
        </div>
      </div>
      <div className={'flex items-center justify-between border-t-[1px]  bg-secondary-disabled px-4 py-4'}>
        <div className="h-[0.875rem] w-1/4 rounded bg-secondary-disabled" />
        <div className="h-4 w-1/5 rounded bg-secondary-disabled" />
      </div>
    </div>
  );
};
export const OrderList = () => {
  const searchParams = useMemo(() => new URLSearchParams({ type: orderTypes.commerce, perPage: '100' }), []);

  const { data, isLoading } = useOrder(searchParams);

  const displayOrders = data?.orders;
  return (
    <>
      <Head title="Миний захиалгууд" description="Миний захиалгууд" />
      <div className="bg-primary">
        <div className="layout layout-space py-10">
          <RouteHomePath name="Миний захиалгууд" />
        </div>
      </div>

      <div className="layout layout-space grid grid-cols-1 space-y-8  py-10 md:py-20">
        {!isLoading && displayOrders?.map((orderItem) => <OrderItem orderItem={orderItem} key={`order-list-${orderItem?.orderId}`} />)}
        {isLoading && createSkeletonArray(2).map((key) => <SkeletonOrderList key={`order-skeleton-list-${key}`} />)}
      </div>
    </>
  );
};
