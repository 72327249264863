import { OrderDetail } from './OrderDetail';
import { OrderList } from './OrderList';

export const orderRoutes = {
  path: '/orders',
  children: [
    { index: true, element: <OrderList /> },
    { path: '/orders/:orderId', element: <OrderDetail /> }
  ]
};
