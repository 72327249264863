import clsx from 'clsx';
import { Trash2 } from 'react-feather';
import { useDispatch } from 'react-redux';

import { Button } from '@/components/Elements';
import { QtyButton } from '@/components/Layout/MainLayout/components';
import { TugCurrency } from '@/libs/react-number-format';
import { cartActions } from '@/store/cartSlice';
import { getDiscountPrice } from '@/utils/products';

export const CartItem = ({ item, canChange = true, isModal, localRemove }) => {
  const dispatch = useDispatch();
  const removeItem = ({ id }) => {
    if (typeof localRemove === 'function') {
      localRemove();
    } else {
      dispatch(cartActions.removeItem({ id }));
    }
  };
  const updateQuantity = ({ id, value }) => dispatch(cartActions.updateQuantity({ id, value }));

  const { brandName } = item || {};
  const discountPrice = getDiscountPrice(item) || '';
  return (
    <div className={clsx('grid grid-cols-3 gap-6  px-4  md:grid-cols-6 md:px-0', isModal ? 'py-4' : 'py-8')}>
      <div className="image-container relative md:col-span-2 ">
        <img src={item?.image} alt={item?.name} />
      </div>

      <div className=" col-span-2  md:col-span-4  ">
        <div className="w-full flex-1">
          <p className="font-label-m mb-0.5 text-secondary-medium">{brandName}</p>
          <p className="font-title-m mb-4 text-primary">{item?.name}</p>
          <p className="font-label-m mb-4 text-primary"> Хэмжээ: {item?.size}</p>
          <p className="mb-4">
            {discountPrice > 0 || <TugCurrency value={item?.price} className="text-support" />}
            {discountPrice > 0 && (
              <>
                <TugCurrency className="mr-8 text-secondary-medium line-through" value={item?.price} />
                <TugCurrency value={discountPrice} className="text-support" />
              </>
            )}
          </p>

          <div className={clsx(!canChange ? 'hidden' : 'flex items-center justify-between ')}>
            <div className=" flex flex-row items-center  text-sm	">
              <QtyButton disabled={item?.qty === 1} onClick={() => updateQuantity({ id: item?.id, value: -1 })} type="minus" />
              <p className="w-12 border py-2 text-center">{item?.qty}</p>
              <QtyButton disabled={item?.qty === item?.remainingCount} onClick={() => updateQuantity({ id: item?.id, value: 1 })} type="plus" />
            </div>
            <Button onClick={() => removeItem({ id: item?.id })} startIcon={<Trash2 className="mr-2 h-6 w-6" />} text="Устгах" color={'primary'} variant="filled" />
          </div>
        </div>
      </div>
    </div>
  );
};
