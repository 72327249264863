import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Head } from '@/components/Head';
import { Tabs } from '@/components/Tabs';
import { TopDetail } from '@/components/TopDetail';
import { categoryTypes } from '@/constants/categoryTypes';
import { useWorks } from '@/features/work';

import { usePeopleDetail } from '../api/usePeopleDetail';

export const PersonDetail = () => {
  const navigate = useNavigate();
  let params = useParams();
  const { personId } = params;

  const { data, isLoading } = usePeopleDetail(personId, { enabled: !!personId });
  const { isLoading: isWorkLoading } = useWorks({ executor: personId, page: 1, perPage: 6 }, { enabled: !!personId });

  React.useEffect(() => {
    if (!isLoading && !data) navigate('/404');
  }, [data, isLoading, navigate]);

  console.log(data);

  if (isLoading || isWorkLoading) {
    return (
      <div className="layout-height">
        <Head title={'Хувь хүн'} description={'Дэлгэрэнгүй'} />
        <TopDetail type={categoryTypes.person} isLoading={isLoading} />
        <Tabs type={categoryTypes.person} isLoading={isLoading} isWorkLoading={isWorkLoading} />
      </div>
    );
  }

  return (
    <div className="layout-height">
      <Head title={data?.lastname + ' ' + data?.firstname} description={data?.description} image={data.avatarOriginalNameSecond} width={487} height={287} />
      <TopDetail detail={data} type={categoryTypes.person} />
      <Tabs type={categoryTypes.person} detail={data} isLoading={isLoading} isWorkLoading={isWorkLoading} />
    </div>
  );
};
