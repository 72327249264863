export const orderStatus = {
  initiated: 'initiated',
  ongoing: 'ongoing',
  completed: 'completed',
  cancelled: 'cancelled'
};

export const orderTypes = {
  service: 'service',
  commerce: 'commerce'
};
