import { useRef } from 'react';
import * as React from 'react';
import { FreeMode, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CompanyItem } from '@/features/company';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';

export const CompanyBigSwiper = ({ data = [], isShowRemovePack, ratings, isShowReview, orderId }) => {
  const findRating = React.useCallback((id) => ratings?.find((rating) => rating.executorId === id || rating.companyId === id), [ratings]);
  const { isSm, isMd } = useWindowDimensions();
  const slidePerView = isSm ? 1.5 : isMd ? 3.2 : 3.4;
  const swiperRef = useRef(null);

  return (
    <div className="relative">
      <Swiper ref={swiperRef} spaceBetween={30} slidesPerView={slidePerView} modules={[Navigation, FreeMode]}>
        {data?.map((item, i) => (
          <SwiperSlide key={i}>
            <CompanyItem value={item} review={findRating(item.companyId)} isShowRemovePack={isShowRemovePack} isShowReview={isShowReview} orderId={orderId} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
