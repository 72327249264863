import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { categoryTypes } from '@/constants/categoryTypes';
import { decodeUrl } from '@/libs/axios';

const categorySorts = {
  [categoryTypes.commerce]: [
    { name: 'Шинэ нь эхэндээ', sort: 'createdAt', direction: 'desc' },
    { name: 'Үнэ өсөхөөр', sort: 'basePrice', direction: 'asc' },
    { name: 'Үнэ буурхаар', sort: 'basePrice', direction: 'desc' }
  ],
  [categoryTypes.company]: [{ name: 'Шинэ нь эхэндээ', sort: 'createdAt', direction: 'desc' }],
  [categoryTypes.person]: [{ name: 'Шинэ нь эхэндээ', sort: 'createdAt', direction: 'desc' }]
};

const categoryTotalText = {
  [categoryTypes.commerce]: 'бүтээгдэхүүн',
  [categoryTypes.company]: 'байгуулга',
  [categoryTypes.person]: 'хувь хүн'
};

export const ListSort = ({ total, type }) => {
  const sorts = React.useMemo(() => categorySorts[type], [type]);
  const navigate = useNavigate();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), []);

  const sort = searchParams.get('sort');
  const [selectedSort, setSelectedSort] = useState({});

  const handleSortChange = (sort) => {
    setSelectedSort(sort);
    searchParams.delete('sort');
    searchParams.delete('direction');
    navigate(`${location.pathname}?${decodeUrl(searchParams.toString())}&sort=${sort.sort}&direction=${sort.direction}`);
  };
  return (
    <div className="row z-10 w-full items-center justify-between py-8">
      <p className="font-title-l sm:text-sm md:text-xl"></p>
      <div className="w-56">
        <Listbox onChange={handleSortChange} value={null}>
          <div className="relative text-zinc-800">
            <Listbox.Button className="focus-visible:ring-offset-orange-300 relative w-full cursor-default px-4 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2">
              <span className="block truncate">{selectedSort?.name || sorts[0].name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute my-2 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {sorts.map((sort, personIdx) => (
                  <Listbox.Option
                    key={personIdx}
                    className={({ active }) =>
                      `${active ? 'bg-zinc-300 text-zinc-800' : 'text-zinc-700'}
                          relative cursor-default select-none py-2 px-4`
                    }
                    value={sort}>
                    <span className="block truncate">{sort.name}</span>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </div>
  );
};
