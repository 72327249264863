import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Button } from '@/components/Elements';
import { AuthProvider } from '@/libs/auth';
import { queryClient } from '@/libs/react-query';
import { InitReduxData } from '@/providers/InitReduxData';
import ScrollToTop from '@/providers/ScrollToTop';
import { store } from '@/store';

import 'react-toastify/dist/ReactToastify.css';

const ErrorFallback = () => {
  return (
    <div className="text-red-500 flex h-screen w-screen flex-col items-center justify-center" role="alert">
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <Button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </Button>
    </div>
  );
};

export const AppProvider = ({ children }) => {
  return (
    <React.Suspense fallback={<div className="flex h-screen w-screen items-center justify-center">{/*<Spinner size="xl" />*/}</div>}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
                <InitReduxData>
                  <Router>
                    <ScrollToTop />
                    {children}
                  </Router>
                </InitReduxData>
              </AuthProvider>
            </QueryClientProvider>
          </Provider>
          <ToastContainer />
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
