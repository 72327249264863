import { createSelector, createSlice, nanoid } from '@reduxjs/toolkit';
import { isArray } from 'lodash';

import { categoryTypes } from '@/constants/categoryTypes';
import storage from '@/utils/storage';

const initialState = {
  [categoryTypes.person]: [],
  [categoryTypes.company]: [],
  selectedPackItem: null
};

export const packSlice = createSlice({
  name: 'pack',
  initialState,
  reducers: {
    selectPackItem: (state, action) => {
      state.selectedPackItem = action.payload.item;
    },
    removeSelectedPackItem: (state) => {
      state.selectedPackItem = null;
    },
    setPackItems: () => {
      return {
        ...initialState,
        ...storage.getPackItems()
      };
    },
    addPackItem: (state, action) => {
      const { type, item } = action.payload;
      const { id } = item;
      if (isArray(state[type])) {
        const index = state[type].findIndex((item) => item.id === id);
        if (index === -1) {
          state[type] = [item, ...state[type]];
          storage.setPackItems({
            [categoryTypes.person]: state[categoryTypes.person],
            [categoryTypes.company]: state[categoryTypes.company]
          });
        }
      }
    },
    removePackItem: (state, action) => {
      const { type, id } = action.payload;
      if (isArray(state?.[type])) {
        state[type] = state[type].filter((item) => item.id !== id);
        storage.setPackItems({
          [categoryTypes.person]: state[categoryTypes.person],
          [categoryTypes.company]: state[categoryTypes.company]
        });
      }
    },
    resetPack: () => {
      storage.setPackItems({
        [categoryTypes.person]: [],
        [categoryTypes.company]: []
      });
      return initialState;
    }
  }
});

export const packActions = packSlice.actions;

export const packSliceReducer = packSlice.reducer;

export const packSelector = {
  hasIdInPack: createSelector(
    [(state) => state.pack, (state, category) => category],
    // Output selector gets (`state, id)` as args
    (state, id) => {
      const executorIndex = state[categoryTypes.person].findIndex((executor) => executor.id === id);
      const companyIndex = state[categoryTypes.company].findIndex((company) => company.id === id);
      return executorIndex >= 0 || companyIndex >= 0;
    }
  ),
  getSelectedPackItem: createSelector([(state) => state.pack], (pack) => pack.selectedPackItem),
  hasPackItem: createSelector([(state) => state.pack], (pack) => pack[categoryTypes.person]?.length > 0 || pack[categoryTypes.company]?.length > 0),
  getItems: createSelector([(state) => state.pack], (pack) => ({
    [categoryTypes.person]: pack[categoryTypes.person],
    [categoryTypes.company]: pack[categoryTypes.company]
  })),
  getNewPackOrder: createSelector([(state) => state.pack], (pack) => ({
    orderId: nanoid(),
    executors: pack[categoryTypes.person],
    companies: pack[categoryTypes.company],
    executorIds: pack[categoryTypes.person],
    companyIds: pack[categoryTypes.company],
    isNew: true
  })),
  getPackLength: createSelector([(state) => state.pack], (pack) => Number(pack[categoryTypes.person]?.length || 0) + Number(pack[categoryTypes.company]?.length || 0))
};
