import { PackDetail } from './PackDetail';
import { PackList } from './PackList';

export const packRoutes = {
  path: '/pack',
  children: [
    { index: true, element: <PackList /> },
    { path: '/pack/:orderId', element: <PackDetail /> }
  ]
};
