import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Logo } from '@/components/Elements';
import { PasswordInput } from '@/components/Form';
import { getError } from '@/libs/react-hook-form';
import { yup } from '@/libs/yup';

import { useMutatePasswordReset } from '../api/useMutatePasswordReset';
import AuthContainer, { authTypes } from '../components/AuthContainer';

const schema = yup.object({
  password: yup.string().password(),
  password_confirmation: yup.string().oneOf([yup.ref('password'), null], 'Давтсан Нууц үг таарахгүй байна.')
});

export const PasswordReset = () => {
  const { isLoading, mutateAsync } = useMutatePasswordReset();

  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  const token = searchParams.get('token');

  const {
    formState: { errors },
    control,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { password: '', password_confirmation: '' }
  });

  const onSubmit = async ({ password }) => {
    await mutateAsync({ token, password });
    navigate('/');
  };

  useEffect(() => {
    if (!token) navigate('/');
  }, [token, navigate]);

  return (
    <AuthContainer type={authTypes.passwordReset}>
      <form onSubmit={handleSubmit(onSubmit)} className="mx-auto w-full max-w-xl	transform overflow-hidden rounded-sm bg-white p-6 text-left shadow-xl transition-all md:p-20">
        <div className="row justify-center">
          <Logo />
        </div>

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <PasswordInput containerStyle="mt-12" error={getError(errors, 'password')} field={field} label="Шинэ нууц үг" placeholder="Та шинэ нууц үгээ оруулна уу" />
          )}
        />

        <Controller
          name="password_confirmation"
          control={control}
          render={({ field }) => (
            <PasswordInput
              containerStyle="mt-6"
              error={getError(errors, 'password_confirmation')}
              field={field}
              label="Нууц үг баталгаажуулах"
              placeholder="Та нууц үгээ баталгаажуулна уу"
            />
          )}
        />

        <Button isLoading={isLoading} type="submit" variant="filled" className="mt-10 w-full" text="Нүүц үг солих" />
      </form>
    </AuthContainer>
  );
};
