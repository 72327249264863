export const PackItemSkeleton = () => {
  return (
    <div className="animate-pulse bg-white">
      <div className="center flex bg-secondary-disabled py-2">
        <div className="h-4 w-24 rounded bg-primary-disabled" />
      </div>
      <div className="p-4 py-8">
        <div className="mb-6 h-[1.75rem] w-24 rounded bg-primary-disabled" />

        <div className="mb-6 h-[1px] rounded bg-secondary-disabled" />

        <div className="row justify-between">
          <div className="h-5 w-14 rounded bg-primary-disabled" />

          <div className="h-5 w-4 rounded bg-primary-disabled" />
        </div>

        <div className="row mt-2 justify-between">
          <div className="h-5 w-14 rounded bg-primary-disabled" />

          <div className="h-5 w-4 rounded bg-primary-disabled" />
        </div>

        <div className="mt-10">
          <div className="h-10 w-full bg-secondary-disabled" />
        </div>
      </div>
    </div>
  );
};
