import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';

import { useHeader } from '@/features/home';

import { MenuItem } from './MenuItem';

export const MenuModal = ({ isMenuOpen, toggleMenu }) => {
  const { data } = useHeader();

  const headerData = data && JSON.parse(data);

  return (
    <Transition
      show={isMenuOpen}
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 -translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-1">
      <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto" onClose={toggleMenu}>
        <div className="fixed inset-0 z-50 h-screen w-screen overflow-hidden overflow-y-scroll bg-white">
          <button className="absolute top-8 right-5" onClick={toggleMenu}>
            <XIcon className="absolute top-3 right-5 h-6 w-6 text-black" />
          </button>

          <div className="divide-y pt-20">
            {headerData?.map((headerItem) => (
              <MenuItem key={`header-menu-item-${headerItem.categoryId}`} headerItem={headerItem} toggleMenu={toggleMenu} />
            ))}
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
