import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useCategories } from '@/features/category';

/*
  Ene code iig zaswar oruulaad aldaa gargsan hun uuruu zasah tus be careful
 */
const RoutePathItem = React.memo(({ item, isDetail = false }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const getLinkUrl = ({ categoryId, path }) => {
    if (path) {
      return '/';
    } else if (categoryId) {
      const { pathname } = location;
      let tempPathname = pathname;
      if (isDetail && pathname?.match('people')) {
        tempPathname = '/people';
      } else if (isDetail && pathname?.match('company')) {
        tempPathname = '/company';
      }
      searchParams.set('category', categoryId);
      return `${tempPathname}?${searchParams.toString()}`;
    } else {
      return '/';
    }
  };
  return (
    <Link to={getLinkUrl({ categoryId: item?.categoryId, path: item?.path })} className="pb- ml-1 space-x-4 pb-4 text-xs font-medium text-primary-medium">
      <span>{item?.name}</span>
      <span>/</span>
    </Link>
  );
});
RoutePathItem.displayName = 'RoutePathItem';

/*
  Ene code iig zaswar oruulaad aldaa gargsan hun uuruu zasah tus be careful
 */
export const RoutePath = ({ categoryId, type, isDetail = false }) => {
  const { data } = useCategories(type);

  const n = isDetail ? 0 : 1;

  let path = [];
  const category = findCategory({ children: [data] }, categoryId);

  path.push({ name: 'Нүүр хуудас', path: '/' });
  let displayPath = path.reverse();
  path.push(category);

  function findCategory(data, id) {
    if (categoryId === null) {
      return { name: data?.children[0]?.name, categoryId: data?.children[0]?.categoryId };
    }
    let category = null;
    if (data && data?.children)
      for (const d of data.children) {
        if (d?.categoryId === id) {
          category = d;
          break;
        }
        const childCategory = findCategory(d, id);
        if (childCategory !== null) {
          category = childCategory;
          path.push(d);
          break;
        }
      }
    return category;
  }

  return (
    <>
      <div className="flex space-x-4">
        {displayPath?.slice(0, displayPath?.length - n)?.map((item) => (
          <RoutePathItem isDetail={isDetail} key={`route-path-${item?.name}`} item={item} />
        ))}
      </div>
      {isDetail || <p className="font-headline-l text-primary-high">{displayPath[displayPath.length - 1]?.name ?? '/'}</p>}
    </>
  );
};
/*
  Ene code iig zaswar oruulaad aldaa gargsan hun uuruu zasah tus be careful
 */
