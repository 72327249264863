import { MinusIcon, PlusIcon } from '@heroicons/react/outline';

const block = 'block h-10 w-10   disabled:cursor-not-allowed';
const text = 'font-body-lg mb-2 text-primary ';
const icon = 'h-4 w-4 inline text-primary';
export const ProductCount = ({ remainingCount = 0, qty = 0, plus, minus }) => {
  return (
    <div className="mb-8">
      <p className={text}>Үлдэгдэл: {remainingCount}</p>
      <div className="flex flex-row items-center self-center text-center">
        <button disabled={qty === 1} className={block} onClick={minus}>
          <MinusIcon className={icon} />
        </button>
        <div className=" flex h-10 w-12 flex-col justify-center border border-primary-disabled">
          <p className=" text-base">{qty}</p>
        </div>
        <button disabled={remainingCount <= qty} className={block} onClick={plus}>
          <PlusIcon className={icon} />
        </button>
      </div>
    </div>
  );
};
