import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { X } from 'react-feather';

import { IconButton } from '@/components/Elements';
import { orderTypes } from '@/constants/order';
import { PackItem } from '@/features/pack';

export const RateRemainderModal = ({ orders, isRateRemainderOpen, close }) => {
  const serviceOrders = orders?.filter((order) => order.type === orderTypes.service);
  return (
    <Transition
      show={isRateRemainderOpen}
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 -translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-1">
      <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto" onClose={close}>
        <div className="min-h-screen text-center">
          <Transition.Child as={Fragment}>
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child as={Fragment}>
            <span className="relative inline-block w-full max-w-3xl transform rounded-lg rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
              <IconButton onClick={close} className="absolute top-[-3rem] right-0 focus:outline-none">
                <X color="white" />
              </IconButton>
              <p className="font-body-lg text-center text-primary">Танд үнэлгээ өгөөгүй дууссан захиалга байна та үнэлгээгээ өгнө үү.</p>

              <div className="mt-6 flex overflow-x-scroll">
                <div className="flex flex-nowrap space-x-3">
                  {serviceOrders?.map((order) => (
                    <div className="inline-block w-72 snap-center" key={`service.order.rate.remainder.${order.orderId}`}>
                      <PackItem order={order} name="" onClick={close} />
                    </div>
                  ))}
                </div>
              </div>
            </span>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
