import React, { useEffect, useState } from 'react';
import { ShoppingBag } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Elements';
import { TugCurrency } from '@/libs/react-number-format';
import { addToCart } from '@/store/cartSlice';
import { getDiscountPrice } from '@/utils/products';

import { ProductCount } from '../components/ProductCount';
import { useProductOptions } from '../hooks/useProductOptions';

import { ProductDisclosure } from './ProductDisclosure';
import { Select } from './Select';
import { ColorItem } from './SizeItem';

export const ProductController = ({ product = {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [qty, setQty] = useState(1);
  const { id, name, productOptions, material, description, categoryDetail, brandDetail, isColor } = product || {};
  const { avatarOriginalName, name: brandName } = brandDetail || {};
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState({});
  const { hasOptions, productSizes, normalizedOptions = {} } = useProductOptions(productOptions);
  useEffect(() => {
    if (hasOptions) {
      const firstSize = productSizes[0];
      setSelectedSize(firstSize);
      setSelectedProduct(normalizedOptions[firstSize][0]);
    }
  }, [hasOptions, normalizedOptions, productSizes]);
  if (!id) return null;

  const { price, remainingCount } = selectedProduct;

  const discountPrice = getDiscountPrice(selectedProduct);

  const handleSelectSize = (size) => {
    setSelectedSize(size);
    setSelectedProduct(normalizedOptions[size][0]);
    setQty(1);
  };

  const handleSelectColor = (product) => {
    setSelectedProduct(product);
    setQty(1);
  };

  const handleSelectColorText = (color) => {
    setSelectedProduct(normalizedOptions[selectedSize].find((p) => p.color === color));
    setQty(1);
  };

  const handleAddItemToCart = () => {
    const { size, colorHex, productOptionId } = selectedProduct;
    if (qty === 0) {
      return;
    }
    dispatch(
      addToCart({
        item: {
          id: productOptionId.id,
          image: product.avatarOriginalName,
          name,
          size,
          colorHex,
          price,
          qty,
          remainingCount,
          offAmount: selectedProduct.offAmount,
          offPercentage: selectedProduct.offPercentage,
          brandName
        }
      })
    );
  };

  const handleBuyItem = () => {
    const { size, colorHex, productOptionId } = selectedProduct;
    if (qty === 0) {
      return;
    }
    navigate('/cart', {
      state: {
        item: {
          id: productOptionId.id,
          image: product.avatarOriginalName,
          name,
          size,
          colorHex,
          price,
          qty,
          remainingCount,
          offAmount: selectedProduct.offAmount,
          offPercentage: selectedProduct.offPercentage,
          brandName
        }
      }
    });
  };
  return (
    <div className="">
      <div className="mb-1.5">
        <p className="font-body-s mb-2 text-secondary-medium">{categoryDetail?.name}</p>
        <p className="font-title-l mb-6 text-primary">{name}</p>
        <div className="font-body-lg text-support">
          {discountPrice ? (
            <>
              <TugCurrency className="mr-10 text-primary-disabled line-through" value={price} />
              <TugCurrency value={discountPrice} />
            </>
          ) : (
            <TugCurrency value={price} />
          )}
        </div>
      </div>
      <img className="mb-2 h-auto w-1/3 md:w-1/4 lg:w-1/5" src={avatarOriginalName} alt={'brand'} />

      {hasOptions && (
        <>
          {!!selectedSize && (
            <>
              <p className="font-body-lg mb-2 text-primary">Өнгө:</p>
              {isColor ? (
                <div className="grid-row-2 mb-4 grid grid-cols-6 gap-6">
                  {normalizedOptions[selectedSize]?.length > 0 &&
                    normalizedOptions[selectedSize].map((product) => (
                      <ColorItem
                        isColor={isColor}
                        key={product?.productOptionId?.id}
                        color={product?.colorHex}
                        selected={product?.colorHex === selectedProduct.colorHex}
                        onClick={() => handleSelectColor(product)}
                        hasItem={product?.remainingCount > 0}
                      />
                    ))}
                </div>
              ) : (
                normalizedOptions[selectedSize]?.length > 0 && <Select sizes={normalizedOptions[selectedSize].map((s) => s.color)} onPress={handleSelectColorText} />
              )}
            </>
          )}
          <div className="mb-2 flex justify-between">
            <p className="font-body-lg  text-primary">Хэмжээ:</p>
          </div>
          {productSizes?.length > 0 && <Select sizes={productSizes} onPress={handleSelectSize} />}
        </>
      )}

      <ProductCount qty={qty} remainingCount={remainingCount} plus={() => setQty((q) => q + 1)} minus={() => setQty((q) => q - 1)} />

      <div className="mb-16 flex flex-row justify-between space-x-7">
        <Button onClick={handleBuyItem} text="Захиалах" variant={'filled'} color={'primary'} className="flex-1 " />

        <Button
          onClick={handleAddItemToCart}
          text="Сагслах"
          startIcon={<ShoppingBag className="mr-3" />}
          className="font-body-m flex-1 flex-row space-x-3 text-primary"
          variant={'outlined'}
        />
      </div>
      <div className="flex flex-col space-y-6 ">
        <hr />
        <ProductDisclosure text={'Материал'}>{material} </ProductDisclosure>
        <hr />
        <ProductDisclosure text={'Тайлбар'}>{description}</ProductDisclosure>
        {/*<hr />*/}
        {/*<ProductDisclosure text={'Сэтгэгдэл'} />*/}
        {/*<Comment />*/}
      </div>
    </div>
  );
};
