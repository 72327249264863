import { axios } from '@/libs/axios';

export const registerWithEmailAndPassword = (data) => {
  const { password, firstname, lastname, email, isCompany, companyName, companyPosition, companyRegister, agreement1, agreement2, phone } = data;

  const company = isCompany ? { name: companyName, nip: companyRegister, companyType: companyPosition } : null;

  return axios.post('/customer/self_register', {
    customer: {
      email,
      phone,
      plainPassword: password,
      firstName: firstname,
      lastName: lastname,
      company,
      agreement1,
      agreement2,
      gender: 'not_disclosed',
      birthDate: new Date()
    }
  });
};
