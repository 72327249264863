import React from 'react';
import { Link } from 'react-router-dom';

import noRecommendation from '@/assets/no-recommendation.png';
import { yearFormat } from '@/utils/dateFormats';

export const AwardItem = ({ awardItem }) => {
  const { description, datePublished, name, photos } = awardItem || {};
  const { originalName } = photos[0] || {};
  return (
    // <Link to={`/award/${awardId}`}>
    <Link to={`#`}>
      <div className={'bg-white p-6'}>
        <div className="worker-image-container relative mb-6">
          <img className={'w-full '} src={originalName || noRecommendation} alt={'award'} />
        </div>
        <div className={'w-full'}>
          <div className={'py-1'}>
            <p className={'font-body-s text-support'}>{name}</p>
          </div>
          <div className={'py-1'}>
            <p className={'font-title-l text-secondary-high'}>{description}</p>
          </div>
          <div className={'grid grid-cols-2 py-1'}>
            <p className={'font-body-m col-span-1 text-secondary-medium'}>{yearFormat(datePublished)}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};
