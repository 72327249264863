import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';

import { LoginForm, PasswordResetForm } from './forms';

export const LoginModal = ({ isLoginOpen, toggleLogin }) => {
  const [isShowPasswordRest, setIsShowPasswordRest] = useState(false);

  const togglePasswordReset = () => setIsShowPasswordRest((p) => !p);
  const closePasswordReset = () => setIsShowPasswordRest(false);

  return (
    <Transition
      afterLeave={closePasswordReset}
      show={isLoginOpen}
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 -translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-1">
      <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto" onClose={toggleLogin}>
        <div className="min-h-screen text-center">
          <Transition.Child as={Fragment}>
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child as={Fragment}>
            <span className="inline-block w-full max-w-xl	transform overflow-hidden rounded-sm bg-white p-6 text-left align-middle shadow-xl transition-all md:p-20">
              {isShowPasswordRest || <LoginForm toggleLogin={toggleLogin} togglePasswordReset={togglePasswordReset} />}
              {isShowPasswordRest && <PasswordResetForm toggleLogin={toggleLogin} togglePasswordReset={togglePasswordReset} />}
            </span>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
