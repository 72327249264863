import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';

import noRecommendation from '@/assets/no-recommendation.png';

const RenderWorkItem = () => {
  return (
    <div className=" animate-pulse rounded">
      <div className="image-container-company bg-slate-300" />
      <div className="mt-4">
        <div className={`h-${5} mb-2 w-2/3 rounded bg-slate-200`} />
        <div className={`h-${3} w-1/2 rounded bg-slate-200`} />
      </div>
    </div>
  );
};
export const WorkItem = ({ work, isLoading }) => {
  const { name, datePublished, workId, photos } = work || {};
  const imageUri = photos?.length > 0 ? photos[0]?.originalName : null;
  if (isLoading) {
    return (
      <div className=" animate-pulse rounded">
        <div className="bg-white px-10 pt-10">
          <div className="grid content-center">
            <div className={`h-${5} mb-2 w-2/3 rounded bg-slate-200`} />
            <div className="flex justify-items-center pb-10">
              <div className={`h-${5} mb-2 mr-10 w-1/3 rounded bg-slate-200`} />
              <div className={`h-${5} mb-2 w-1/3 rounded bg-slate-200`} />
            </div>
          </div>
          <div className="image-container-company bg-slate-300" />
        </div>
      </div>
    );
  }
  return (
    <div className="">
      <Link to={`/works/${workId}`}>
        <div className="bg-white px-10 pt-10">
          <div className="grid content-center">
            <p className="font-title-l mb-2 text-primary">{name}</p>
            <div className="flex justify-items-center pb-10">
              <p className="font-body-xs pr-10 text-secondary-medium">Нийтлэгдсэн:</p>
              <p className="font-body-xs text-secondary-medium">{format(new Date(datePublished), 'yyyy.MM.dd')}</p>
            </div>
          </div>
          <div className="image-container-company">
            <img alt="work" src={imageUri || noRecommendation} className="w-full" />
          </div>
        </div>
      </Link>
    </div>
  );
};
