import React, { memo } from 'react';
import { MinusCircle } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, IconButton } from '@/components/Elements';
import { ServiceRating } from '@/components/ServiceRating';
import { categoryTypes } from '@/constants/categoryTypes';
import { menuActions } from '@/store/menuSlice';
import { packActions } from '@/store/packSlice';

export const CompanyItem = memo(({ value, toggleSearch = () => null, isShowRemovePack, rating, isShowReview, orderId }) => {
  const dispatch = useDispatch();
  const { name, companyId, avatarPath, avatarPathSecond, category, categoryDetail, avatarOriginalName } = value || {};

  const handleRemove = React.useCallback(() => dispatch(packActions.removePackItem({ type: categoryTypes.company, id: companyId })), [companyId, dispatch]);

  const toggleReview = React.useCallback(() => dispatch(menuActions.toggleReview({ reviewItem: { orderId, ...value } })), [dispatch, orderId, value]);

  return (
    <div className="relative">
      <Link to={`/company/${companyId}`} onClick={toggleSearch}>
        <div className="group">
          <div className="home-image-image-container relative mb-4 rounded bg-white bg-cover bg-center">
            <div className="home-image-image-container absolute top-0 bg-transparent  duration-1000 hover:bg-light-medium" />
            <img className="w-full duration-300 hover:scale-105" src={avatarOriginalName || avatarPath || avatarPathSecond} alt={`${name}`} />
          </div>
          <p className="font-body-lg duration-800 truncate font-semibold  text-secondary-high group-hover:text-support">{name}</p>
          <p className="font-title-s text-secondary-medium">{category?.name ?? categoryDetail.name}</p>
        </div>
      </Link>

      {(isShowReview || !!rating) && (
        <div className="mt-4">
          {rating && <ServiceRating rating={rating} />}

          {isShowReview && !rating && (
            <Button onClick={toggleReview} variant="outlined" className="w-full">
              Үнэлгээ өгөх
            </Button>
          )}
        </div>
      )}

      {isShowRemovePack && (
        <IconButton className="absolute right-0 bottom-3" size="md" onClick={handleRemove}>
          <MinusCircle size={20} />
        </IconButton>
      )}
    </div>
  );
});

CompanyItem.displayName = 'CompanyItem';
