import clsx from 'clsx';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button } from '@/components/Elements';
import { orderStatus } from '@/constants/order';
import { PackConfirmationModal } from '@/features/pack';
import { packSelector } from '@/store/packSlice';

import { getStatusInfo, NEW_PACK } from '../constants';

const getButtonText = (status) => {
  switch (status) {
    case orderStatus.initiated:
    case orderStatus.cancelled:
    case orderStatus.ongoing:
      return 'Дэлэгрэнгүй';
    case orderStatus.completed:
      return 'Үнэлгээ өгөх';
  }
};

export const PackItem = ({ order, name, onClick = () => null }) => {
  const hasPackItem = useSelector(packSelector.hasPackItem);
  const navigate = useNavigate();

  const { status, executorIds, companyIds } = order;

  const canEdit = !status;

  const buttonText = getButtonText(status);

  const { text, bgColor, textColor } = getStatusInfo(status);

  const handleGoToDetail = () => {
    onClick();
    navigate(`/pack/${canEdit ? NEW_PACK : order.orderId}`);
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handlePackOrder = () => {
    if (hasPackItem) {
      handleOpen();
    } else {
      toast.error('Таны багц хоосон байна. Та багцдаа хувь хүн, байгуулга нэмнэ үү.');
    }
  };

  return (
    <div className="bg-primary-high transition-shadow duration-300 ease-in-out hover:shadow-xl">
      {canEdit && <PackConfirmationModal isOpen={isOpen} close={handleClose} />}
      <div className={clsx('center flex py-2', bgColor)}>
        <p className={clsx('font-label-l', textColor)}>{text}</p>
      </div>

      <div className="p-4 pt-8">
        <p className="font-title-l mb-6 border-b border-dashed border-light-disabled pb-6">{name}</p>

        <div className="row font-body-l justify-between">
          <p className="text-secondary-medium">Хувь хүн</p>

          <p className="text-support">{executorIds.length}</p>
        </div>

        <div className="row font-body-l mt-2 justify-between">
          <p className="text-secondary-medium">Байгууллага</p>

          <p className="text-support">{companyIds.length}</p>
        </div>

        <div className="row mt-10 space-x-4">
          {canEdit && (
            <>
              <Button onClick={handlePackOrder} className="flex-1" variant="filled">
                Захиалах
              </Button>
              <Button onClick={handleGoToDetail} className="flex-1" variant="outlined">
                Засах
              </Button>
            </>
          )}
          {canEdit || (
            <Button onClick={handleGoToDetail} className="w-full" variant="filled">
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
