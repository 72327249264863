import { People } from './People';
import { PersonDetail } from './PersonDetail';

export const peopleRoutes = [
  {
    children: [
      { path: '', element: <People /> },
      { path: ':personId', element: <PersonDetail /> }
    ]
  }
];
