import { QueryClient } from 'react-query';

const queryConfig = {
  queries: {
    // useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry: false,
    notifyOnChangeProps: 'tracked',
    staleTime: 5 * 60 * 1000, // 5 minute,
    cacheTime: 5 * 60 * 1000 // 5 minute,
  }
};

export const getNextPageParam = (lastPage, pages, PER_PAGE = 10) => {
  const pageSize = pages.length;
  const { total } = lastPage;
  const totalPageSize = Math.ceil(total / PER_PAGE);
  if (pageSize < totalPageSize) return pageSize + 1;
  else return undefined;
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });
